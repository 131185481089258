import React from 'react';
import {Link, useNavigate} from "react-router-dom";
import {Swiper, SwiperSlide} from "swiper/react";
import {encodeToBase64} from "../../js/module/base64Utils";


function VoteBox({data,keyword}) {
    const navigate = useNavigate();
    const relationMove = (keyword) => {
        const infoData = encodeToBase64(keyword)
        navigate(`/vote/relation/${infoData}`);
    };

    return (
        <div className="relationVoteSh">
            <div className="tit">관련 투표<span>{data.length}</span></div>
            <div className="list">
                <Swiper
                    direction='vertical'
                    slidesPerView='auto'
                    spaceBetween={0}
                    touchReleaseOnEdges={true}
                    breakpoints={{
                        480: {
                            direction: 'horizontal',
                            slidesPerView: 'auto',
                            spaceBetween: 16,
                        },
                        1200: {
                            direction: 'horizontal',
                            slidesPerView: 3,
                            spaceBetween: 16,
                        }
                    }}
                >
                    {data.slice(0,3).map((item,index) => (
                        <SwiperSlide key={index}>
                            <ul className="hoverImgPt">
                                <li className="thumb">
                                    <img
                                        src={item.thumbnail || "https://cdn.trend.rankify.best/dctrend/front/images/defaultImage_dt.svg"}
                                        alt={item.title || 'No Image'}
                                        onError={(e) => { e.target.src = "https://cdn.trend.rankify.best/dctrend/front/images/defaultImage_dt.svg"; }}
                                    />
                                </li>
                                <li className="info">
                                    <dl className="date">{item.startDate.substring(2).replace(/-/g, '.')} ~ {item.endDate.substring(2).replace(/-/g, '.')}</dl>
                                    <dl className="title">{item.title}</dl>
                                    <Link to={`/vote/detail/${item.id}`}><dl className="btn">투표</dl></Link>
                                </li>
                            </ul>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
            <div className="more"><div className="moreBtn" onClick={() => relationMove(keyword)}>연관 투표 전체보기<div className="arw s16"></div></div></div>
        </div>
    );
}

export default VoteBox;
