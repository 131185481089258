import React, { useEffect, useState } from "react";
import StickyDefault from "../layout/stickyDefault";
import { useNavigate, useParams } from "react-router-dom";
import { useApi } from "../../js/module/api";
import {encodeToBase64} from "../../js/module/base64Utils";
import {useAlertNavigation } from  "../../js/module/checkNavigation"

const getDisplayCategory = (categoryPath) => {
    const cateArray = categoryPath.split(' > '); // 문자열을 ' > '로 분리하여 배열로 변환
    const lastValue = cateArray[cateArray.length - 1];
    return `${cateArray[cateArray.length - 2] || ''}・${lastValue}`; // 두 번째 마지막 값과 마지막 값을 조합하여 반환
};

function Another() {
    const apiUrl = process.env.REACT_APP_API_URL; // 환경 변수에서 API URL 가져오기
    const { apiRequest } = useApi();
    const handleNavigationWithAlert = useAlertNavigation();
    const { voteId } = useParams();
    const navigate = useNavigate();
    const [voteInfo, setVoteInfo] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [showSearchKeyword, setShowSearchKeyword] = useState(false);
    const [keywordList, setKeywordList] = useState([]);
    const [hasSearched, setHasSearched] = useState(false); // 검색 여부 상태 추가
    const [selectedIndex, setSelectedIndex] = useState(null); // 선택된 아이템 인덱스 상태 추가

    useEffect(() => {
        const fetchVoteInfo = async () => {
            try {
                const url = apiUrl + `v1/vote/active/detail/${voteId}`;
                const data = await apiRequest(url, {}, 'GET', undefined, undefined, false, false);
                setVoteInfo(data.data);
            } catch (error) {
                console.error('데이터를 불러오는데 실패했습니다.', error);
            }
        };
        fetchVoteInfo().then();
        // eslint-disable-next-line
    }, [apiRequest, apiUrl, voteId]);

    const handleSearchKeyword = async () => {
        if (!searchTerm) {
            alert('대상을 입력해주세요.');
            return;
        }
        try {
            const url = apiUrl + `v1/vote/${voteId}/keywords/search?keyword=${searchTerm}&topCount=0&bottomCount=0`;
            const data = await apiRequest(url, {}, 'GET', undefined, undefined, false, false);
            setKeywordList(data.data);
            setHasSearched(true); // 검색 완료 상태 설정
        } catch (error) {
            console.log(error);
        }
    };

    const handleSearch = () => {
        if (searchTerm) {
            setShowSearchKeyword(true); // 검색어를 보여줌
            handleSearchKeyword(searchTerm).then();
        }
    };

    const handleReset = () => {
        setSearchTerm(""); // 검색어 초기화
        setShowSearchKeyword(false); // 검색어 숨김
        setKeywordList([]); // 검색 결과 초기화
        setHasSearched(false); // 검색 상태 초기화
        setSelectedIndex(null); // 선택된 인덱스 초기화
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSearch(); // 엔터 키를 눌렀을 때 검색 처리
        }
    };

    const handleItemClick = (index) => {
        setSelectedIndex(index); // 클릭한 아이템의 인덱스 설정
    };

    useEffect(() => {
        document.body.style.backgroundColor = "#FAFAFC";
        return () => {
            document.body.style.backgroundColor = "#ffffff";
        };
    }, []);

    const MoveVoteDo = (voteId,keywordId) => {
        if(keywordId === null){
            alert("검색결과를 선택해주세요.");
            return;
        }
        const infoData = encodeToBase64(`${voteId}@${keywordId}`)
        handleNavigationWithAlert(`/vote/do/${infoData}`);
    };

    return (
        <>
            <StickyDefault />
            <section className="contentsWrap">
                <div className="voteWrap">
                    <div className="box">
                        <div className="targetVote">
                            <ul className="thumb">
                                <img
                                    src={voteInfo.thumbnail || "https://cdn.trend.rankify.best/dctrend/front/images/defaultImage_dt.svg"}
                                    alt={voteInfo.title || 'No Image'}
                                    onError={(e) => { e.target.src = "https://cdn.trend.rankify.best/dctrend/front/images/defaultImage_dt.svg"; }}
                                />
                            </ul>
                            <ul className="info">
                                {voteInfo.startDate && (
                                    <>
                                        <li className="title">{voteInfo.title}</li>
                                        <li className="date">{voteInfo.startDate.substring(2).replace(/-/g, '.')} ~ {voteInfo.endDate.substring(2).replace(/-/g, '.')}</li>
                                    </>
                                )}
                            </ul>
                        </div>
                    </div>
                    <div className="box mgt16">
                        <div className="anotherSearch">
                            <div className="title">선호하는 대상이 없나요?</div>
                            <div className="txt">선호하는 대상을 검색해 주세요</div>
                            <div className="inpBox">
                                <div className="inp">
                                    <img src="https://cdn.trend.rankify.best/dctrend/front/images/ico_search_gray.svg" alt="" />
                                    {showSearchKeyword ? (
                                        <>
                                            <div className="searchKeyword">
                                                <ul>{searchTerm}</ul>
                                                <ul className="reset" onClick={handleReset}>
                                                    <img src="https://cdn.trend.rankify.best/dctrend/front/images/btn_close_gray.svg" alt="" />
                                                </ul>
                                            </div>
                                        </>
                                    ) : (
                                        <input
                                            type="text"
                                            placeholder="검색어를 입력하세요"
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                            onKeyPress={handleKeyPress}
                                        />
                                    )}
                                </div>
                                <div className="btn" onClick={handleSearch}>검색</div>
                            </div>
                        </div>
                        <div className="anotherResult">
                            {hasSearched && keywordList.length === 0 ? ( // 검색 후 결과가 없을 때만 메시지 표시
                                <div className="anotherResultNone">
                                    <ul>검색 결과가 없습니다.</ul>
                                    <ul>투표와 관련이 없는 검색어 입니다.<br />다른 키워드를 입력해 보세요.</ul>
                                </div>
                            ) : (
                                keywordList.map((item, index) => (
                                    <div
                                        key={index}
                                        className="item" // 선택된 인덱스와 비교하여 클래스 추가
                                        onClick={() => handleItemClick(item.keywordId)} // 아이템 클릭 시 인덱스 설정
                                    >
                                        <ul className="thumb">
                                            <img
                                                src={item.imageUrl || "https://cdn.trend.rankify.best/dctrend/front/images/defaultImage_dt.svg"}
                                                alt={item.title || 'No Image'}
                                                onError={(e) => { e.target.src = "https://cdn.trend.rankify.best/dctrend/front/images/defaultImage_dt.svg"; }}
                                            />
                                        </ul>
                                        <ul className="info">
                                            <li className="cate">{getDisplayCategory(item.categoryPath)}</li>
                                            <li className="name">{item.keyword}</li>
                                        </ul>
                                        <ul className={`check ${selectedIndex === item.keywordId ? 'on' : ''}`}></ul>
                                    </div>
                                ))
                            )}
                            <div className="btn" onClick={()=>{MoveVoteDo(voteId,selectedIndex)}}>투표하기</div>
                        </div>
                    </div>
                    <div className="tgnc">
                        <div className="backVote" onClick={() => {
                            navigate(-1); // -1을 전달하여 이전 페이지로 이동
                        }}>
                            투표화면으로 돌아가기
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Another;
