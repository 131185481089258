import React, {useEffect, useState} from 'react';
import {useApi} from "../../js/module/api";
import {useNavigate} from "react-router-dom";

function RelationKeyword({keyword}) {
    const apiUrl = process.env.REACT_APP_API_URL;
    const { apiRequest } = useApi();
    const navigate = useNavigate();
    const [list, setList] = useState([]);

    useEffect(() => {
        const fetchRelation = async () => {
            if (!keyword) return;
            try {
                const url = apiUrl + `v1/search/assoc-keyword?query=${keyword}`;
                const data = await apiRequest(url, {}, 'GET', undefined, undefined, false, false);
                setList(data.data);
            } catch (error) {
                console.error('데이터를 불러오는데 실패했습니다.', error);
            } finally {

            }
        };
        fetchRelation().then();
        // eslint-disable-next-line
    }, [apiRequest, apiUrl, keyword]);

    const searchMove = (keyword) => {
        navigate(`/search/${keyword}`);
    };

    return (
        <>
            <div className="dbox keyword">
                <div className="stit">연관 검색어</div>
                <div className="list">
                    {list?.map((item, index) => (
                        <ul key={index} onClick={() => searchMove(item)}>
                            <li>{index + 1}</li>
                            <li>{item}</li>
                        </ul>
                    ))}
                </div>
            </div>
        </>
    );
}

export default RelationKeyword;