import React, {useEffect, useState} from "react";
import StickyDefault from "../layout/stickyDefault";
import RankingNewsBox from "../../component/rankingNewsBox";
import {useApi} from "../../js/module/api";


function RankingNews() {
    const apiUrl = process.env.REACT_APP_API_URL;
    const { apiRequest } = useApi();
    const [rankData, setRankData] = useState([]);

    useEffect(() => {
        const fetchRankNews = async () => {
            try {
                const url = apiUrl + `v1/ranking/news?keywordCount=10&size=48`;
                const data = await apiRequest(url, {}, 'GET', undefined, undefined, false, true);
                setRankData(data.data);
            } catch (error) {
                console.error('데이터를 불러오는데 실패했습니다.', error);
            }
        };
        fetchRankNews().then(); // 비동기 함수 호출
    }, [apiRequest,apiUrl]);

    return (
        <>
            <StickyDefault/>
            <section className="rankingNewsTitle">랭킹뉴스</section>
            <section className="contentsWrap">
                <div className="rankChartWarp">
                    <div className="rankChart no700">
                        {rankData.length === 0 && (
                            <>
                                <div className="rankBox skeletonStyle"></div>
                                <div className="rankBox skeletonStyle"></div>
                                <div className="rankBox skeletonStyle"></div>
                                <div className="rankBox skeletonStyle"></div>
                                <div className="rankBox skeletonStyle"></div>
                                <div className="rankBox skeletonStyle"></div>
                            </>
                        )}
                        {rankData?.map((item, index) => (
                            <RankingNewsBox key={index} rankingData={item} />
                        ))}
                    </div>
                </div>

            </section>
        </>
    );
}

export default RankingNews;