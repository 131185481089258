const menu = [
    {
        title: '트렌드뉴스',
        pathName: '/news',
        link: '/news/trend',
        subMenu: [

        ],
    },
    {
        title: '랭킹뉴스',
        pathName: '/rankNews',
        link: '/rankNews',
        subMenu: [

        ],
    },
    {
        title: '랭킹',
        pathName: '/ranking',
        link: '/ranking/popular',
        subMenu: [

        ],
    },
    {
        title: '투표',
        pathName: '/vote',
        link: '/vote/list/all/1',
        subMenu: [
            {
                title: '진행중인 투표',
                pathName: '/vote/list',
                pathName2: '/vote/detail',
                link: '/vote/list/all/1'
            },
            {
                title: '마감된 투표',
                pathName: '/vote/awards',
                pathName2: '/vote/awardsDetail',
                link: '/vote/awards/all/1'
            }
        ],
    },
];

export default menu;
