import StickyDefault from "../layout/stickyDefault";
import React, {useEffect, useRef, useState} from "react";
import 'swiper/swiper-bundle.css';
import { useLocation, useNavigate, useParams} from "react-router-dom";
import { useApi } from "../../js/module/api";
import VoteBox from "./voteBox";
import PeopleBox from "./peopleBox";
import decodeHtmlEntities from "../../js/module/HtmlDecode";
import htmlToText from "../../js/module/HtmlToText";
import {formatDay} from "../../js/module/formatDate";

function Search() {
    const location = useLocation();
    const apiUrl = process.env.REACT_APP_API_URL;
    const { apiRequest } = useApi();
    const { query } = useParams();
    const [peopleData, setPeopleData] = useState([]);
    const [voteData, setVoteData] = useState([]);
    const [newsData, setNewsData] = useState([]);

    const [items, setItems] = useState(() => {
        const savedItems = sessionStorage.getItem('newsitems');
        return savedItems ? JSON.parse(savedItems) : [];
    });
    const [page, setPage] = useState(() => {
        const savedPage = sessionStorage.getItem('newspage');
        return savedPage ? Number(savedPage) : 1;
    });
    const [hasMore, setHasMore] = useState(true);
    const observerRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchSearch = async () => {
            if (!query) return;
            try {
                const url = apiUrl + `v1/search?query=${query}`;
                const data = await apiRequest(url, {}, 'GET', undefined, undefined, false, false);
                setPeopleData(data.data.keywordResults);
                setVoteData(data.data.voteResults);
                setNewsData(data.data.newsResults);
            } catch (error) {
                console.error('데이터를 불러오는데 실패했습니다.', error);
            } finally {

            }
        };
        fetchSearch().then();
        // eslint-disable-next-line
    }, [apiRequest, apiUrl, query]);


    useEffect(() => {
        const pageCheck = (sessionStorage.getItem('newsList'));
        if (pageCheck) {
            sessionStorage.removeItem('newsList');
        } else {
            setItems([]); // 상태 초기화
            setPage(1); // 페이지 초기화
            sessionStorage.removeItem('newsitems');
            sessionStorage.removeItem('newspage');
        }
    }, [location]);

    const fetchItems = async (page) => {
        try {
            const url = apiUrl + `v1/search/news?query=${query}&page=${page}&size=20`;
            const data = await apiRequest(url, {}, 'GET', undefined, undefined, false, false);
            return Array.isArray(data.data.content) ? data.data.content : [];
        } catch (error) {
            console.error('Error fetching items:', error);
            return [];
        }
    };

    useEffect(() => {
        sessionStorage.setItem('newsitems', JSON.stringify(items));
        sessionStorage.setItem('newspage', page.toString());
    }, [items, page]);


    const handleObserver = (entries) => {
        const [target] = entries;
        if (target.isIntersecting && hasMore) {
            setPage((prev) => prev + 1);
        }
    };

    useEffect(() => {
        const loadItems = async () => {
            const newItems = await fetchItems(page);
            if (newItems.length === 0) {
                setHasMore(false);
                setPage((prev) => prev - 1);
            } else {
                const uniqueItems = [...new Map([...items, ...newItems].map(item => [item.newsId, item])).values()];
                setItems(uniqueItems);
            }
            if (newItems.length > 0) {
                if (observerRef.current && document.getElementById('load-more')) {
                    observerRef.current.observe(document.getElementById('load-more'));
                }
            }
        };
        if (hasMore) {
            loadItems().then();
        }
        return () => {
            if (observerRef.current) observerRef.current.disconnect();
        };
        // eslint-disable-next-line
    }, [page]);

    useEffect(() => {
        observerRef.current = new IntersectionObserver(handleObserver, {
            root: null,
            rootMargin: '0px',
            threshold: 0.5,
        });
        // eslint-disable-next-line
    }, []);

    const moveDetail = (categoryId,newsId) => {
        sessionStorage.setItem('newsList', 'true');
        navigate(`/news/trend/detail/${categoryId}/${newsId}`);
    };

    return (
        <>
            <StickyDefault />
            <section className="contentsWrap">

                {/* 모든 데이터가 없을 때 noResulWrap 보여주기 */}
                {peopleData.length === 0 && voteData.length === 0 && newsData.content?.length === 0 ? (
                    <div className="noResulWrap" style={{ paddingTop: '130px' }}>
                        <div className="ul"><img src="https://cdn.trend.rankify.best/dctrend/front/images/ico_nocomment.svg" alt="" /></div>
                        <div className="ul"><span>'{query}'</span> 에 대한 검색결과가 없습니다.</div>
                        <div className="ul">단어의 철자가 정확한지 확인 후 다시 검색해 보세요.</div>
                    </div>
                ) : (
                    <div className="searchResult">
                        <div className="resultTitle"><span>'{query}'</span> 검색결과</div>
                        {peopleData.length !== 0 && (
                            <PeopleBox peopleData={peopleData} />
                        )}
                        {voteData.length !== 0 && (
                            <VoteBox data={voteData} keyword={query} />
                        )}
                        <div className="relationNews">
                            <div className="newsTitle">관련뉴스</div>
                            <div className="newsList">
                                <div className="list">
                                    {items.map(item => (
                                        <ul key={item.newsId} className="hoverImgPt" onClick={() => {moveDetail(item.categoryId,item.newsId)}}>
                                            <div className="noLink">
                                                <div className="thumb skeletonStyle">
                                                    <img
                                                        src={item.thumbnail || "https://cdn.trend.rankify.best/dctrend/front/images/defaultImage_dt.svg"}
                                                        alt={item.title || 'No Image'}
                                                        onError={(e) => { e.target.src = "https://cdn.trend.rankify.best/dctrend/front/images/defaultImage_dt.svg"; }}
                                                    />
                                                </div>
                                                <li className="tit">{decodeHtmlEntities(item.title)}</li>
                                                <li className="txt">{htmlToText(item.content, 150)}</li>
                                                <li className="info">
                                                    <dl>{item.pressName}</dl>
                                                    <dl>{formatDay(item.publishedAt)}</dl>
                                                    {/*
                                                    <dl><img src="https://cdn.trend.rankify.best/dctrend/front/images/ico_comment.png" alt=""/>0</dl>
                                                    */}
                                                </li>
                                            </div>
                                        </ul>
                                    ))}
                                </div>
                                <div id="load-more" style={{ height: '20px'}}></div>
                            </div>
                        </div>
                    </div>
                )}

            </section>
        </>
    );
}

export default Search;
