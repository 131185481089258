import React from 'react';

function ContThirdParty() {

    return (
        <div className="box">
            <ul>
                <li>디시트렌드</li>
                <li>
                    <dl>
                        <dt>
                            <div>서비스 내 이용자 식별, 통계, 계정 연동 및 CS 등을 위해 이용자 식별자 정보와 함께 개인정보가 제공됩니다. 제공된 정보는 서비스 이용기간 동안 활용/보관됩니다. 본 제공 동의를 거부할 권리가 있으나, 동의를 거부하실 경우 서비스 이용이 제한될 수 있습니다.</div>
                            <div className="pdl20 pdr20">
                                <div className="subtit">1. 제공받는자</div>
                                <div>팅코</div>
                                <div className="subtit">2. 필수 제공 항목</div>
                                <div>이용자 식별자, 이름, 이메일 주소, 닉네임</div>
                                <div className="subtit">3. 선택 제공 항목</div>
                                <div>별명, 성별, 연령대</div>
                                <div className="subtit">4. 제공 목적</div>
                                <div>디시트렌드 서비스 내 뉴스 댓글 관리, 투표권 구매 및 환불 관리, 피드백 센터 운영</div>
                                <div className="subtit">5. 보유 기간</div>
                                <div>서비스 이용기간 동안 보관하며 동의 철회 또는 서비스 탈퇴 시 지체없이 파기</div>
                            </div>
                        </dt>
                    </dl>
                </li>
            </ul>
        </div>
    );
}

export default ContThirdParty;