import React, { useEffect, useState } from 'react';
import { useApi } from '../../js/module/api';
import { formatNumberWithCommas } from '../../js/module/numberFormatter';

function KeywordRankBox({ categoryId, rank, depth, setKeywordId }) {
    const apiUrl = process.env.REACT_APP_API_URL;
    const { apiRequest } = useApi();
    const [rankData, setRankData] = useState([]);
    const [displayValue, setDisplayValue] = useState('');

    useEffect(() => {
        const fetchRanking = async () => {
            if (!categoryId) return;
            try {
                const url = apiUrl + `v1/ranking/keyword`;
                const params = {
                    "categoryIds": [categoryId],
                    "keywordCount": 50
                };
                const data = await apiRequest(url, params, 'POST', undefined, undefined, false, false);
                setRankData(data.data.keyword);
            } catch (error) {
                console.error('데이터를 불러오는데 실패했습니다.', error);
            } finally {

            }
        };
        fetchRanking().then();
        // eslint-disable-next-line
    }, [apiRequest, apiUrl, categoryId]);

    useEffect(() => {
        if (depth) {
            const depthArray = depth.split(' > '); // 문자열을 ' > '로 분리하여 배열로 변환
            const lastValue = depthArray[depthArray.length - 1];

            if (lastValue === '남자' || lastValue === '여자') {
                // 마지막 값이 '남자' 또는 '여자'인 경우 이전 항목과 결합
                setDisplayValue(`${depthArray[depthArray.length - 2] || ''} ${lastValue}`);
            } else {
                // 그 외의 경우 마지막 값을 그대로 사용
                setDisplayValue(lastValue);
            }
        }
    }, [depth]);

    const changeKeyword = (id) => {
        setKeywordId(id);
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    const top10Items = rankData.slice(0, 10);
    const matchingItems = rankData.filter(item => String(item.todayRank) === String(rank));

    return (
        <>
            {!displayValue ? (
                <div className="dbox rankVote">
                    <div className="listLoader" style={{paddingTop:'200px'}}>
                        <div className="loader"></div>
                    </div>
                </div>
            ) : (
                <div className="dbox rankVote">
                    <>
                        <div className="stit">{displayValue} 랭킹</div>
                        <div className="base">
                            <img src="https://cdn.trend.rankify.best/dctrend/front/images/ico_info.svg" alt="" /> 인기지수 기준
                        </div>
                        <div className="list">
                            {top10Items.map((item, index) => (
                                <ul key={index}>
                                    <li className="rank">
                                        <dl className="num">{item.todayRank}</dl>
                                        <dl
                                            className={`rankMark ${
                                                item.rankDiff === 'NEW'
                                                    ? 'new'
                                                    : !isNaN(item.rankDiff) && Number(item.rankDiff) > 0
                                                        ? 'up'
                                                        : !isNaN(item.rankDiff) && Number(item.rankDiff) < 0
                                                            ? 'down'
                                                            : 'same'
                                            }`}
                                        >
                                            {!isNaN(item.rankDiff) && Number(item.rankDiff) >= 1000 ? '999+' : item.rankDiff}
                                        </dl>
                                    </li>
                                    <li className="info">
                                        <dl className="thumb">
                                            <div className="img">
                                                <img
                                                    src={item.thumbnailUrl || 'https://cdn.trend.rankify.best/dctrend/front/images/defaultImage_dt.svg'}
                                                    alt={item.keyword || 'No Image'}
                                                    onError={(e) => {
                                                        e.target.src = 'https://cdn.trend.rankify.best/dctrend/front/images/defaultImage_dt.svg';
                                                    }}
                                                />
                                            </div>
                                        </dl>
                                        <dl className="txt">
                                            <dt className="cate">{displayValue}</dt>
                                            <dt className="name">{item.keyword}</dt>
                                        </dl>
                                    </li>
                                    <li className="score" onClick={() => changeKeyword(item.id)}>{formatNumberWithCommas(item.popularityIndexValue)} 점 <div className="arw s16"></div></li>
                                </ul>
                            ))}
                            {matchingItems.map((item, index) => (
                                <ul key={`rank-${item.todayRank}-${index}`}>
                                    <li className="rank">
                                        <dl className="num">{item.todayRank}</dl>
                                        <dl
                                            className={`rankMark ${
                                                item.rankDiff === 'NEW'
                                                    ? 'new'
                                                    : !isNaN(item.rankDiff) && Number(item.rankDiff) > 0
                                                        ? 'up'
                                                        : !isNaN(item.rankDiff) && Number(item.rankDiff) < 0
                                                            ? 'down'
                                                            : 'same'
                                            }`}
                                        >
                                            {!isNaN(item.rankDiff) && Number(item.rankDiff) >= 1000 ? '999+' : item.rankDiff}
                                        </dl>
                                    </li>
                                    <li className="info">
                                        <dl className="thumb">
                                            <div className="img">
                                                <img
                                                    src={item.imageUrl || 'https://cdn.trend.rankify.best/dctrend/front/images/defaultImage_dt.svg'}
                                                    alt={item.keyword || 'No Image'}
                                                    onError={(e) => {
                                                        e.target.src = 'https://cdn.trend.rankify.best/dctrend/front/images/defaultImage_dt.svg';
                                                    }}
                                                />
                                            </div>
                                        </dl>
                                        <dl className="txt">
                                            <dt className="cate">{displayValue}</dt>
                                            <dt className="name">{item.keyword}</dt>
                                        </dl>
                                    </li>
                                    <li className="score">{formatNumberWithCommas(item.popularityIndexValue)} 점</li>
                                </ul>
                            ))}
                        </div>
                    </>
                </div>
            )}
        </>
    );
}

export default KeywordRankBox;
