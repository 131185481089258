import StickyDefault from "../layout/stickyDefault";
import React, { useEffect,useState } from "react";
import {Checkbox} from 'antd';
import {useNavigate} from "react-router-dom";

function FeedBack() {
    const apiUrl = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [entityType, setEntityType] = useState("INDIVIDUAL");
    const [sameAsReporter, setSameAsReporter] = useState(false);
    const [reporterInfo, setReporterInfo] = useState({
        name: "",
        email: "",
        phone: ""
    });

    const [rightsHolderInfo, setRightsHolderInfo] = useState({
        name: "",
        email: "",
        phone: ""
    });

    const [rightOwnerFileName, setRightOwnerFileName] = useState(""); // 권리자 파일명 상태
    const [originalFileName, setOriginalFileName] = useState(""); // 원본 파일명 상태
    const [agreeChecked, setAgreeChecked] = useState(false); // 동의 체크박스
    const [reportReason, setReportReason] = useState(""); // 신고 사유
    const [isFormValid, setIsFormValid] = useState(false);

    useEffect(() => {
        document.body.style.backgroundColor = "#FAFAFC";
        return () => {
            document.body.style.backgroundColor = "#ffffff";
        };
    }, []);

    const onEntityTypeChange = (e) => {
        setEntityType(e.target.value);
    };

    const onSameAsReporterChange = (e) => {
        setSameAsReporter(e.target.checked);
        if (e.target.checked) {
            setRightsHolderInfo(reporterInfo);
        } else {
            setRightsHolderInfo({ name: "", email: "", phone: "" });
        }
    };

    const handleFileClick = (type) => {
        if(type === "rightOwnerIdFile"){
            document.querySelector('input[name="rightOwnerIdFile"]').click();
        }else{
            document.querySelector('input[name="originalFile"]').click();
        }
    };

    const handleFileChange = (e, type) => {
        const file = e.target.files[0];
        if (file) {
            if (type === "rightOwnerIdFile") {
                setRightOwnerFileName(file.name); // 권리자 파일명 설정
            } else if (type === "originalFile") {
                setOriginalFileName(file.name); // 원본 파일명 설정
            }
        }
    };

    useEffect(() => {
        if (sameAsReporter) {
            setRightsHolderInfo(reporterInfo);
        }
    }, [reporterInfo, sameAsReporter]);

    useEffect(() => {
        const isReporterInfoValid = reporterInfo.name && reporterInfo.email && reporterInfo.phone;
        const isRightsHolderInfoValid = rightsHolderInfo.name && rightsHolderInfo.email && rightsHolderInfo.phone;
        const isFilesUploaded = rightOwnerFileName && originalFileName;
        const isFormValid = isReporterInfoValid && isRightsHolderInfoValid && reportReason && agreeChecked && isFilesUploaded;
        setIsFormValid(isFormValid);
    }, [reporterInfo, rightsHolderInfo, rightOwnerFileName, originalFileName, reportReason, agreeChecked]);


    const handleSubmit = async (e) => {
        e.preventDefault(); // 기본 이벤트 취소
        const form = e.target; // 이벤트 타겟 확인
        if (!(form instanceof HTMLFormElement)) return;
        const formData = new FormData(form);
        console.log(...formData); // FormData 내용 확인

        // API 요청 보내기
        try {
            setIsLoading(true)
            const response = await fetch(apiUrl+'v1/common/feedback-report', {
                method: 'POST',
                headers: {
                    'User-Identifier': sessionStorage.getItem('fingerprint'),
                    'X-API-KEY': 'AdswKr3yJ5lHkWllQUr6adnY9Q4aoqHh0KfwBeyb14',
                    Authorization: 'bearer ' + sessionStorage.getItem('authToken'),
                },
                body: formData,
            });
            const data = await response.json();
            setIsLoading(false);
            if (response.status !== 200) {
                let message;
                if (data.validation && data.validation.length > 0) {
                    message = data.validation[0].message;
                } else {
                    message = data.message;
                }
                alert(message);
            }else{
                alert("접수가 정상적으로 처리되었습니다.");
                navigate("/");
            }
        } catch (error) {
            alert(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <StickyDefault />
            <section className="contentsWrap">
                <form onSubmit={handleSubmit} encType="multipart/form-data">
                    <div className="feedBack">
                        <div className="title">피드백 센터</div>
                        <div className="box">
                            <div className="boxTitle">신고 사유 선택</div>
                            <div className="typeSelect">
                                <div className="radioList">
                                    <input id="r1" className="default" type="radio" name="reportReason" value="개인정보침해" onChange={(e) => setReportReason(e.target.value)} />
                                    <label htmlFor="r1">개인정보침해</label>
                                </div>
                                <div className="radioList">
                                    <input id="r2" className="default" type="radio" name="reportReason" value="저작권 침해" onChange={(e) => setReportReason(e.target.value)} />
                                    <label htmlFor="r2">저작권 침해</label>
                                </div>
                                <div className="radioList">
                                    <input id="r3" className="default" type="radio" name="reportReason" value="초상권 침해" onChange={(e) => setReportReason(e.target.value)} />
                                    <label htmlFor="r3">초상권 침해</label>
                                </div>
                                <div className="radioList">
                                    <input id="r4" className="default" type="radio" name="reportReason" value="상표권 침해" onChange={(e) => setReportReason(e.target.value)} />
                                    <label htmlFor="r4">상표권 침해</label>
                                </div>
                                <div className="radioList">
                                    <input id="r5" className="default" type="radio" name="reportReason" value="불법 촬영 콘텐츠" onChange={(e) => setReportReason(e.target.value)} />
                                    <label htmlFor="r5">불법 촬영 콘텐츠</label>
                                </div>
                            </div>
                        </div>
                        <div className="box">
                            <div className="boxTitle">신고자 정보</div>
                            <div className="formBd">
                                <ul>
                                    <li className="stit">이름</li>
                                    <input
                                        name="reporterName"
                                        type="text"
                                        placeholder="이름을 입력해주세요."
                                        value={reporterInfo.name}
                                        onChange={(e) =>
                                            setReporterInfo({ ...reporterInfo, name: e.target.value })
                                        }
                                    />
                                </ul>
                                <ul>
                                    <li className="stit">이메일</li>
                                    <input
                                        name="reporterEmail"
                                        type="text"
                                        placeholder="이메일 주소를 입력해주세요."
                                        value={reporterInfo.email}
                                        onChange={(e) =>
                                            setReporterInfo({ ...reporterInfo, email: e.target.value })
                                        }
                                    />
                                </ul>
                                <ul>
                                    <li className="stit">전화번호</li>
                                    <input
                                        name="reporterPhone"
                                        type="text"
                                        placeholder="숫자만 입력해 주세요. (-제외)"
                                        value={reporterInfo.phone}
                                        onChange={(e) =>
                                            setReporterInfo({ ...reporterInfo, phone: e.target.value })
                                        }
                                    />
                                </ul>
                            </div>
                        </div>
                        <div className="box">
                            <div className="boxTitle">권리자 정보</div>
                            <div className="tab">
                                <ul>
                                    <input
                                        type="radio"
                                        id="type1"
                                        name="RightOwnerType"
                                        value="INDIVIDUAL"
                                        checked={entityType === "INDIVIDUAL"}
                                        onChange={onEntityTypeChange}
                                    />
                                    <label htmlFor="type1">개인</label>
                                </ul>
                                <ul>
                                    <input
                                        type="radio"
                                        id="type2"
                                        name="RightOwnerType"
                                        value="ORGANIZATION"
                                        checked={entityType === "ORGANIZATION"}
                                        onChange={onEntityTypeChange}
                                    />
                                    <label htmlFor="type2">단체<div>(기업 · 개인사업자 등)</div></label>
                                </ul>
                            </div>
                            <div className="copyInfo">
                                <Checkbox onChange={onSameAsReporterChange}>신고자와 동일하게 입력</Checkbox>
                            </div>
                            <div className="formBd">
                                <ul>
                                    <li className="stit">{entityType === "INDIVIDUAL" ? "권리자 이름" : "단체명"}</li>
                                    <input
                                        name="rightOwnerName"
                                        type="text"
                                        placeholder={entityType === "INDIVIDUAL" ? "이름을 입력해주세요." : "단체명을 입력해주세요."}
                                        value={rightsHolderInfo.name}
                                        onChange={(e) =>
                                            setRightsHolderInfo({ ...rightsHolderInfo, name: e.target.value })
                                        }
                                    />
                                </ul>
                                <ul>
                                    <li className="stit">이메일</li>
                                    <input
                                        name="rightOwnerEmail"
                                        type="text"
                                        placeholder="이메일 주소를 입력해주세요."
                                        value={rightsHolderInfo.email}
                                        onChange={(e) =>
                                            setRightsHolderInfo({ ...rightsHolderInfo, email: e.target.value })
                                        }
                                    />
                                </ul>
                                <ul>
                                    <li className="stit">{entityType === "INDIVIDUAL" ? "권리자 전화번호" : "단체 전화번호"}</li>
                                    <input
                                        name="rightOwnerPhone"
                                        type="text"
                                        placeholder="숫자만 입력해 주세요. (-제외)"
                                        value={rightsHolderInfo.phone}
                                        onChange={(e) =>
                                            setRightsHolderInfo({ ...rightsHolderInfo, phone: e.target.value })
                                        }
                                    />
                                </ul>
                                <ul>
                                    <li className="stit">{entityType === "INDIVIDUAL" ? "권리자 신분증" : "단체 증빙 서류"}</li>
                                    <div className="inpFile" onClick={() => handleFileClick("rightOwnerIdFile")}>
                                        <input
                                            name="idRight"
                                            type="text"
                                            placeholder={entityType === "INDIVIDUAL" ? "신분증, 운전면허증 또는 여권 사진" : "단체 증빙 서류"}
                                            value={rightOwnerFileName} // 파일명 표시
                                            disabled={true}
                                        />
                                        <div className="btnFile">파일 첨부</div>
                                    </div>
                                    <li className="infoText">파일 첨부: 최대 1개(jpg, jpeg, png 10MB 이하)</li>
                                    <input
                                        name="rightOwnerIdFile"
                                        type="file"
                                        style={{ display: 'none' }}
                                        accept=".jpg, .jpeg, .png"
                                        onChange={(e) => handleFileChange(e, "rightOwnerIdFile")} // 파일 선택 시 파일명 처리
                                    />
                                </ul>
                            </div>
                        </div>
                        <div className="box">
                            <div className="boxTitle noBOt">파일첨부</div>
                            <div className="formBd">
                                <ul>
                                    <li className="stit">신고 관련 파일 또는 저작물의 원본을 첨부해주세요.</li>
                                    <div className="inpFile" onClick={() => handleFileClick("originalFile")}>
                                        <input
                                            name="idRight"
                                            type="text"
                                            placeholder="신고파일 또는 저작물 원본"
                                            value={originalFileName} // 파일명 표시
                                            disabled={true}
                                        />
                                        <div className="btnFile">파일 첨부</div>
                                    </div>
                                    <li className="infoText">파일 첨부: 최대 1개(jpg, jpeg, png, zip 50MB 이하)</li>
                                    <input
                                        name="originalFile"
                                        type="file"
                                        style={{ display: 'none' }}
                                        accept=".jpg, .jpeg, .png, .zip"
                                        onChange={(e) => handleFileChange(e, "originalFile")} // 파일 선택 시 파일명 처리
                                    />
                                </ul>
                            </div>
                        </div>
                        <div className="termsTitle">이용약관</div>
                        <div className="box noTop">
                            <div className="terms">
                                <ul>아래의 개인정보 수집 및 이용에 동의합니다. 개인정보의 수집 및 이용에 대한 동의를 거부하실 수 있으나 이 경우 신고 접수가 어려울 수 있습니다.</ul>
                                <ul className="part">
                                    <li>수집항목</li>
                                    <li>
                                        <dl><span>신고자 정보</span>신고자 정보 (필수) 이름, 이메일주소, 전화번호</dl>
                                        <dl><span>권리자 정보</span>권리자 정보: (필수) 이름, 전화번호, 신분증 사본, 단체번호</dl>
                                        <dl><span>신분증이란?</span>신분증이란? 주민등록증, 운전면허증, 여권</dl>
                                    </li>
                                </ul>
                                <ul className="part">
                                    <li>수집목적</li>
                                    <li>
                                        <dl>본인확인, 신고 접수/중복 확인 및 결과 회신, 분쟁조정을 위한 기록 보관</dl>
                                    </li>
                                </ul>
                                <ul className="part">
                                    <li>이용기간</li>
                                    <li>
                                        <dl>상기 수집항목은 전자상거래법 등 관련 법령에 따라 3년간 보관 후 파기합니다.</dl>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="agree">
                            <Checkbox id="agree" onChange={(e) => setAgreeChecked(e.target.checked)}>개인정보 수집 및 이용 동의</Checkbox>
                            <div className="req">필수</div>
                        </div>
                        <button type="submit" className="btn" disabled={!isFormValid}>제출하기</button>
                    </div>
                </form>
            </section>
            {isLoading && (
                <div className="fullLoader">
                    <div className="inBox">
                        <div className="loader"></div>
                    </div>
                </div>
            )}
        </>
    );
}

export default FeedBack;
