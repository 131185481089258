import React, { useEffect, useState } from 'react';
import { useApi } from '../../js/module/api';
import htmlToText from "../../js/module/HtmlToText";


function KeywordNaverBox({ keyword }) {
    const apiUrl = process.env.REACT_APP_API_URL;
    const { apiRequest } = useApi();
    const [listData, setListData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchRanking = async () => {
            if (!keyword) return;
            setLoading(true);
            try {
                const url = apiUrl + `v1/search/naver?query=${keyword}&limit=6`;
                const data = await apiRequest(url, {}, 'GET', undefined, undefined, false, false);
                setListData(data.data);
            } catch (error) {
                console.error('데이터를 불러오는데 실패했습니다.', error);
            } finally {
                setLoading(false);
            }
        };
        fetchRanking().then();
        // eslint-disable-next-line
    }, [apiRequest, apiUrl,keyword]);

    const moveUrl = (url) => {
        window.open(url);
    };

    return (
        <div className="blogCafe">
            <div className="stit">블로그 · 카페</div>
            <a href={`https://section.blog.naver.com/Search/Post.naver?keyword=${keyword}`} target="_blank" rel="noopener noreferrer"><div className="more">바로가기<div className="arw s16"></div></div></a>
            <div className="list">
                {loading ? (
                    <div style={{ height: '1000px' }}>
                        <div className="listLoader" style={{marginTop:'100px'}}>
                            <div className="loader"></div>
                        </div>
                    </div>
                ) : listData?.length === 0 ? (
                    <div className="listNoneDefault">
                        검색된 컨텐츠가 없습니다.
                    </div>
                ) : (
                    listData?.map((item, index) => (
                        <ul key={index} className={item.searchType} onClick={() => moveUrl(item.link)}>{htmlToText(item.title)}</ul>
                    ))
                )}
            </div>
        </div>
    );
}

export default KeywordNaverBox;
