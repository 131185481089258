
import React, {useEffect, useState} from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation, Pagination} from "swiper/modules";
import RankingNewsRight from "../../component/rankingNewsRight";
import {useApi} from "../../js/module/api";

function NewsListRanking() {

    const apiUrl = process.env.REACT_APP_API_URL;
    const { apiRequest } = useApi();
    const [rankData, setRankData] = useState([]);

    useEffect(() => {
        const fetchRankNews = async () => {
            try {
                const url = apiUrl + `v1/main/ranking-news?keywordCount=10`;
                const data = await apiRequest(url, {}, 'GET', undefined, undefined, false, true);
                setRankData(data.data);
            } catch (error) {
                console.error('데이터를 불러오는데 실패했습니다.', error);
            }
        };
        fetchRankNews().then();

    }, [apiRequest,apiUrl]);

    return (
        <>
            <Swiper
                slidesPerView={1}
                spaceBetween={0}
                pagination={{
                    clickable: true,
                    el: '.swiper-pagination',
                }}
                navigation={{
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                }}
                modules={[Pagination, Navigation]}
                className="rankChartRt"
            >
                {rankData?.slice(0, 3).map((item, index) => (
                    <SwiperSlide key={index}>
                        <RankingNewsRight rankingData={item} />
                    </SwiperSlide>
                ))}
            </Swiper>
            <div className="swiper-button-next"></div>
            <div className="swiper-button-prev"></div>
            <div className="swiper-pagination"></div>
        </>
    );
}

export default NewsListRanking;
