// src/atoms/AuthProvider.js
import React, { useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { authState } from './authAtom';
import { jwtDecode } from 'jwt-decode';

const AuthProvider = ({ children }) => {
    const setAuthState = useSetRecoilState(authState);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const token = sessionStorage.getItem('authToken');
        if (token) {
            const decoded = jwtDecode(token);
            const user = {
                "id": decoded.sub,
                "name": decoded.name,
                "exp": decoded.exp
            };
            setAuthState({
                isAuthenticated: true,
                token: token,
                user: user,
            });
        }
        setLoading(false);
    }, [setAuthState]);

    if (loading) {
        return <div></div>;
    }

    return children;
};

export default AuthProvider;
