import React, {useEffect, useRef, useState} from "react";
import StickyNewsDetail from "../layout/stickyNewsDetail";
import {Link, useNavigate, useParams} from "react-router-dom";
import { useApi } from '../../js/module/api';
import { formatDate } from '../../js/module/formatDate';
import KakaoShareButton from '../../component/KakaoShareButton';
import htmlToText from '../../js/module/HtmlToText';
import decodeHtmlEntities from "../../js/module/HtmlDecode";
import useCopyUrl from '../../js/module/useCopyUrl';
import CommentWrap from "./comment/comment";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css';
import Lottie from "lottie-react";
import NoNews from "../../assets/json/lottie_404_news.json";
import PopularVoteBox from "../../component/populaVoteBox";
import NewsSubMenu from "./submenu";

function NewsDetail() {
    const apiUrl = process.env.REACT_APP_API_URL;
    const currentUrl = window.location.href;
    const [newsContents, setNewsContents] = useState([]);
    const [emotions, setEmotion] = useState([]);
    const [resent, setResent] = useState([]);
    const { categoryId, newsId } = useParams();
    const [isFontSizeBoxVisible, setIsFontSizeBoxVisible] = useState(false);
    const [isShareBoxVisible, setIsShareBoxVisible] = useState(false);
    const [selectedFontSize, setSelectedFontSize] = useState('ftSz18');
    const [pageError, setPageError] = useState(false); // 404 에러 상태 관리
    const copyUrlToClipboard = useCopyUrl();
    const { apiRequest } = useApi();
    const popularNews = 10;
    const navigate = useNavigate();
    const widgetRef = useRef(null);

    useEffect(() => {
        const fetchNews = async () => {
            try {
                const url = apiUrl + `v1/news/${categoryId}/${newsId}?popularNews=${popularNews}`;
                const data = await apiRequest(url, {}, 'GET', undefined, undefined, false, false);

                if (data.status === 404) {
                    setPageError(true);
                    return;
                }

                setEmotion(data.data.emotions);

                // HTML 파싱 후 첫 번째 <h2> 태그 내부에 없는 <p> 태그를 선택
                const parser = new DOMParser();
                const doc = parser.parseFromString(data.data.newsDeatil.content, 'text/html');
                const paragraphs = doc.querySelectorAll('p');

                let firstValidP = null;

                // 첫 번째 <h2> 내부에 없는 <p> 태그 찾기
                for (let p of paragraphs) {
                    if (!p.closest('h2')) {
                        firstValidP = p;
                        break;
                    }
                }

                // 첫 번째 유효한 <p> 태그 뒤에 광고 태그 및 스크립트 추가
                if (firstValidP) {
                    const teadsDiv = document.createElement('div');
                    teadsDiv.id = 'teads-slot';

                    const teadsScript = document.createElement('script');
                    teadsScript.type = 'text/javascript';
                    teadsScript.className = 'teads';
                    teadsScript.async = true;
                    teadsScript.src = '//a.teads.tv/page/215463/tag';

                    firstValidP.insertAdjacentElement('afterend', teadsDiv);
                    teadsDiv.insertAdjacentElement('afterend', teadsScript);

                    // 변환된 HTML을 다시 설정
                    setNewsContents({
                        ...data.data.newsDeatil,
                        content: doc.body.innerHTML
                    });
                } else {
                    setNewsContents(data.data.newsDeatil); // 유효한 <p>가 없을 경우 원본 내용 설정
                }

                setResent(data.data.resents);
                setPageError(false);

            } catch (error) {
                if (error.status === 404) {
                    setPageError(true);
                }
                console.log("page: " + error);
            }
        };

        if (categoryId && newsId) {
            fetchNews().then();
        }
    }, [categoryId, newsId, apiRequest, apiUrl]);


    const sendEmotion = async (type) => {
        try {
            const url = `${apiUrl}v1/emotions/save-and-return-summary`;
            const params = {
                "emotionType": type,
                "entityType": "NEWS",
                "entityId": newsId
            };
            const response = await apiRequest(url, params, 'POST');
            setEmotion(response.data);
        } catch (error) {
            console.error('Error sending emotion:', error);
        }
    };

    const toggleFontSizeBoxVisibility = () => {
        setIsFontSizeBoxVisible(prevState => !prevState);
    };
    const closeFontSizeBox = () => {
        setIsFontSizeBoxVisible(false);
    };
    const toggleShareBoxVisibility = () => {
        setIsShareBoxVisible(prevState => !prevState);
    };
    const closeShareBox = () => {
        setIsShareBoxVisible(false);
    };

    const handleFontSizeChange = (size) => {
        setSelectedFontSize(size);
        closeFontSizeBox();
    };

    const moveDefault = (url) => {
        navigate(url);
    };

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://dctrend.rankify.best/widget/showWidget.js?code=k6einmvy7reraev8&target=_self';
        script.async = true;
        widgetRef.current.appendChild(script);

        return () => {
            // 컴포넌트 언마운트 시 스크립트 제거
            if (widgetRef.current) {
                // eslint-disable-next-line
                widgetRef.current.innerHTML = '';
            }
        };
    }, []);

    return (
        <>
            {!pageError && (
                <>
                    <StickyNewsDetail title={decodeHtmlEntities(newsContents.title)} onShareClick={toggleShareBoxVisibility} onFontClick={toggleFontSizeBoxVisibility} />
                    <NewsSubMenu />
                    <section className="contentsWrap">
                        <div className="newsLayout">
                            <div className="leftWrap">
                                <div className="articleWrap">
                                    <div className="category ftSz16">{newsContents.categoryName}</div>
                                    <div className="articleTitle ftSz36">{newsContents.title ? decodeHtmlEntities(newsContents.title) : <Skeleton width="100%" height={46} />}</div>
                                    <div className="info">
                                        {newsContents.authors && (
                                            <ul className="publisher">{newsContents.authors} 기자</ul>
                                        )}
                                        <ul className="date">
                                            {newsContents && newsContents.publishedAt && (
                                                <>입력 {formatDate(newsContents.publishedAt)}</>
                                            )}
                                        </ul>
                                        <ul className="btn">
                                            <img src="https://cdn.trend.rankify.best/dctrend/front/images/btn_share.svg" onClick={toggleShareBoxVisibility} alt=""/>
                                            <img src="https://cdn.trend.rankify.best/dctrend/front/images/btn_fontsize.svg" onClick={toggleFontSizeBoxVisibility} alt=""/>
                                        </ul>
                                    </div>
                                    <div className="layerBox">
                                        {isShareBoxVisible && (
                                            <div className="box share">
                                                <div className="tit">공유</div>
                                                <div className="close" onClick={closeShareBox}>
                                                    <img src="https://cdn.trend.rankify.best/dctrend/front/images/btn_close_gray.svg" alt="Close" style={{ cursor: 'pointer' }} />
                                                </div>
                                                <div className="cont">
                                                    <ul onClick={copyUrlToClipboard}>
                                                        <li><div className="icoUrl"></div></li>
                                                        <li>URL복사</li>
                                                    </ul>
                                                    <ul>
                                                        <li>
                                                            <KakaoShareButton
                                                                title={decodeHtmlEntities(newsContents.title)}
                                                                description={htmlToText(newsContents.content,100)}
                                                                imageUrl={newsContents.thumbnail}
                                                                linkUrl={currentUrl}
                                                            />
                                                        </li>
                                                        <li>카카오톡</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        )}
                                        {isFontSizeBoxVisible && (
                                            <div className="box font">
                                                <div className="tit">글자 크기 설정</div>
                                                <div className="close" onClick={closeFontSizeBox}>
                                                    <img src="https://cdn.trend.rankify.best/dctrend/front/images/btn_close_gray.svg" alt="Close" style={{ cursor: 'pointer' }} />
                                                </div>
                                                <div className="cont">
                                                    <ul
                                                        className={`ftSz16 ${selectedFontSize === 'ftSz16' ? 'on' : ''}`}
                                                        onClick={() => handleFontSizeChange('ftSz16')}
                                                    >
                                                        <li>가</li>
                                                        <li>작게</li>
                                                    </ul>
                                                    <ul
                                                        className={`ftSz18 ${selectedFontSize === 'ftSz18' ? 'on' : ''}`}
                                                        onClick={() => handleFontSizeChange('ftSz18')}
                                                    >
                                                        <li>가</li>
                                                        <li>보통</li>
                                                    </ul>
                                                    <ul
                                                        className={`ftSz20 ${selectedFontSize === 'ftSz20' ? 'on' : ''}`}
                                                        onClick={() => handleFontSizeChange('ftSz20')}
                                                    >
                                                        <li>가</li>
                                                        <li>크게</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="contents">
                                        <div className={`break-words content ${selectedFontSize}`}>
                                            <div dangerouslySetInnerHTML={{ __html: newsContents.content }} />
                                        </div>
                                    </div>
                                    {newsContents.keywords && newsContents.keywords.length > 0 && (
                                        <div className="tag">
                                            {newsContents.keywords.map((keyword, index) => (
                                                <ul key={index} onClick={()=>{moveDefault(`/search/${keyword}`)}}>#{keyword}</ul>
                                            ))}
                                        </div>
                                    )}
                                </div>
                                <div className="trendWidgetWrap">
                                    <div ref={widgetRef}></div>
                                </div>
                                <div className="sectionTitleSub">
                                    <div className="title">이 기사, 어떠셨나요?</div>
                                </div>
                                <div className="newsVote">
                                    {emotions.map((item, index) => (
                                        <ul key={index} onClick={() => sendEmotion(item.emotionType)}>
                                            <li><img src={item.iconUrl} alt={item.name} /></li>
                                            <li>{item.name}</li>
                                            <li>{item.count}</li>
                                        </ul>
                                    ))}
                                </div>
                                <div id="commentPoint"></div>
                                <CommentWrap id={newsId} type="news"/>
                            </div>
                            <div className="rightWrap">
                                <div className="rightSticky">
                                    <PopularVoteBox/>
                                    <div className="stickyTitle"><span>{newsContents.categoryName}</span> 주요뉴스</div>
                                    <div className="popularNewsRight">
                                        {resent.map((item,index) => (
                                            <Link key={item.newsId} to={`/news/trend/detail/${item.categoryId}/${item.newsId}`}>
                                                <ul>
                                                    <li>{index+1}</li>
                                                    <li>{decodeHtmlEntities(item.title)}</li>
                                                    <li>
                                                        <img
                                                            src={item.thumbnail || "https://cdn.trend.rankify.best/dctrend/front/images/defaultImage_dt.svg"}
                                                            alt={item.title || 'No Image'}
                                                            onError={(e) => { e.target.src = "https://cdn.trend.rankify.best/dctrend/front/images/defaultImage_dt.svg"; }}
                                                        />
                                                    </li>
                                                </ul>
                                            </Link>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </>
            )}
            {pageError && (
                <section className="errNewsDetail">
                    <div className="img"><Lottie animationData={NoNews} loop={true} /></div>
                    <div className="title">페이지를 찾을 수 없습니다.</div>
                    <div className="txt">잘못된 주소이거나 해당 컨텐츠가<br />삭제되었을 수 있습니다.</div>
                    <Link to="/"><div className="btn">메인으로</div></Link>
                </section>
            )}
        </>
    );
}

export default NewsDetail;
