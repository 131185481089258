import { useNavigate, useLocation } from 'react-router-dom'; // useLocation 추가
import { useRecoilValue } from 'recoil';
import { authState } from '../../atoms/authAtom';

export const useAlertNavigation = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { isAuthenticated } = useRecoilValue(authState);

    const handleNavigationWithAlert = (loginPath) => {
        if (!isAuthenticated) {
            if (window.confirm('소셜로그인 후 디시트렌드 서비스를 이용하실 수 있습니다.')) {
                sessionStorage.setItem('originalPath', location.pathname);
                navigate('/login');
            }
        } else {
            navigate(loginPath); // 인증된 경우에만 해당 경로로 이동
        }
    };

    return handleNavigationWithAlert;
};
