import { Link, useLocation } from "react-router-dom";
import React from "react";
import { useAppContext } from '../../component/context';

function NewsSubMenu() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const { categoryList } = useAppContext();
    const getLinkClassName = (path) => {
        const [linkPath, linkSearch] = path.split('?');
        const linkQueryParams = new URLSearchParams(linkSearch || '');

        return location.pathname === linkPath && [...linkQueryParams.entries()].every(([key, value]) => queryParams.get(key) === value) ? "on" : "";
    };

    const isActive = (path, groupCode) => {
        const pathSegments = location.pathname.split('/');

        // 기존 형식: /news/trend/{groupCode}/1
        if (pathSegments[3] !== 'detail') {
            return path.includes(pathSegments[3]);
        }

        // 새로 추가된 형식: /news/trend/detail/{categoryId}/{postId}
        const categoryId = parseInt(pathSegments[4], 10);

        // categoryList에서 해당 카테고리의 그룹을 찾아서 groupCode와 비교
        const matchedGroup = categoryList.find(group =>
            group.categories.some(category => category.id === categoryId)
        );

        return matchedGroup && matchedGroup.groupCode === groupCode;
    };

    return (
        <div className="newsSubMenu">
            <ul className="menu">
                <li className="list on">
                    <Link to="/news/trend">
                        <dl className={getLinkClassName("/news/trend")}>전체</dl>
                    </Link>
                    {categoryList.map((item,index) => (
                        <Link key={index} to={`/news/trend/${item.groupCode}/1`}>
                            <dl className={isActive(`/news/trend/${item.groupCode}`, item.groupCode) ? "on" : ""}>{item.groupName}</dl>
                        </Link>

                    ))}
                </li>
            </ul>
        </div>
    );
}

export default NewsSubMenu;
