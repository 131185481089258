import React, { useRef, useEffect,useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { authState } from "../../atoms/authAtom";
import { searchState } from "../../atoms/searchAtom";
import {useAppContext} from "../../component/context";

const items = [];

function TopPcRight() {
    const auth = useRecoilValue(authState);
    const setAuthState = useSetRecoilState(authState);
    const navigate = useNavigate();
    const location = useLocation();
    const suggestionsRef = useRef(null);
    const inputRef = useRef(null); // Reference for the input element
    const { query, showSearchInput } = useRecoilValue(searchState);
    const setSearchState = useSetRecoilState(searchState);
    const [suggestions, setSuggestions] = useState([]);
    const { ticket } = useAppContext();

    const handleChange = (event) => {
        const value = event.target.value;
        setSearchState(prevState => ({ ...prevState, query: value }));

        if (value.length > 0) {
            const filteredSuggestions = items
                .filter(item =>
                    item.label.toLowerCase().includes(value.toLowerCase())
                )
                .slice(0, 5); // Limit suggestions to 5 items
            setSuggestions(filteredSuggestions);
        } else {
            setSuggestions([]);
        }
    };

    const handleSuggestionClick = (suggestion) => {
        setSearchState(prevState => ({
            ...prevState,
            query: suggestion.label,
            showSearchInput: false
        }));
        setSuggestions([]);
        navigate(`/search?query=${encodeURIComponent(suggestion.label)}`);
    };

    const handleSearchClick = () => {
        if (query.trim() === '') {
            alert('검색어를 입력해주세요.');
        } else {
            navigate(`/search/${encodeURIComponent(query)}`);
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSearchClick();
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (suggestionsRef.current && !suggestionsRef.current.contains(event.target)) {
                setSuggestions([]);
                setSearchState(prevState => ({ ...prevState, showSearchInput: false }));
            }
        };

        const handleScroll = () => {
            if (showSearchInput) {
                setSuggestions([]);
                setSearchState(prevState => ({ ...prevState, showSearchInput: false }));
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        window.addEventListener('scroll', handleScroll);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            window.removeEventListener('scroll', handleScroll);
        };
    }, [showSearchInput, setSearchState]);

    useEffect(() => {
        if (location.pathname === '/search') {
            setSearchState(prevState => ({ ...prevState, showSearchInput: true }));
        } else {
            setSearchState(prevState => ({ query: '', showSearchInput: false }));
            setSuggestions([]);
        }

        // Remove focus from input when navigating away from /search
        if (inputRef.current) {
            inputRef.current.blur();
        }
    }, [location, setSearchState]);

    const handleLogout = () => {
        sessionStorage.removeItem('authToken');
        localStorage.removeItem('com.naver.nid.oauth.state_token');
        localStorage.removeItem('com.naver.nid.access_token');
        localStorage.removeItem('kakao_448db9e204b79a4e02cbf3f062a0543a');
        setAuthState({ isAuthenticated: false, token: null, user: null });
        sessionStorage.removeItem('totalVoteTicket')
        navigate('/');
    };

    const toggleSearchInput = () => {
        setSearchState(prevState => ({ ...prevState, showSearchInput: !prevState.showSearchInput }));
    };

    return (
        <div className="pc">
            <div className="search">
                <div className="searched">
                    <div className="bg">
                        <div className="keyword">강하늘<img src="https://cdn.trend.rankify.best/dctrend/front/images/btn_close_gray.svg" alt=""/></div>
                    </div>
                </div>
                <div className={`inp ${showSearchInput ? 'visible' : ''}`}>
                    <input
                        ref={inputRef} // Attach ref to input
                        name="search"
                        autoComplete="off"
                        type="text"
                        value={query}
                        onChange={handleChange}
                        onKeyPress={handleKeyPress}
                        placeholder="검색어를 입력하세요"
                    />
                    <img className="clickSearch" src="https://cdn.trend.rankify.best/dctrend/front/images/ico_search.svg" alt="" onClick={handleSearchClick} />
                </div>
                <img className="openSearch" src="https://cdn.trend.rankify.best/dctrend/front/images/ico_search.svg" alt="" onClick={toggleSearchInput} />
                {suggestions.length > 0 && (
                    <div className="suggestion" ref={suggestionsRef}>
                        {suggestions.map((suggestion, index) => (
                            <ul key={index} onClick={() => handleSuggestionClick(suggestion)}>
                                <li>{suggestion.label}</li>
                                <li>{suggestion.path}</li>
                            </ul>
                        ))}
                    </div>
                )}
            </div>
            <Link to="/mypage">
                <ul className="btn">
                    <img src="https://cdn.trend.rankify.best/dctrend/front/images/ico_ticket.svg" alt="" />
                    투표권 구매
                </ul>
            </Link>
            {auth.isAuthenticated ? (
                <>
                    <Link to="/mypage">
                        <div className="myVote">
                            내 투표권<span>{ticket}개</span>
                        </div>
                    </Link>
                    <ul className="logout" onClick={handleLogout}>로그아웃</ul>
                </>
            ) : (
                <Link to="/login">
                    <div className="login">로그인</div>
                </Link>
            )}
        </div>
    );
}

export default TopPcRight;
