import StickyDefault from "../layout/stickyDefault";
import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import TicketBox from "./ticketBox";
import { useApi } from "../../js/module/api";
import { formatNumberWithCommas } from "../../js/module/numberFormatter";
import { Pagination } from "antd";
import { formatDateKr } from "../../js/module/formatDate";

function PurchaseList() {
    const apiUrl = process.env.REACT_APP_API_URL;
    const { apiRequest } = useApi();
    const navigate = useNavigate();
    const { pageNumber } = useParams();
    const [list, setList] = useState([]);
    const [currentPage, setCurrentPage] = useState(parseInt(pageNumber) || 1);
    const [totalItems, setTotalItems] = useState(0);
    const pageSize = 10;
    const [isRefund, setIsRefund] = useState(false);
    const [refundItem, setRefundItem] = useState(null); // 결제 취소할 아이템 저장

    // 리스트 가져오기
    const fetchList = useCallback(async (page) => {
        const fetchHistory = async () => {
            try {
                const url = apiUrl + `v1/order/list?page=${page}&size=${pageSize}`;
                const data = await apiRequest(url, {}, 'GET', undefined, undefined, false, false);
                setList(data.data.content);
                setTotalItems(data.data.metadata.totalCounts);
            } catch (error) {
                console.error(error);
            }
        };
        fetchHistory();
    }, [apiRequest, apiUrl]);

    useEffect(() => {
        const pageFromUrl = parseInt(pageNumber) || 1;
        setCurrentPage(pageFromUrl);
        fetchList(pageFromUrl).then();
    }, [pageNumber, fetchList]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
        navigate(`/mypage/purchase/${page}`);
    };

    // 결제취소 레이어 오픈 및 아이템 정보 저장
    const handleRefundClick = (item) => {
        setRefundItem(item);
        setIsRefund(true);
    };

    // 결제취소 요청 처리
    const handleRefundConfirm = async () => {
        if (!refundItem) return;
        try {
            const cancelUrl = apiUrl + `v1/order/cancel/${refundItem.id}`;
            await apiRequest(cancelUrl, {}, 'GET', undefined, undefined, false, false);
            setIsRefund(false); // 레이어 닫기
            setTimeout(() => {
            alert("결제취소가 완료되었습니다.");
            window.location.reload();
        }, 0);
        } catch (error) {
            console.error("결제 취소 오류:", error);
        }
    };

    return (
        <>
            <StickyDefault />
            <section className="contentsWrap">
                <div className="myPage">
                    <div className="topTitle">마이페이지</div>
                    <TicketBox />
                    <div className="tab">
                        <ul><Link to="/mypage">투표권 구매</Link></ul>
                        <ul><Link to="/mypage/voted/1">투표 내역</Link></ul>
                        <ul className="on">구매 내역</ul>
                    </div>
                    <div className="orderList">
                        <div className="tit">구매 내역</div>
                        <div className="list">
                            {list?.map((item, index) => {
                                const orderDate = formatDateKr(item.orderDate);
                                const [date, time] = orderDate.split(' ');
                                const [year, month, day] = date.split('.');
                                const formattedDate = `${year.slice(-2)}.${month}.${day}`;
                                const formattedTime = time;
                                return (
                                    <ul key={index}>
                                        <li className="date">
                                            {formattedDate}
                                            <div className="time">{formattedTime}</div>
                                            <div className="ticket">잔여수량 {formatNumberWithCommas(item.voteTickets)}개</div>
                                        </li>
                                        <li className="item">{item.productName}</li>
                                        <li className="price">{formatNumberWithCommas(item.amount)}원</li>
                                        <li className={`state ${item.status === 'PAYMENT_CANCELLED' ? 'cancel' : ''}`}>
                                            {item.status === "PAYMENT_COMPLETED" ? (
                                                <div className="btn" onClick={() => handleRefundClick(item)}>결제취소</div>
                                            ) : (
                                                <div>{item.statusDescription}</div>
                                            )}
                                        </li>
                                    </ul>
                                );
                            })}
                            {totalItems === 0 && (
                                <div className="listNoneDefault">투표내역이 없습니다.</div>
                            )}
                        </div>
                        <div className="paging">
                            <Pagination
                                onChange={handlePageChange}
                                align="center"
                                defaultCurrent={1}
                                total={totalItems}
                                pageSize={pageSize}
                                current={currentPage}
                                showSizeChanger={false}
                                responsive={true}
                            />
                        </div>
                    </div>
                </div>
            </section>

            {isRefund && refundItem && (
                <section className="layerDefault refund">
                    <div className="inbox">
                        <div className="top">구매 취소</div>
                        <div className="close" onClick={() => setIsRefund(false)}><img src="https://cdn.trend.rankify.best/dctrend/front/images/btn_close_gray.svg" alt=""/></div>
                        <div className="cont">
                            <div className="refundBox">
                                <div className="refundInfo">
                                    <ul>
                                        <li>구매일</li>
                                        <li>{formatDateKr(refundItem.orderDate)}</li>
                                    </ul>
                                    <ul>
                                        <li>구매상품</li>
                                        <li className="item">
                                            <div className="qty">{refundItem.productName.replace("디시트렌드 ", "")}</div>
                                            <div className="price">{formatNumberWithCommas(refundItem.amount)}원</div>
                                        </li>
                                    </ul>
                                    <ul>
                                        <li>총 환불 금액</li>
                                        <li><span>{formatNumberWithCommas(refundItem.amount)}</span>원</li>
                                    </ul>
                                </div>
                                <div className="botInfo">
                                    <div className="tit">구매 취소 안내</div>
                                    <div className="txt">
                                        <ul>구매 시 결제한 수단으로 환불됩니다.</ul>
                                        <ul>무료 투표권은 환불 대상이 아닙니다.</ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bot">
                            <ul className="cancel" onClick={() => setIsRefund(false)}>취소</ul>
                            <ul className="confirm" onClick={handleRefundConfirm}>구매취소</ul>
                        </div>
                    </div>
                </section>
            )}
        </>
    );
}

export default PurchaseList;
