// src/utils/extractKeywordInfo.js

export function extractKeywordInfo(keywordInfoString) {
    // keywordInfoString이 유효한지 확인
    if (typeof keywordInfoString !== 'string') {
        console.warn("keywordInfo is not a valid string:", keywordInfoString);
        return []; // 유효하지 않은 경우 빈 배열 반환
    }

    let keywordInfo;

    try {
        keywordInfo = JSON.parse(keywordInfoString);
    } catch (error) {
        console.error("JSON parsing error:", error);
        return []; // 파싱 오류 발생 시 빈 배열 반환
    }

    // 필요한 값 추출 및 빈 값 처리
    const result = {
        nationality: keywordInfo.nationality || "-",
        birthday: keywordInfo.birthday || "-",
        gender: keywordInfo.gender === 'male' ? '남자' : (keywordInfo.gender === 'female' ? '여자' : "-"), // 성별 변환
        genre: (keywordInfo.genre && Array.isArray(keywordInfo.genre) && keywordInfo.genre.length > 0)
            ? keywordInfo.genre.slice(0, 2).join(", ") // 배열의 첫 두 개 항목만 사용
            : "-", // 배열이 비어있거나 배열이 아닐 경우 "-"
        activity_type: keywordInfo.activity_type === 'solo' ? '솔로' : (keywordInfo.activity_type === 'group' ? '그룹' : "-"), // 활동 유형 변환
        active_years: (keywordInfo.active_years && Array.isArray(keywordInfo.active_years) && keywordInfo.active_years.length > 0)
            ? keywordInfo.active_years.slice(0, 2).join(", ") // 배열의 첫 두 개 항목만 사용
            : "-", // 배열이 비어있거나 배열이 아닐 경우 "-"
        debut_date: keywordInfo.debut_date || "-",
    };

    // 빈 값이 아닌 항목만 추출하여 정렬
    const orderedResult = Object.entries(result)
        .filter(([_, value]) => value !== "-") // "-" 값 필터링
        .concat(Object.entries(result).filter(([_, value]) => value === "-")) // "-" 값 뒤로 이동
        .reduce((acc, [key, value]) => {
            acc[key] = value; // 객체로 재구성
            return acc;
        }, {});

    // 각 항목에 대해 타이틀과 값을 구분하여 반환
    const formattedResult = Object.entries(orderedResult).map(([key, value]) => {
        let title = "";
        switch (key) {
            case "nationality":
                title = "국적";
                break;
            case "birthday":
                title = "생일";
                break;
            case "gender":
                title = "성별";
                break;
            case "genre":
                title = "장르";
                break;
            case "activity_type":
                title = "활동유형";
                break;
            case "active_years":
                title = "활동시기";
                break;
            case "debut_date":
                title = "데뷔";
                break;
            default:
                title = key; // 기본적으로 키를 타이틀로 사용
        }

        return {
            title: title, // 타이틀로 한국어 설명 사용
            value: value // 값으로 실제 값 사용
        };
    });

    return formattedResult; // 결과 배열 반환
}
