const goods = [
    {
        id: 1,
        title: '디시트렌드 투표권 10개',
        price: 1000,
        qty: 10
    },
    {
        id: 2,
        title: '디시트렌드 투표권 20개',
        price: 1900,
        qty: 20
    },
    {
        id: 3,
        title: '디시트렌드 투표권 30개',
        price: 2700,
        qty: 30
    },
    {
        id: 4,
        title: '디시트렌드 투표권 50개',
        price: 4500,
        qty: 50
    },
    {
        id: 5,
        title: '디시트렌드 투표권 100개',
        price: 8500,
        qty: 100
    },
    {
        id: 6,
        title: '디시트렌드 투표권 1,000개',
        price: 85000,
        qty: 1000
    },
];

export default goods;
