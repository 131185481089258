import React, {useEffect, useState } from 'react';
import WriteBox from "./writeBox";
import SideMenuBox from "./sideMenu";
import VoteBox from "./voteBox";
import { useApi } from '../../../js/module/api';
import {formatDateKr} from '../../../js/module/formatDate';
import {useLocation} from "react-router-dom";

function CommentWrap(props) {
    const apiUrl = process.env.REACT_APP_API_URL; // 환경 변수에서 API URL 가져오기
    const { apiRequest } = useApi();
    const location = useLocation();
    const [comment, setComment] = useState([]);  // 불러온 아이템들을 저장
    const [metaData, setMetaData] = useState([]);  // 불러온 아이템들을 저장
    const [page, setPage] = useState(1);     // 현재 페이지 번호
    const [sort, setSort] = useState('');
    const [hasMore, setHasMore] = useState(true);  // 더 불러올 데이터가 있는지 여부
    const [showDep2, setShowDep2] = useState({});

    const fetchComment = async (page, sort) => {
        try {
            const url = apiUrl + `v1/comments/${props.type}?entityId=${props.id}&page=${page}&size=10&sort=${sort}`;
            const data = await apiRequest(url, {}, 'GET', undefined, undefined, false, false);
            setMetaData(data.data.metadata);
            if (data.data.content.length > 0) {
                setComment((prevItems) => [...prevItems, ...data.data.content]);
            }
            if (data.data.metadata.currentPage === data.data.metadata.totalPages) {
                setHasMore(false);
            }
        } catch (error) {
            console.error('데이터를 불러오는데 실패했습니다.', error);
        }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        fetchComment(page, sort).then(); // 최신 페이지와 sort 값을 사용하여 API 호출
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, sort, location]); // page와 sort 값이 바뀔 때마다 실행

    const loadMore = () => {
        setPage((prevPage) => prevPage + 1);
    };

    const handleReplyClick = (index) => {
        setShowDep2(prevState => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    };

    const commentReset = () => {
        setComment([]); // 기존 댓글 목록을 지웁니다.
        setPage(1); // 페이지 번호를 1로 초기화합니다.
        fetchComment(1, sort).then(); // 페이지 1로 초기화
        document.body.classList.remove("notScroll");
    };

    const handleSortChange = (newSort) => {
        setPage(1); // 페이지 번호 초기화
        setComment([]); // 기존 댓글 목록 초기화
        setSort(newSort); // 새로운 sort 값 설정
    };

    return (
        <>
            <div className="commentWrap">
                <div className="totalCnt">댓글 {metaData.totalCounts}</div>
                <WriteBox contentsId={props.id} commentType={props.type} commentId={null} saveType="COMMENT" isReset={commentReset} />
                <div className="tab">
                    <ul className={sort === '' ? "on" : ""} onClick={() => handleSortChange('')}>최신순</ul>
                    <ul className={sort === 'likeCount,desc' ? "on" : ""} onClick={() => handleSortChange('likeCount,desc')}>추천순</ul>
                    <ul className={sort === 'replyCount,desc' ? "on" : ""} onClick={() => handleSortChange('replyCount,desc')}>답글순</ul>
                </div>
                <div className="commentList">
                    {metaData.totalCounts > 0 ? (
                        comment.map((comment, index) => (
                            <ul key={index} className="list">
                                <div className="dep1">
                                    <li className="info">
                                        <dl className="name">{comment.nickName}</dl>
                                        <dl className="date">{formatDateKr(comment.createdAt)}</dl>
                                    </li>
                                    <SideMenuBox isReset={commentReset} id={comment.id} commentType={props.type} txt={comment.comment} type="COMMENT" state={comment.status} />
                                    <li className={`cont ${comment.status === 'INACTIVE' ? 'block' : ''}`}>{comment.comment}</li>
                                    <li className="reply" onClick={() => handleReplyClick(index)}>
                                        답글<span>{comment.commentReplies.length}</span>
                                        <div className={`arw s16 ${showDep2[index] ? 'open' : ''}`}></div>
                                    </li>
                                    <VoteBox initialGood={comment.likeCount} initialBad={comment.dislikeCount} id={comment.id} commentType={props.type} type="COMMENT" state={comment.status} />
                                </div>
                                {showDep2[index] && (
                                    <div className="dep2">
                                        {comment.commentReplies.length > 0 ? (
                                            comment.commentReplies.map((reply,replyIndex) => (
                                                <div className="inList" key={replyIndex}>
                                                    <li className="info">
                                                        <dl className="name">{reply.nickName}</dl>
                                                        <dl className="date">{formatDateKr(reply.createdAt)}</dl>
                                                    </li>
                                                    <SideMenuBox isReset={commentReset} id={reply.id} commentType={props.type} txt={reply.comment} type="REPLY" state={reply.status} />
                                                    <li className={`cont ${reply.status === 'INACTIVE' ? 'block' : ''}`}>{reply.comment}</li>
                                                    <VoteBox initialGood={reply.likeCount} initialBad={reply.dislikeCount} id={reply.id} commentType={props.type} type="REPLY" state={reply.status} />
                                                </div>
                                            ))
                                        ) : (
                                            <div className="noReplies">
                                                <ul><img src="https://cdn.trend.rankify.best/dctrend/front/images/ico_nocomment.svg" alt="" /></ul>
                                                등록된 답글이 없습니다.
                                            </div>
                                        )}
                                        <WriteBox contentsId={props.id} commentType={props.type} commentId={comment.id} saveType="REPLY" isReset={commentReset} state={comment.status} />
                                        <div className="replyClose">
                                            <div className="in" onClick={() => handleReplyClick(index)}>
                                                답글 접기<div className="arw s16"></div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </ul>
                        ))
                    ) : (
                        <div className="noComment">
                            <ul><img src="https://cdn.trend.rankify.best/dctrend/front/images/ico_nocomment.svg" alt="" /></ul>
                            등록된 댓글이 없습니다.
                        </div>
                    )}
                </div>
                {hasMore && metaData.totalCounts > 0 && (
                    <div className="btnMore"><div className="in" onClick={loadMore}>댓글더보기<div className="arw"></div></div></div>
                )}
            </div>
        </>
    );
}

export default CommentWrap;
