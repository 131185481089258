import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { authState } from '../../atoms/authAtom';
import menu from './menu.js';
import TopPcRight from "./topPcRight";
import { useAppContext } from '../../component/context';
import SearchSlide from "../../component/searchSlide";
import dayjs from 'dayjs';  // 추가: dayjs 라이브러리를 사용하여 시간 계산

function TopWrap() {
    const auth = useRecoilValue(authState); // 현재 인증 상태를 가져옵니다.
    const setAuthState = useSetRecoilState(authState); // 인증 상태를 업데이트할 수 있는 함수입니다.
    const navigate = useNavigate(); // 페이지 이동을 위한 navigate 훅입니다.
    const location = useLocation(); // 현재 URL 경로를 가져오기 위한 훅입니다.
    const currentPath = location.pathname;
    const { categoryList } = useAppContext();
    const { menuOpen, closeMenu, openMenu, ticket } = useAppContext();
    const [isMenuOpen, setIsMenuOpen] = useState(false); // 추가: 메뉴 열림 상태
    const nameRef = useRef(null);
    const myMenuRef = useRef(null); // 추가: myMenu 참조

    const handleLinkClick = (path) => (event) => {
        event.preventDefault(); // 링크의 기본 동작을 방지
        closeMenu();
        navigate(path); // 페이지를 이동
    };

    // 로그아웃 처리 함수
    const handleLogout = () => {
        sessionStorage.removeItem('authToken');
        sessionStorage.removeItem('totalVoteTicket')
        localStorage.removeItem('kakao_448db9e204b79a4e02cbf3f062a0543a');
        setAuthState({ isAuthenticated: false, token: null, user: null });
        if (menuOpen) {
            closeMenu(); // 메뉴가 열려 있을 경우 닫기
        }
        navigate('/');
    };

    const isActive = (path) => {
        return location.pathname.startsWith(path);
    };

    const isNewsOrRankingPath = currentPath.startsWith('/vote');

    const handleNameClick = () => {
        setIsMenuOpen(!isMenuOpen); // 메뉴 상태 토글
    };

    // 외부 클릭 감지 및 메뉴 닫기
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (nameRef.current && !nameRef.current.contains(event.target) && myMenuRef.current && !myMenuRef.current.contains(event.target)) {
                setIsMenuOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // 토큰만료체크
    useEffect(() => {
        if (!auth.isAuthenticated || !auth.user.exp) return;
        // 만료 시간이 5분 이내인지 확인
        const checkInterval = auth.user.exp - dayjs().unix() <= 300; // 5분 = 300초
        const intervalTime = checkInterval ? 10000 : 300000; // 10초 또는 5분

        const intervalId = setInterval(() => {
            const currentTime = dayjs().unix();
            if (auth.user.exp - currentTime <= 60) {
                handleLogout();
            }
        }, intervalTime); // 설정된 시간 간격에 따라 실행
        return () => clearInterval(intervalId); // 컴포넌트 언마운트 시 interval 해제
        // eslint-disable-next-line
    }, [auth]);

    return (
        <>
            <section className="topWrap">
                <div className="naviDc">
                    <div className="navi">
                        <a href="https://www.dcinside.com/" target="_blank" rel="noreferrer"><ul className="openUrl">디시인사이드</ul></a>
                        <a href="https://gall.dcinside.com/" target="_blank" rel="noreferrer"><ul>갤러리</ul></a>
                        <a href="https://gall.dcinside.com/m" target="_blank" rel="noreferrer"><ul>마이너갤</ul></a>
                        <a href="https://gall.dcinside.com/n" target="_blank" rel="noreferrer"><ul>미니갤</ul></a>
                        <a href="https://gallog.dcinside.com/" target="_blank" rel="noreferrer"><ul>갤로그</ul></a>
                        <a href="https://event.dcinside.com/" target="_blank" rel="noreferrer"><ul>이벤트</ul></a>
                        <a href="https://mall.dcinside.com/?from=A08" target="_blank" rel="noreferrer"><ul>디시콘</ul></a>
                    </div>
                </div>
                <div className="top">
                    <Link to="/"><div className="logo"><img src="https://cdn.trend.rankify.best/dctrend/front/images/logo.svg" alt="" /></div></Link>
                    <TopPcRight />
                    <div className="mo" onClick={openMenu}><img src="https://cdn.trend.rankify.best/dctrend/front/images/btn_menu.svg" alt="" /></div>
                </div>
                <div className="menuBox">
                    <ul className="dep1">
                        <li className="menu">
                            {menu.map((item, index) => (
                                <Link to={item.link} key={index}>
                                    <dl className={`${isActive(item.pathName) ? "on" : ""} ${item.title === "랭킹뉴스" ? "point" : ""}`}>
                                        {item.title}
                                    </dl>
                                </Link>
                            ))}
                        </li>
                    </ul>
                    {isNewsOrRankingPath && (
                        <ul className="dep2">
                            <li className="menu">
                                <dl className="on">
                                    {isActive("/vote") && (
                                        menu[3].subMenu.map((subItem, subIndex) => (
                                            <Link key={subIndex} to={subItem.link}>
                                                <dt className={(isActive(subItem.pathName) || isActive(subItem.pathName2)) ? "on" : ""}>
                                                    {subItem.title}
                                                </dt>
                                            </Link>
                                        ))
                                    )}
                                </dl>
                            </li>
                        </ul>
                    )}
                </div>
            </section>
            <section className={`slideMenu ${menuOpen ? 'on' : ''}`}>
                <div className="top">
                    <a href="/" onClick={handleLinkClick('/')}><ul className="logo"><img src="https://cdn.trend.rankify.best/dctrend/front/images/logo.svg" alt="" /></ul></a>
                    <ul className="close" onClick={closeMenu}><img src="https://cdn.trend.rankify.best/dctrend/front/images/btn_close.svg" alt="" /></ul>
                </div>
                <div className="info">
                    <div className="login">
                        {auth.isAuthenticated ? (
                            <>
                                <div ref={nameRef} className={`name ${isMenuOpen ? 'on' : ''}`} onClick={handleNameClick}>
                                    {auth.user?.name || ''}<div className="arw"></div>
                                </div>
                                {isMenuOpen && (
                                    <div className="myMenu">
                                        <a href="/mypage" onClick={handleLinkClick('/mypage')}><ul><img src="https://cdn.trend.rankify.best/dctrend/front/images/ico_botmenu_my_g.svg" alt=""/>마이페이지</ul></a>
                                        <ul onClick={handleLogout}><img src="https://cdn.trend.rankify.best/dctrend/front/images/ico_logout.svg" alt=""/>로그아웃</ul>
                                    </div>
                                )}
                            </>
                        ) : (
                            <a href="/login" onClick={handleLinkClick('/login')}><div className="signIn">로그인 해주세요<div className="arw"></div></div></a>
                        )}
                    </div>
                    <div className="vote">
                        {auth.isAuthenticated ? (
                            <a href="/mypage" onClick={handleLinkClick('/mypage')}><div className="myVote">내 투표권<span>{ticket}개</span></div></a>
                        ) : (
                            <a href="/mypage" onClick={handleLinkClick('/mypage')}><div className="btn"><img src="https://cdn.trend.rankify.best/dctrend/front/images/ico_ticket.svg" alt="" />투표권 구매</div></a>
                        )}
                    </div>
                </div>
                <div className="scroll">
                    <SearchSlide />
                    <div className="menu">
                        <ul>
                            <li><a href="/news/trend" onClick={handleLinkClick('/news/trend')}>트렌드 뉴스</a></li>
                            <li>
                                <dl>
                                    <a href="/news/trend" onClick={handleLinkClick('/news/trend')}>전체</a>
                                </dl>
                                {categoryList.map((item,index) => (
                                    <dl key={index}>
                                        <a href={`/news/trend/${item.groupCode}/1`} onClick={handleLinkClick(`/news/trend/${item.groupCode}/1`)}>{item.groupName}</a>
                                    </dl>
                                ))}
                            </li>
                        </ul>
                        <ul>
                            <li><a href="/rankNews" onClick={handleLinkClick('/rankNews')}>랭킹뉴스<div className="arw"></div></a></li>
                        </ul>
                        <ul>
                            <li><a href="/ranking/celebrity/popular'" onClick={handleLinkClick('/ranking/popular')}>랭킹<div className="arw"></div></a></li>
                        </ul>
                        <ul>
                            <li><a href="/vote/list/all/1" onClick={handleLinkClick('/vote/list/all/1')}>투표</a></li>
                            <li>
                                <dl>
                                    <a href="/vote/list/all/1" onClick={handleLinkClick('/vote/list/all/1')}>진행중인 투표</a>
                                </dl>
                                <dl>
                                    <a href="/vote/awards" onClick={handleLinkClick('/vote/awards/all/1')}>마감된 투표</a>
                                </dl>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        </>
    );
}

export default TopWrap;
