import React, { useRef } from 'react';
import { Link } from "react-router-dom";
import { useAppContext  } from '../../component/context';

function generateRandomString() {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const length = 8;

    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters[randomIndex];
    }

    return result;
}

function LoginPage() {
    const { naverClientID,kakaoRestApiKey } = useAppContext();
    const naverLoginRef = useRef(null);

    const redirect_uri_kakao = process.env.REACT_APP_REDIRECT_KAKAO;
    const redirect_uri_naver = process.env.REACT_APP_REDIRECT_NAVER;
    const kakaoURL = `https://kauth.kakao.com/oauth/authorize?client_id=${kakaoRestApiKey}&redirect_uri=${redirect_uri_kakao}&response_type=code`
    const naverURL = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${naverClientID}&state=${generateRandomString()}&redirect_uri=${redirect_uri_naver}`
    const handleKakaoLoginClick = () => {
        window.location.href = kakaoURL
    };
    const handleNaverLoginClick = () => {
        window.location.href = naverURL
    };

    return (
        <section className="loginPage">
            <div className="box">
                <div className="logo"><Link to="/"><img src="https://cdn.trend.rankify.best/dctrend/front/images/logo.svg" alt="" /></Link></div>
                <div className="title">간편하게 로그인하고<br />선호하는 분야에 투표해 보세요</div>
                <div className="btn">
                    <ul onClick={handleKakaoLoginClick}><img src="https://cdn.trend.rankify.best/dctrend/front/images/login_btn_kakao.svg" alt="" /></ul>
                    <ul onClick={handleNaverLoginClick}><img src="https://cdn.trend.rankify.best/dctrend/front/images/login_btn_naver.svg" alt="" /></ul>
                    <div id="naverIdLogin" ref={naverLoginRef} style={{ display: 'none' }} />
                </div>
                <div className="info">
                    <ul>디시트렌드의 소셜로그인은 투표권 구매 및 투표권 관리만을 위해 사용됩니다.</ul>
                    <ul>소셜로그인 시, 디시트렌드의 <Link to="/policy">서비스 이용약관</Link> 및 <Link to="/policy/privacy">개인정보 처리방침</Link>에 동의한 것으로 간주합니다.</ul>
                </div>
            </div>
        </section>
    );
}

export default LoginPage;
