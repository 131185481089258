import React, { useEffect, useState } from "react";
import StickyDefault from "../layout/stickyDefault";
import { Checkbox } from 'antd';
import { useSetRecoilState } from 'recoil';
import { authState } from '../../atoms/authAtom';
import {Link, useNavigate} from "react-router-dom";
import { useApi } from '../../js/module/api';

function Withdraw() {
    const apiUrl = process.env.REACT_APP_API_URL;
    const setAuthState = useSetRecoilState(authState);
    const navigate = useNavigate();
    const [checkedItems, setCheckedItems] = useState([]);
    const [isAgreed, setIsAgreed] = useState(false);
    const { apiRequest } = useApi();

    // 항목을 배열로 관리
    const categories = [
        {
            title: "서비스 이용 관련",
            items: [
                { id: 'ck1', label: '서비스 사용 빈도가 낮음.' },
                { id: 'ck2', label: '원하는 투표가 없음.' },
                { id: 'ck3', label: '제공되는 뉴스 콘텐츠가 만족스럽지 않음.' },
                { id: 'ck4', label: '랭킹 정보에 신뢰가 가지 않음.' }
            ]
        },
        {
            title: "기술적 문제",
            items: [
                { id: 'ck5', label: '빈번한 오류가 발생함.' },
                { id: 'ck6', label: '소셜 로그인에 문제가 발생함.' }
            ]
        },
        {
            title: "사용자 경험 관련",
            items: [
                { id: 'ck7', label: '투표 참여 방법이 복잡함.' },
                { id: 'ck8', label: '모바일 환경에서의 이용이 불편함.' }
            ]
        },
        {
            title: "개인정보 및 보안 관련",
            items: [
                { id: 'ck9', label: '개인정보 보호가 우려됨.' },
                { id: 'ck10', label: '소셜 로그인으로 인한 개인정보 유출이 우려됨.' }
            ]
        }
    ];

    useEffect(() => {
        document.body.style.backgroundColor = "#FAFAFC";
        return () => {
            document.body.style.backgroundColor = "#ffffff";
        };
    }, []);

    const handleCheckboxChange = (e) => {
        const { id, checked } = e.target;
        setCheckedItems(prev =>
            checked ? [...prev, id] : prev.filter(item => item !== id)
        );
    };

    const handleAgreeChange = (e) => {
        setIsAgreed(e.target.checked);
    };

    const handleClick = async () => {
        try {
            const reasons = categories
                .flatMap(category => category.items)
                .filter(item => checkedItems.includes(item.id))
                .map(item => item.label);

            const url = `${apiUrl}v1/auth/withdraw`;
            const params = { "reasons": reasons };
            await apiRequest(url, params, 'POST');
            withdrawDctrend();
        } catch (error) {
            console.error('Error sending withdraw request:', error);
        }
    };

    const withdrawDctrend = () => {
        sessionStorage.removeItem('authToken');
        localStorage.removeItem('nick');
        localStorage.removeItem('password');
        localStorage.removeItem('fingerprint');
        localStorage.removeItem('kakao_448db9e204b79a4e02cbf3f062a0543a');
        sessionStorage.removeItem('totalVoteTicket')
        setAuthState({ isAuthenticated: false, token: null, user: null });
        alert('회원탈퇴가 완료되었습니다.');
        navigate('/');
    };

    const isButtonEnabled = () => {
        return checkedItems.length > 0 && isAgreed;
    };

    return (
        <>
            <StickyDefault />
            <section className="contentsWrap">
                <div className="myPage">
                    <div className="topTitle">디시트렌드 탈퇴</div>
                    <div className="withdrawBox">
                        <div className="rBox form">
                            {categories.map((category, idx) => (
                                <ul key={idx}>
                                    <li>{category.title}</li>
                                    <li>
                                        {category.items.map(item => (
                                            <dl key={item.id}>
                                                <Checkbox id={item.id} onChange={handleCheckboxChange}>
                                                    {item.label}
                                                </Checkbox>
                                            </dl>
                                        ))}
                                    </li>
                                </ul>
                            ))}
                        </div>
                        <div className="rBox info">
                            <div className="title">아래 내용을 반드시 확인해 주세요.</div>
                            <div className="agree">
                                <Checkbox id="agree" onChange={handleAgreeChange}>위 내용을 모두 확인하였습니다.</Checkbox>
                                <div className="req">필수</div>
                            </div>
                        </div>
                        <div className="btnBox">
                            <button onClick={handleClick} disabled={!isButtonEnabled()}>탈퇴하기</button>
                            <Link to="/"><ul className="cancel">취소</ul></Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Withdraw;
