import React from 'react';
import { Outlet } from 'react-router-dom';
import TopWrap from './topWrap';
import Footer from "./footer";

function Layout() {

    return (
        <>
            <TopWrap />
                <Outlet />
            <Footer />
        </>
    );
}

export default Layout;
