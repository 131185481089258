import StickyDefault from "../layout/stickyDefault";
import React, {useCallback, useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useApi} from "../../js/module/api";
import TicketBox from "./ticketBox";
import {formatDateKr} from "../../js/module/formatDate";
import {Pagination} from "antd";

function VotedList() {
    const apiUrl = process.env.REACT_APP_API_URL;
    const { apiRequest } = useApi();
    const navigate = useNavigate();
    const { pageNumber } = useParams();
    const [list, setList] = useState([]);
    const [currentPage, setCurrentPage] = useState(parseInt(pageNumber) || 1);
    const [totalItems, setTotalItems] = useState(0);
    const pageSize = 10;

    const fetchList = useCallback(async (page) => {
        const fetchHistory = async () => {
            try {
                const url = apiUrl + `v1/user/vote-history?page=${page}&size=${pageSize}`;
                const data = await apiRequest(url, {}, 'GET', undefined, undefined, false, false);
                setTotalItems(data.data.metadata.totalCounts)
                setList(data.data.content)
            } catch (error) {
                console.error(error);
            }
        };
        fetchHistory();
    }, [apiRequest, apiUrl]);

    useEffect(() => {
        const pageFromUrl = parseInt(pageNumber) || 1;
        setCurrentPage(pageFromUrl);
        fetchList(pageFromUrl).then();
    }, [pageNumber, fetchList]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
        navigate(`/mypage/voted/${page}`);
    };

    return (
        <>
            <StickyDefault/>
            <section className="contentsWrap">

                <div className="myPage">
                    <div className="topTitle">마이페이지</div>
                    <TicketBox />
                    <div className="tab">
                        <ul><Link to="/mypage">투표권 구매</Link></ul>
                        <ul className="on">투표 내역</ul>
                        <ul><Link to="/mypage/purchase/1">구매 내역</Link></ul>
                    </div>
                    <div className="votedList">
                        <div className="tit">투표권 내역</div>
                        <div className="list">
                            {list.map((item,index) => (
                                <ul key={index}>
                                    <li className="date">{formatDateKr(item.voteDate)}</li>
                                    <li className="cate">{item.voteTitle}</li>
                                    <li className="info">
                                        <dl className="voted">{item.keyword}</dl>
                                        <dl className="ticket">사용 투표권 <span>{item.useTickets}</span></dl>
                                    </li>
                                </ul>
                            ))}
                            {totalItems === 0 &&(
                                <div className="listNoneDefault">투표내역이 없습니다.</div>
                            )}
                        </div>
                        <div className="paging">
                            <Pagination
                                onChange={handlePageChange}
                                align="center"
                                defaultCurrent={1}
                                total={totalItems}
                                pageSize={pageSize}
                                current={currentPage}
                                showSizeChanger={false}
                                responsive={true}
                            />
                        </div>
                    </div>
                </div>

            </section>
        </>
    );
}

export default VotedList;