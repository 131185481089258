import React, {useState} from 'react';
import ReactDOM from 'react-dom';
import { useApi } from '../../../js/module/api';


function CommentDelete({ isOpen, onClose, isReset, id, commentType, txt, type }) {
    const apiUrl = process.env.REACT_APP_API_URL; // 환경 변수에서 API URL 가져오기
    const { apiRequest } = useApi();
    const [password, setPassword] = useState('');
    const handlePasswordChange = (e) => setPassword(e.target.value);
    if (!isOpen) return null;

    const handleDeleteConfirm = async (e) => {
        if (!password) {
            alert('비밀번호를 입력해주세요.');
            return;
        }
        try {
            const url = `${apiUrl}v1/comments/${commentType}/delete`;
            const params = {
                "id": id,
                "type": type,
                "password": password
            };
            const response = await apiRequest(url, params, 'POST');
            if (isReset && response.message === 'success') isReset(); // 댓글 작성 후 콜백 호출
        } catch (error) {
            console.log(error)
        }
    };

    return ReactDOM.createPortal(
        <section className="layerDefault commentDelete">
            <div className="inbox">
                <div className="top">댓글 삭제</div>
                <div className="close" onClick={onClose}><img src="https://cdn.trend.rankify.best/dctrend/front/images/btn_close_gray.svg" alt=""/></div>
                <div className="cont">
                    <div className="commentPassword">
                        <ul className="tit">작성자만 글을 삭제할 수 있습니다.</ul>
                        <ul className="txt">글 작성시 입력한 비밀번호를 입력하여<br/>글을 삭제할 수 있습니다.</ul>
                        <ul className="inp">
                            <img src="https://cdn.trend.rankify.best/dctrend/front/images/ico_password.svg" alt=""/>
                            <input name="password" type="password" placeholder="비밀번호" onChange={handlePasswordChange} />
                        </ul>
                    </div>
                </div>
                <div className="bot">
                    <ul className="cancel">취소</ul>
                    <ul className="confirm" onClick={handleDeleteConfirm}>확인</ul>
                </div>
            </div>
        </section>,
        document.body
    );
}

export default CommentDelete;
