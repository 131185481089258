import React from 'react';

function ContPrivacyJoin() {

    return (
        <div className="box">
            <ul>
                <li>정보주체의 동의를 받아 처리하는 개인정보 항목</li>
                <li>
                    <dl>
                        <dt>
                            <div>디시트렌드는 다음의 개인정보 항목을 「개인정보 보호법」 제15조 제1항 제1호 및 제22조 제1항 제7호에 따라 정보주체의 동의를 받아 처리하고 있습니다.</div>
                            <div className="pdl20 pdr20">
                                <div className="subtit">[소셜로그인 및 관리]</div>
                                <div><strong>⦁ 수집・이용 항목:</strong> 이름(필수), 이메일 주소, 소셜로그인 시 닉네임, 소셜로그인 계정 식별값</div>
                                <div><strong>⦁ 목적:</strong> 본인 확인, 이용자 자격 유지 및 관리, 각종 고지 및 통지</div>
                                <div className="subtit">[투표권 구매 및 관리]</div>
                                <div><strong>⦁ 수집・이용 항목:</strong> 투표권 구매 내역, 결제 정보</div>
                                <div><strong>⦁ 목적:</strong> 투표권 구매 처리, 투표권 관리, 결제 확인</div>
                                <div className="subtit">[뉴스 댓글 작성 및 관리]</div>
                                <div><strong>⦁ 수집・이용 항목:</strong> 뉴스 댓글 작성 시 닉네임, 댓글 내용</div>
                                <div><strong>⦁ 목적:</strong> 뉴스 댓글 작성 및 관리, 사용자 간 소통 지원</div>
                                <div className="subtit">[생년월일 및 성별 정보] (선택적 수집 항목)</div>
                                <div><strong>⦁ 수집・이용 항목:</strong> 생년월일, 성별</div>
                                <div><strong>⦁ 목적:</strong> 트렌드 리포트의 투표 통계 분석에 활용</div>
                                <div className="subtit">[저작권 침해 관련 신고]</div>
                                <div><strong>⦁ 수집・이용 항목:</strong></div>
                                <div style={{paddingLeft:'16px'}}>⦁ 신고자 정보: 이름, 이메일, 전화번호</div>
                                <div style={{paddingLeft:'16px'}}>⦁ 권리자 정보:</div>
                                <div style={{paddingLeft:'32px'}}>⦁ 개인: 이름, 이메일, 전화번호, 신분증</div>
                                <div style={{paddingLeft:'32px'}}>⦁ 단체: 단체명, 이메일, 전화번호, 증빙서류</div>
                                <div><strong>⦁ 목적:</strong> 저작권 침해 신고 접수 및 처리, 관련 분쟁 해결</div>
                            </div>
                        </dt>
                    </dl>
                </li>
            </ul>
        </div>
    );
}

export default ContPrivacyJoin;