import React, { useEffect } from 'react';
import {useLocation} from "react-router-dom";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function Hecto() {
    const query = useQuery();
    const code = query.get('code');
    const backUrl = query.get('backUrl');
    useEffect(() => {
        if (window.opener) {
            window.opener.completeAction(code,backUrl);
            window.close();
        }
    }, [code,backUrl]);

    return (
        <>
        </>
    );
}

export default Hecto;
