/**
 * 문자열을 Base64로 인코딩합니다. '/' 문자를 포함하지 않도록 변환합니다.
 * @param {string} str - 인코딩할 문자열
 * @returns {string} - Base64로 인코딩된 문자열
 */
export const encodeToBase64 = (str) => {
    try {
        // Base64 인코딩
        let base64 = window.btoa(unescape(encodeURIComponent(str)));

        // '/' 문자를 포함할 경우 처리
        base64 = base64.replace(/\//g, '_@_'); // '/'를 '_@_'로 대체

        return base64;
    } catch (e) {
        console.error("Encoding Error:", e);
        return "";
    }
};

/**
 * Base64로 인코딩된 문자열을 디코딩합니다. '/' 문자가 대체된 경우 원래 문자열로 변환합니다.
 * @param {string} str - 디코딩할 Base64 문자열
 * @returns {string} - 디코딩된 원래 문자열
 */
export const decodeFromBase64 = (str) => {
    try {
        // '_@_' 문자를 복원
        const base64 = str.replace(/_@_/g, '/'); // '_@_'을 '/'로 대체

        return decodeURIComponent(escape(window.atob(base64)));
    } catch (e) {
        console.error("Decoding Error:", e);
        return "";
    }
};
