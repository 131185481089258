import React, { useState, useCallback, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import LoadingSpinner from '../../component/LoadingSpinner';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

let root;

export function useApi() {
    // fingerprint 초기값을 localStorage에서 가져옴
    const [fingerprint, setFingerprint] = useState(() => localStorage.getItem('fingerprint'));

    // 핑거프린트 생성 및 초기화
    useEffect(() => {
        if (!fingerprint) {
            const getFingerprint = async () => {
                const fp = await FingerprintJS.load();
                const result = await fp.get();
                const visitorId = result.visitorId;

                localStorage.setItem('fingerprint', visitorId);
                setFingerprint(visitorId);
            };

            getFingerprint().catch((error) => console.error('Failed to get fingerprint:', error));
        }
    }, [fingerprint]);

    // 로딩 스피너 보여주기
    const showLoadingSpinner = () => {
        let loaderContainer = document.getElementById('loader-container');
        if (!loaderContainer) {
            loaderContainer = document.createElement('div');
            loaderContainer.id = 'loader-container';
            document.body.appendChild(loaderContainer);
        }

        if (!root) {
            root = ReactDOM.createRoot(loaderContainer);
        }
        root.render(<LoadingSpinner />);
    };

    // 로딩 스피너 숨기기
    const hideLoadingSpinner = () => {
        const loaderContainer = document.getElementById('loader-container');
        if (loaderContainer && root) {
            root.unmount();
            document.body.removeChild(loaderContainer);
            root = null;
        }
    };

    // API 요청 함수
    const apiRequest = useCallback(
        async (url, params = {}, method = 'POST', externalHeaders = {}, headersMode = 'merge', showError = false, showLoading = true) => {
            if (showLoading) {
                showLoadingSpinner();
            }

            const defaultHeaders = {
                'Content-Type': method === 'GET' ? undefined : 'application/json',
                'User-Identifier': fingerprint,
                'X-API-KEY': 'AdswKr3yJ5lHkWllQUr6adnY9Q4aoqHh0KfwBeyb14',
                Authorization: 'bearer ' + sessionStorage.getItem('authToken'),
            };

            const finalHeaders =
                headersMode === 'merge' ? { ...defaultHeaders, ...externalHeaders } : { ...externalHeaders };

            let body;

            if (method === 'POST' || method === 'PUT' || method === 'DELETE') {
                if (params instanceof FormData) {
                    body = params;
                } else {
                    body = JSON.stringify(params);
                }
            }

            try {
                const response = await fetch(url, {
                    method: method,
                    headers: finalHeaders,
                    body: body,
                });
                const data = await response.json();
                if (response.status !== 200) {
                    let message;
                    if (data.validation && data.validation.length > 0) {
                        message = data.validation[0].message;
                    } else {
                        message = data.message;
                    }
                    alert(message);
                }
                return data;
            } catch (error) {
                if (showError) {
                    alert(error.message);
                }
                throw error;
            } finally {
                if (showLoading) {
                    hideLoadingSpinner();
                }
            }
        },
        [fingerprint]
    );
    return { apiRequest };
}
