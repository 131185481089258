// loginHandler.js
import { useSetRecoilState } from 'recoil';
import { authState } from '../../atoms/authAtom';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

const useLoginHandler = () => {
    const setAuthState = useSetRecoilState(authState);
    const navigate = useNavigate();
    const originalPath = sessionStorage.getItem('originalPath') || '/';

    const handleLogin = () => {
        const token = sessionStorage.getItem('authToken');
        const decoded = jwtDecode(token);
        const user = {
            "id": decoded.sub,
            "name": decoded.name,
            "exp": decoded.exp
        };
        setAuthState({
            isAuthenticated: true,
            token: token,
            user: user,
        });
        navigate(originalPath, { replace: true });
        sessionStorage.removeItem('originalPath');
    };

    return { handleLogin };
};

export default useLoginHandler;
