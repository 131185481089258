import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {Swiper, SwiperSlide} from "swiper/react";
import {useApi} from "../js/module/api";

function PopularVoteBox() {
    const apiUrl = process.env.REACT_APP_API_URL;
    const { apiRequest } = useApi();
    const [popularList, setPopularList] = useState([]);

    useEffect(() => {
        const fetchPopularList = async () => {

            try {
                const url = apiUrl + `v1/vote/active/list?categoryId=&page=1&size=3&sort=voteCount,desc`;
                const data = await apiRequest(url, {}, 'GET', undefined, undefined, false, false);
                setPopularList(data.data.content);

            } catch (error) {
                console.error('데이터를 불러오는데 실패했습니다.', error);
            } finally {

            }
        };
        fetchPopularList().then();
        // eslint-disable-next-line
    }, [apiRequest,apiUrl]);

    return (
        <div className="dcTrend">
            <div className="dcTitle">실시간 인기투표<Link to="/vote/list/all/1"><div className="arw s24"></div></Link></div>
            <Swiper
                direction='vertical'
                slidesPerView='auto'
                spaceBetween={0}
                touchReleaseOnEdges={true}
                breakpoints={{
                    480: {
                        direction: 'horizontal',
                        slidesPerView: 'auto',
                        spaceBetween: 16,
                    },
                    1200: {
                        direction: 'vertical',
                        slidesPerView: 'auto',
                        spaceBetween: 0,
                    }
                }}
            >
                {popularList.length === 0 && (
                    <>
                        <SwiperSlide>
                            <ul className="skeletonStyle" style={{height:'262px'}}></ul>
                        </SwiperSlide>
                        <SwiperSlide>
                            <ul className="skeletonStyle" style={{height:'262px'}}></ul>
                        </SwiperSlide>
                        <SwiperSlide>
                            <ul className="skeletonStyle" style={{height:'262px'}}></ul>
                        </SwiperSlide>
                    </>
                )}
                {popularList.map((item,index) => (
                    <SwiperSlide key={index}>
                        <ul className="hoverImgPt">
                            <li className="thumb">
                                <img
                                    src={item.thumbnail || "https://cdn.trend.rankify.best/dctrend/front/images/defaultImage_dt.svg"}
                                    alt={item.title || 'No Image'}
                                    onError={(e) => { e.target.src = "https://cdn.trend.rankify.best/dctrend/front/images/defaultImage_dt.svg"; }}
                                />
                            </li>
                            <li className="info">
                                <dl className="date">{item.startDate.substring(2).replace(/-/g, '.')} ~ {item.endDate.substring(2).replace(/-/g, '.')}</dl>
                                <dl className="title">{item.title}</dl>
                                <Link to={`/vote/detail/${item.id}`}><dl className="btn">투표</dl></Link>
                            </li>
                        </ul>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
}

export default PopularVoteBox;
