import React, { useEffect, useState } from 'react';
import { useApi } from '../../js/module/api';
import {Link, useNavigate} from "react-router-dom";
import decodeHtmlEntities from "../../js/module/HtmlDecode";
import { formatDay} from "../../js/module/formatDate";


function KeywordNewsBox({ keywordId , keyword }) {
    const apiUrl = process.env.REACT_APP_API_URL;
    const { apiRequest } = useApi();
    const navigate = useNavigate();
    const [newsData, setNewsData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchRanking = async () => {
            if (!keywordId) return;
            setLoading(true);
            try {
                const url = apiUrl + `v1/ranking/news/search/${keywordId}`;
                const data = await apiRequest(url, {}, 'GET', undefined, undefined, false, false);
                setNewsData(data.data);
            } catch (error) {
                console.error('데이터를 불러오는데 실패했습니다.', error);
            } finally {
                setLoading(false);
            }
        };
        fetchRanking().then();
        // eslint-disable-next-line
    }, [apiRequest, apiUrl, keywordId]);

    const moveNews = (categoryId,newsId) => {
        navigate(`/news/trend/detail/${categoryId}/${newsId}`);
    };

    return (
        <div className="news">
            <div className="stit">트렌드뉴스</div>
            <Link to={`/search/${keyword}`}><div className="more">바로가기<div className="arw s16"></div></div></Link>
            <div className="list">
                <ul>
                    <li>이미지</li>
                    <li>제목</li>
                    <li>날짜</li>
                </ul>
                {loading ? (
                    <div style={{ height: '1000px' }}>
                        <div className="listLoader" style={{marginTop:'100px'}}>
                            <div className="loader"></div>
                        </div>
                    </div>
                ) : newsData?.length === 0 ? (
                    <div className="listNoneDefault">
                        검색된 뉴스가 없습니다.
                    </div>
                    ) : (
                    newsData?.slice(0,5).map((item, index) => (
                        <ul key={index} onClick={() => moveNews(item.categoryId,item.newsId)}>
                            <li className="thumb skeletonStyle">
                                <img
                                    src={item.thumbnail || "https://cdn.trend.rankify.best/dctrend/front/images/defaultImage_dt.svg"}
                                    alt={item.title || 'No Image'}
                                    onError={(e) => { e.target.src = "https://cdn.trend.rankify.best/dctrend/front/images/defaultImage_dt.svg"; }}
                                />
                            </li>
                            <li className="title">{decodeHtmlEntities(item.title)}</li>
                            <li className="date">{formatDay(item.publishedAt)}</li>
                        </ul>
                    ))
                )}
            </div>
        </div>
    );
}

export default KeywordNewsBox;
