import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Radio, Space } from "antd";
import { useApi } from '../../../js/module/api';

function CommentNotify({ isOpen, onClose, id, commentType, type }) {
    const apiUrl = process.env.REACT_APP_API_URL;
    const { apiRequest } = useApi();

    const [list, setList] = useState(null);
    const [value, setValue] = useState(null);
    const [text, setText] = useState("");

    useEffect(() => {
        const reasonsLoad = async () => {
            try {
                const data = await apiRequest(apiUrl + 'v1/common/report-reasons', {}, 'GET');
                setList(data.data);
            } catch (error) {
                console.error(error);
            }
        };
        reasonsLoad().then();
    }, [apiRequest, apiUrl]);

    const onChange = (e) => {
        setValue(e.target.value);
    };

    const handleTextChange = (e) => {
        if (e.target.value.length <= 500) {
            setText(e.target.value);
        }
    };

    const handleNotifyConfirm = async (e) => {
        if (value === null) {
            alert('신고사유를 선택해주세요.');
            return;
        }
        if (value === 9999 && text.trim() === '') {
            alert('기타 신고사유를 작성해주세요.');
            return;
        }
        try {
            const url = `${apiUrl}v1/comments/report`;
            const params = {
                "contentType" : commentType.toUpperCase(),
                "commentType" : type,
                "commentId" : id,
                "reportReason" : value,
                "reportDetails" : text
            };
            const response = await apiRequest(url, params, 'POST');
            if(response.message === "success"){
                alert('신고접수가 완료되었습니다.');
                setText('');
                onClose();
            }
        } catch (error) {
            console.log(error)
        }
    };

    const handleClose = () => {
        setText('');
        onClose();
    };

    if (!isOpen) return null;

    return ReactDOM.createPortal(
        <section className="layerDefault notify">
            <div className="inbox">
                <div className="top">댓글 신고</div>
                <div className="close" onClick={handleClose}>
                    <img src="https://cdn.trend.rankify.best/dctrend/front/images/btn_close_gray.svg" alt=""/>
                </div>
                <div className="cont">
                    <div className="commentNotify">
                        <ul className="tit">신고 사유를 선택해주세요.</ul>
                        <ul className="list">
                            <Radio.Group onChange={onChange} value={value}>
                                <Space direction="vertical">
                                    {list?.map((item, index) => (
                                        <Radio key={index} value={item.id}>{item.reason}</Radio>
                                    ))}
                                </Space>
                            </Radio.Group>
                            <textarea
                                placeholder="신고 사유를 작성해주세요"
                                disabled={value !== 9999}
                                value={text}
                                onChange={handleTextChange}
                            ></textarea>
                            <div className="limit">{text.length}/500</div>
                        </ul>
                    </div>
                </div>
                <div className="bot">
                    <ul className="cancel" onClick={handleClose}>취소</ul>
                    <ul className="confirm" onClick={handleNotifyConfirm}>신고하기</ul>
                </div>
            </div>
        </section>,
        document.body
    );
}

export default CommentNotify;
