import React, { useEffect, useState } from 'react';
import { useApi } from '../../js/module/api';
import { formatNumberWithCommas } from '../../js/module/numberFormatter';
import {encodeToBase64} from "../../js/module/base64Utils";
import {useAlertNavigation} from "../../js/module/checkNavigation";

function VoteRankBox({ voteId, rank, isIng }) {
    const apiUrl = process.env.REACT_APP_API_URL;
    const { apiRequest } = useApi();
    const handleNavigationWithAlert = useAlertNavigation();
    const [rankData, setRankData] = useState([]);
    const [cate, setCate] = useState("");
    const [displayValue, setDisplayValue] = useState("");

    useEffect(() => {
        const fetchRanking = async () => {
            if (!voteId) return;
            let url = "";
            try {
                url = apiUrl + `v1/vote/${voteId}/${isIng === "ing" ? "keywords" : "completed/keywords"}`;
                const data = await apiRequest(url, {}, 'GET', undefined, undefined, false, false);
                console.log(data);
                setRankData(data.data);
                setCate(data.data[0].categoryPath);
            } catch (error) {
                console.error('데이터를 불러오는데 실패했습니다.', error);
            } finally {
            }
        };
        fetchRanking().then();
        // eslint-disable-next-line
    }, [apiRequest, apiUrl, voteId]);

    useEffect(() => {
        if (cate) {
            const cateArray = cate.split(' > '); // 문자열을 ' > '로 분리하여 배열로 변환
            const lastValue = cateArray[cateArray.length - 1];
            setDisplayValue(`${cateArray[cateArray.length - 2] || ''}・${lastValue}`);
        }
    }, [cate]);

    const top10Items = rankData.slice(0, 10);
    const matchingItems = rankData.filter(item => String(item.rank) === String(rank));
    const matchingIds = rankData.filter(item => item.rank === rank).map(item => item.keywordId);

    const MoveVoteDo = (voteId,keywordId) => {
        const infoData = encodeToBase64(`${voteId}@${keywordId}`)
        handleNavigationWithAlert(`/vote/do/${infoData}`);
    };

    return (
        <>
            {!displayValue ? (
                <div className="dbox rankVote">
                    <div className="listLoader" style={{paddingTop:'200px'}}>
                        <div className="loader"></div>
                    </div>
                </div>
            ) : (
                <div className="dbox rankVote">
                    <>
                        <div className="stit">투표 랭킹</div>
                        <div className="base">
                            <img src="https://cdn.trend.rankify.best/dctrend/front/images/ico_info.svg" alt="" /> 득표수 기준
                        </div>
                        <div className="list">
                            {top10Items.map((item, index) => (
                                <ul key={index}>
                                    <li className="rank">
                                        <dl className="num">{item.rank}</dl>
                                    </li>
                                    <li className="info">
                                        <dl className="thumb">
                                            <div className="img">
                                                <img
                                                    src={item.imageUrl || 'https://cdn.trend.rankify.best/dctrend/front/images/defaultImage_dt.svg'}
                                                    alt={item.keyword || 'No Image'}
                                                    onError={(e) => {
                                                        e.target.src = 'https://cdn.trend.rankify.best/dctrend/front/images/defaultImage_dt.svg';
                                                    }}
                                                />
                                            </div>
                                        </dl>
                                        <dl className="txt">
                                            <dt className="cate">{displayValue}</dt>
                                            <dt className="name">{item.keyword}</dt>
                                        </dl>
                                    </li>
                                    <li className="score">{formatNumberWithCommas(item.totalVotes)} 표</li>
                                </ul>
                            ))}
                            {matchingItems.map((item, index) => (
                                <ul key={`rank-${item.todayRank}-${index}`}>
                                    <li className="rank">
                                        <dl className="num">{item.rank}</dl>
                                    </li>
                                    <li className="info">
                                        <dl className="thumb">
                                            <div className="img">
                                                <img
                                                    src={item.imageUrl || 'https://cdn.trend.rankify.best/dctrend/front/images/defaultImage_dt.svg'}
                                                    alt={item.keyword || 'No Image'}
                                                    onError={(e) => {
                                                        e.target.src = 'https://cdn.trend.rankify.best/dctrend/front/images/defaultImage_dt.svg';
                                                    }}
                                                />
                                            </div>
                                        </dl>
                                        <dl className="txt">
                                            <dt className="cate">{displayValue}</dt>
                                            <dt className="name">{item.keyword}</dt>
                                        </dl>
                                    </li>
                                    <li className="score">{formatNumberWithCommas(item.totalVotes)} 표</li>
                                </ul>
                            ))}
                        </div>
                        {isIng === "ing" ? (
                            <div className="btn" onClick={() => MoveVoteDo(voteId,matchingIds)}>투표 한 번 더하기</div>
                        ) : (
                            <div className="btn end">종료된 투표입니다.</div>
                        )}
                    </>
                </div>
            )}
        </>
    );
}

export default VoteRankBox;
