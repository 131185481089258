import React, { useEffect, useState } from 'react';
import StickyDefault from "../layout/stickyDefault";
import ChartRank from "../../component/chartRank";
import { useNavigate, useParams} from "react-router-dom";
import ChartMini from "../../component/chartmini";
import { useApi } from "../../js/module/api";
import { formatNumberWithCommas } from "../../js/module/numberFormatter";
import ChartKeyword from "../../component/chartKeyword";
import ChartDetail from "../../component/chartDetail";
import {decodeFromBase64, encodeToBase64} from "../../js/module/base64Utils";

const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}년 ${month}월 ${day}일`;
};

function RankChart() {
    const { infoData } = useParams();
    const apiUrl = process.env.REACT_APP_API_URL;
    const { apiRequest } = useApi();
    const navigate = useNavigate();
    const [rankData, setRankData] = useState([]);
    const [rankDate, setRankDate] = useState("");
    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [{
            data: [],
            borderWidth: 0,
            backgroundColor: '#2D65F2',
            barThickness: window.innerWidth < 480 ? 10 : 16,
            borderRadius: 50
        }]
    });
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [loading, setLoading] = useState(true);
    const [openDetails, setOpenDetails] = useState({});
    const [cachedData, setCachedData] = useState({}); // 캐시된 데이터 상태 추가
    const [title, setTitle] = useState("");
    const [categoryId, setCategoryId] = useState([]);

    useEffect(() => {
        if (infoData) {
            const detailInfo = decodeFromBase64(infoData);
            const [title, categoryIdString] = detailInfo.split("@");
            setTitle(title);
            // categoryId가 문자열로 들어오는 경우 배열로 변환
            const categoryArray = categoryIdString.split(",").map(Number); // "1,2,3" => [1, 2, 3]
            setCategoryId(categoryArray);
        }
    }, [infoData]);

    useEffect(() => {
        const fetchRankNews = async () => {
            if (!Array.isArray(categoryId) || categoryId.length === 0) return;
            setLoading(true);
            try {
                const url = `${apiUrl}v1/ranking/keyword`;
                const params = {
                    "categoryIds": categoryId,
                    "keywordCount": 50
                };
                const data = await apiRequest(url, params, 'POST', undefined, undefined, false, true);
                setRankData(data.data.keyword);
                setRankDate(data.data.update);
                const labels = data.data.keyword.slice(0, 10).map(item => item.keyword);
                const values = data.data.keyword.slice(0, 10).map(item => item.indexValue);

                setChartData(prev => ({
                    ...prev,
                    labels: labels,
                    datasets: [{
                        ...prev.datasets[0],
                        data: values
                    }]
                }));

                setIsDataLoaded(true);
            } catch (error) {
                console.error('데이터를 불러오는데 실패했습니다.', error);
            } finally {
                setLoading(false);
            }
        };
        fetchRankNews().then();
    }, [apiRequest, apiUrl, categoryId]);

    const toggleDetail = async (index) => {
        if (openDetails[index]) {
            setOpenDetails(prevState => ({
                ...prevState,
                [index]: false
            }));

            document.body.classList.remove("notScroll");
            return;
        }

        setOpenDetails(prevState => {
            const newState = {};
            Object.keys(prevState).forEach(key => {
                newState[key] = false;
            });
            newState[index] = true;
            return newState;
        });

        if (window.innerWidth <= 560) {
            document.body.classList.add("notScroll");
        }


        if (cachedData[index]) {
            return;
        }

        const fetchDetail = async () => {
            try {
                const url = `${apiUrl}v1/ranking/keyword/${index}/statistic`;
                const data = await apiRequest(url, {}, 'GET', undefined, undefined, false, false);
                return data.data || []; // data.data가 undefined인 경우 빈 배열 반환
            } catch (error) {
                console.error('데이터를 불러오는데 실패했습니다.', error);
                return []; // 에러 발생 시 빈 배열 반환
            }
        };

        const newData = await fetchDetail();
        if (newData) {
            // 데이터가 배열이 아닐 경우, 배열로 변환
            setCachedData(prev => ({
                ...prev,
                [index]: Array.isArray(newData) ? newData : [newData]
            }));
        }
    };


    const closeDetail = (index) => {
        setOpenDetails(prevState => ({
            ...prevState,
            [index]: false
        }));
        document.body.classList.remove("notScroll");
    };

    const relationMove = (keyword) => {
        const infoData = encodeToBase64(keyword)
        navigate(`/vote/relation/${infoData}`);
    };

    useEffect(() => {
        document.body.style.backgroundColor = "#FAFAFC";
        return () => {
            document.body.style.backgroundColor = "#ffffff";
        };
    }, []);

    return (
        <>
            <StickyDefault />
            <section className="contentsWrap">
                <div className="rankingMain">
                    <div className="mainTitle">
                        <ul className="tit">{title} 더보기</ul>
                        <ul className="update">{formatDate(rankDate)}</ul>
                        <ul className="info"><img src="https://cdn.trend.rankify.best/dctrend/front/images/ico_info.svg" alt=""/>랭킹은 인기지수를 반영하여 10분 간격으로 집계되며, 키워드의 온라인 관심도와 활동을 반영합니다.</ul>
                    </div>
                    <div className="chartWrap">
                        {isDataLoaded && <ChartRank dataPoints={chartData} />}
                    </div>
                    <div className="chartTableRt">
                        <div className="chartTable">
                            <ul className="tr head">
                                <li className="rank">순위</li>
                                <li className="name">이름</li>
                                <li className="cate">인기지수</li>
                                <li className="index">이슈지수</li>
                                <li className="chart">지수 차트 (최근 30일)</li>
                                <li className="btnBox">투표하기</li>
                            </ul>
                            {loading ? (
                                <div style={{ height: '1000px' }}>
                                    <div className="listLoader" style={{ marginTop: '100px' }}>
                                        <div className="loader"></div>
                                    </div>
                                </div>
                            ) : rankData?.length === 0 ? (
                                <div style={{ height: '1000px' }}>
                                    <div className="listLoader" style={{ marginTop: '100px' }}>
                                        <div className="loader"></div>
                                    </div>
                                </div>
                            ) : (
                                rankData.map((item, index) => (
                                    <React.Fragment key={index}>
                                        <ul className="tr">
                                            <li className="rank">
                                                <div className="num">{item.todayRank}</div>
                                                <div className="rt1"><span>위</span> {item.keyword}</div>
                                                <div
                                                    className={`rankMark ${
                                                        item.rankDiff === "NEW"
                                                            ? "new"
                                                            : !isNaN(item.rankDiff) && Number(item.rankDiff) > 0
                                                                ? "up"
                                                                : !isNaN(item.rankDiff) && Number(item.rankDiff) < 0
                                                                    ? "down"
                                                                    : "same"
                                                    }`}
                                                >
                                                    {!isNaN(item.rankDiff) && Number(item.rankDiff) >= 1000
                                                        ? "999+"
                                                        : item.rankDiff}
                                                </div>
                                            </li>
                                            <li className="name">{item.keyword}</li>
                                            <li className="cate"><div className="rt2">인기지수</div>{formatNumberWithCommas(item.popularityIndexValue)}</li>
                                            <li className="index"><div className="rt2">이슈지수</div>{formatNumberWithCommas(item.indexValue)}</li>
                                            <li className="chart">
                                                <div className="rt2">지수차트</div>
                                                <div className="miniChart"><ChartMini data={item.indexData}/></div>
                                                <div
                                                    className={`drop ${openDetails[item.id] ? 'open' : ''}`}
                                                    onClick={() => toggleDetail(item.id)}
                                                ></div>
                                            </li>
                                            <li className="btnBox"><div className="btn" onClick={() => relationMove(item.keyword)}>투표하기</div></li>
                                        </ul>
                                        {openDetails[item.id] && (
                                            <div className="detail">
                                                <div className="layerBox">
                                                    <div className="layerTitle"><span>{item.todayRank}위</span>{item.keyword}</div>
                                                    <div className="close" onClick={() => closeDetail(item.id)}><img src="https://cdn.trend.rankify.best/dctrend/front/images/btn_close_gray.svg" alt=""/></div>
                                                    <div className="scrollBox">
                                                        <div className="inBox">
                                                            <div className="left">
                                                                <div className="tit">키워드</div>
                                                                {/* ChartKeyword에 기존 데이터와 새로운 데이터 전달 */}
                                                                <div className="openChart">
                                                                    <ChartKeyword dataPoints={item.indexData}/>
                                                                </div>
                                                            </div>
                                                            <ChartDetail dataIndex={cachedData[item.id] || []} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </React.Fragment>
                                ))
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default RankChart;
