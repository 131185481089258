import StickyDefault from "../layout/stickyDefault";
import React, {useCallback, useEffect, useState} from "react";
import 'swiper/swiper-bundle.css';
import {Link, useNavigate, useParams} from "react-router-dom";
import {useApi} from "../../js/module/api";
import {Pagination} from "antd";
import { formatNumberWithCommas } from '../../js/module/numberFormatter';

const titleMap = {
    all: { title: '전체', code: '' },
    celebrity: { title: '연예인', code: '705' },
    athlete: { title: '스포츠인', code: '706' },
    game: { title: '게임', code: '984' }
};

function Awards() {
    const apiUrl = process.env.REACT_APP_API_URL;
    const { apiRequest } = useApi();
    const navigate = useNavigate();
    const { category,pageNumber } = useParams();
    const [list, setList] = useState([]);
    const [currentPage, setCurrentPage] = useState(parseInt(pageNumber) || 1);
    const [totalItems, setTotalItems] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const pageSize = 12;

    const fetchList = useCallback(async (page) => {
        const fetchHistory = async () => {
            setIsLoading(true)
            try {
                const url = apiUrl + `v1/vote/completed/list?categoryId=${titleMap[category].code}&page=${page}&size=${pageSize}`;
                const data = await apiRequest(url, {}, 'GET', undefined, undefined, false, false);
                setTotalItems(data.data.metadata.totalCounts)
                setList(data.data.content)
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false)
            }
        };
        fetchHistory();
    }, [apiRequest, apiUrl, category]);

    useEffect(() => {
        const pageFromUrl = parseInt(pageNumber) || 1;
        setCurrentPage(pageFromUrl);
        fetchList(pageFromUrl).then();
    }, [pageNumber, fetchList]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
        navigate(`/vote/awards/${category}/${page}`);
    }

    const moveDefault = (url) => {
        navigate(url);
    };

    return (
        <>
            <StickyDefault/>
            <div className="topDep3">
                <div className="inBox">
                    <ul onClick={()=>{moveDefault("/vote/awards/all/1")}} className={category === "all" ? "on" : ""}>전체</ul>
                    <ul onClick={()=>{moveDefault("/vote/awards/celebrity/1")}} className={category === "celebrity" ? "on" : ""}>연예인</ul>
                    <ul onClick={()=>{moveDefault("/vote/awards/athlete/1")}} className={category === "athlete" ? "on" : ""}>스포츠인</ul>
                    <ul onClick={()=>{moveDefault("/vote/awards/game/1")}} className={category === "game" ? "on" : ""}>게임</ul>
                </div>
            </div>
            <section className="contentsWrap">
                <div className="gatherVote">
                    <div className="gatherList np">
                        <div className="endedList">
                            {list.map((item,index) => (
                                <ul key={index}>
                                    <Link to={`/vote/awardsDetail/${item.id}`}>
                                        <div className="top">
                                            <li className="thumb">
                                                <img
                                                    src={item.thumbnail || "https://cdn.trend.rankify.best/dctrend/front/images/defaultImage_dt.svg"}
                                                    alt={item.title || 'No Image'}
                                                    onError={(e) => { e.target.src = "https://cdn.trend.rankify.best/dctrend/front/images/defaultImage_dt.svg"; }}
                                                />
                                            </li>
                                            <li className="info">
                                                <dl>{item.startDate.substring(2).replace(/-/g, '.')} ~ {item.endDate.substring(2).replace(/-/g, '.')}</dl>
                                                <dl>{item.title}</dl>
                                            </li>
                                        </div>
                                    </Link>
                                    <div className="rank">
                                        {item.keywords.map((subItem,subIndex) => (
                                            <li key={subIndex}>
                                                <dl className="num">{subItem.rank}</dl>
                                                <dl className="thumb">
                                                    <img
                                                        src={subItem.imageUrl || "https://cdn.trend.rankify.best/dctrend/front/images/defaultImage_dt.svg"}
                                                        alt={subItem.title || 'No Image'}
                                                        onError={(e) => { e.target.src = "https://cdn.trend.rankify.best/dctrend/front/images/defaultImage_dt.svg"; }}
                                                    />
                                                </dl>
                                                <dl className="name">{subItem.keyword}</dl>
                                                <dl className="score">{formatNumberWithCommas(subItem.totalVotes)} 표</dl>
                                            </li>
                                        ))}
                                    </div>
                                </ul>
                            ))}
                            {totalItems === 0 && !isLoading && (
                                <div className="noResulWrap" style={{paddingTop:'130px'}}>
                                    <div className="ul"><img src="https://cdn.trend.rankify.best/dctrend/front/images/ico_nocomment.svg" alt="" /></div>
                                    <div className="ul">아직 마감된 투표가 없습니다.</div>
                                    <div className="ul">진행중인 투표가 종료되면 확인하실 수 있습니다.</div>
                                </div>
                            )}
                        </div>
                        {!isLoading  && (
                            <div className="paging">
                                <Pagination
                                    onChange={handlePageChange}
                                    align="center"
                                    defaultCurrent={1}
                                    total={totalItems}
                                    pageSize={pageSize}
                                    current={currentPage}
                                    showSizeChanger={false}
                                    responsive={true}
                                />
                            </div>
                        )}
                    </div>
                </div>

            </section>
        </>
    );
}

export default Awards;