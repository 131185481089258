import React, {useState} from 'react';
import { useApi } from '../../../js/module/api';

function VoteBox({ initialGood, initialBad, id, commentType, type, state }) {
    const apiUrl = process.env.REACT_APP_API_URL; // 환경 변수에서 API URL 가져오기
    const { apiRequest } = useApi();
    const [good, setGood] = useState(initialGood);
    const [bad, setBad] = useState(initialBad);
    const handleClick = async (feel) => {
        if(state === "INACTIVE"){
            return;
        }
        try {
            const url = apiUrl+`v1/comments/${commentType}/${type}/${id}/${feel}`;
            const response = await apiRequest(url, {}, 'GET', undefined, undefined, false);
            if(response.message === "success"){
                if(feel === 'recommend'){
                    setGood(response.data)
                }else{
                    setBad(response.data)
                }
            }
        } catch (error) {
            console.log(error)
        }
    };

    return (
        <>
            <li className="vote">
                <dl className={`good ${state === 'INACTIVE' ? 'block' : ''}`} onClick={() => handleClick('recommend')}><img src="https://cdn.trend.rankify.best/dctrend/front/images/ico_cmt_good.svg" alt=""/>{good}</dl>
                <dl className={`bad ${state === 'INACTIVE' ? 'block' : ''}`} onClick={() => handleClick('dislike')}><img src="https://cdn.trend.rankify.best/dctrend/front/images/ico_cmt_bad.svg" alt=""/>{bad}</dl>
            </li>
        </>

    );
}

export default VoteBox;
