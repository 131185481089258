import React, { useEffect, useState } from 'react';
import { useApi } from '../../js/module/api';
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation} from "swiper/modules";

function KeywordYoutubeBox({ keyword }) {
    const apiUrl = process.env.REACT_APP_API_URL;
    const { apiRequest } = useApi();
    const [listData, setListData] = useState([]);
    const [isShowYoutube, setIsShowYoutube] = useState(false);
    const [ytCode, setYtCode] = useState(false);
    const [ytTitle, setYtTitle] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchRanking = async () => {
            if (!keyword) return;
            setLoading(true);
            try {
                const url = apiUrl + `v1/search/youtube?query=${keyword}&limit=10`;
                const data = await apiRequest(url, {}, 'GET', undefined, undefined, false, false);
                setListData(data.data);
            } catch (error) {
                console.error('데이터를 불러오는데 실패했습니다.', error);
            } finally {
                setLoading(false);
            }
        };
        fetchRanking().then();
        // eslint-disable-next-line
    }, [apiRequest, apiUrl,keyword]);

    const showYoutube = (code,title) => {
        setYtCode(code);
        setYtTitle(title);
        setIsShowYoutube(true);
    }

    const closeYoutube = () => {
        setIsShowYoutube(false);
    }

    return (
        <>
            <div className="youtube">
                <div className="stit">유튜브</div>
                <a href={`https://www.youtube.com/results?search_query=${keyword}`} target="_blank" rel="noopener noreferrer"><div className="more">바로가기<div className="arw s16"></div></div></a>
                <div className="list">
                    <Swiper
                        modules={[Navigation]} // Swiper 모듈 등록
                        slidesPerView={'auto'}
                        spaceBetween={8}
                        breakpoints={{
                            480: {
                                slidesPerView: 'auto',
                                spaceBetween: 8,
                            },
                            1200: {
                                slidesPerView: 'auto',
                                spaceBetween: 20,
                            }
                        }}
                        navigation={{
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev',
                        }}
                        className="youtubeRoll"
                    >
                        {loading ? (
                            <>
                                <SwiperSlide><ul></ul></SwiperSlide>
                                <SwiperSlide><ul></ul></SwiperSlide>
                                <SwiperSlide><ul></ul></SwiperSlide>
                                <SwiperSlide><ul></ul></SwiperSlide>
                                <SwiperSlide><ul></ul></SwiperSlide>
                                <SwiperSlide><ul></ul></SwiperSlide>
                            </>
                        ) : listData?.length === 0 ? (
                            <div className="listNoneDefault">
                                검색된 컨텐츠가 없습니다.
                            </div>
                        ) : (
                            listData?.map((item, index) => (
                                <SwiperSlide key={index}><ul onClick={() => showYoutube(item.id.videoId,item.snippet.title)}><img src={`https://i.ytimg.com/vi/${item.id.videoId}/hq720.jpg`} alt=""/></ul></SwiperSlide>
                            ))
                        )}
                    </Swiper>
                    <div className="swiper-button-next"></div>
                    <div className="swiper-button-prev"></div>
                </div>
            </div>
            {isShowYoutube && (
                <section className="ytViewLayer">
                    <div className="top">
                        <ul className="title">{ytTitle}</ul>
                        <ul className="close" onClick={closeYoutube}>닫기</ul>
                    </div>
                    <div className="inBox">
                        <iframe
                            src={`https://www.youtube.com/embed/${ytCode}`}
                            width="100%"
                            height="100%"
                            title={ytTitle}
                            style={{ border: 'none' }}
                        ></iframe>
                    </div>
                </section>
            )}
        </>
    );
}

export default KeywordYoutubeBox;
