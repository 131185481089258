import React from 'react';
import './App.css';
import  './assets/css/pluginCustom.css';
import Layout from './page/layout/layout';
import { Route, Routes } from 'react-router-dom';
import Main from './page/news/main';
import News from './page/news/news';
import { RecoilRoot } from 'recoil';
import PrivateRoute from './component/PrivateRoute';
import AuthProvider from './atoms/AuthProvider';
import LoginPage from './page/auth/login';
import NotFound from "./page/notFound";
import RankingNews from "./page/news/rankingNews";
import NewsDetail from "./page/news/newsDetail";
import TrendNews from "./page/news/trendNews";
import RankAll from "./page/ranking/rankAll";
import Contents from "./page/news/contents";
import ContentsDetail from "./page/news/contentsDetail";
import Popular from "./page/ranking/popular";
import Gather from "./page/vote/gather";
import Awards from "./page/vote/awards";
import RankChart from "./page/ranking/rankChart";
import Relation from "./page/vote/relation";
import VoteList from "./page/vote/list";
import DoVote from "./page/vote/doVote";
import Another from "./page/vote/another";
import Report from "./page/vote/report";
import MyPageMain from "./page/mypage/main";
import OrderTicket from "./page/mypage/order";
import VotedList from "./page/mypage/voted";
import Purchase from "./page/mypage/purchase";
import Withdraw from "./page/mypage/withdraw";
import Search from "./page/search/main";
import {ContextProvider} from "./component/context";
import AwardsDetail from "./page/vote/awardsDetail";
import FeedBack from "./page/feedback/main";
import useScrollRestoration from "./component/useScrollRestoration"
import TermUse from "./page/policy/termUse";
import TermVote from "./page/policy/termVote";
import Privacy from "./page/policy/privacy";
import JoinAgree from "./page/auth/joinAgree";
import LoginCallback from "./page/auth/callback";
import Hecto from "./page/mypage/hecto";
import KeywordReport from "./page/ranking/keywordReport";
import RankPopular from "./page/ranking/rankPopular";

function App() {
    useScrollRestoration();
    return (
        <RecoilRoot>
            <AuthProvider>
                <ContextProvider>
                <div className="App">
                    <Routes>
                        <Route path="/" element={<Layout />}>
                            <Route index element={<Main />} />
                            <Route path="news">
                                <Route path="trend">
                                    <Route index element={<TrendNews />} />
                                    <Route path=":categoryId/:pageNumber" element={<News />} />
                                    <Route path="detail/:categoryId/:newsId" element={<NewsDetail />} />
                                </Route>
                                <Route path=":categoryId/:pageNumber" element={<Contents />} />
                                <Route path=":categoryId/detail/:contentsId" element={<ContentsDetail />} />
                            </Route>
                            <Route path="rankNews" element={<RankingNews />} />
                            <Route path="ranking">
                                <Route path="chart/:infoData" element={<RankChart />} />
                                <Route path="report/:infoData" element={<KeywordReport />} />
                                <Route path="popular" element={<RankPopular />} />
                            </Route>
                            <Route path="vote">
                                <Route path="list/:category/:pageNumber" element={<Gather />} />
                                <Route path="awards/:category/:pageNumber" element={<Awards />} />
                                <Route path="awardsDetail/:id" element={<AwardsDetail />} />
                                <Route path="detail/:voteId" element={<VoteList />} />
                                <Route path="relation/:infoData" element={<Relation />} />
                                <Route path="do/:infoData" element={<PrivateRoute><DoVote /></PrivateRoute>} />
                                <Route path="another/:voteId" element={<Another />} />
                                <Route path="report/:infoData" element={<Report />} />
                            </Route>
                            <Route path="mypage">
                                <Route index element={<PrivateRoute><MyPageMain /></PrivateRoute>} />
                                <Route path="order/:goodsId" element={<PrivateRoute><OrderTicket /></PrivateRoute>} />
                                <Route path="voted/:pageNumber" element={<PrivateRoute><VotedList /></PrivateRoute>} />
                                <Route path="purchase/:pageNumber" element={<PrivateRoute><Purchase /></PrivateRoute>} />
                                <Route path="withdraw" element={<Withdraw />} />
                            </Route>
                            <Route path="feedback">
                                <Route index element={<FeedBack />} />
                            </Route>
                            <Route path="search">
                                <Route path=":query"  element={<Search />} />
                            </Route>
                            <Route path="policy">
                                <Route index element={<TermUse />} />
                                <Route path="termsVote" element={<TermVote />} />
                                <Route path="privacy" element={<Privacy />} />
                            </Route>
                        </Route>
                        <Route path="/login" element={<LoginPage />} />
                        <Route path="/auth/callback/:type" element={<LoginCallback />} />
                        <Route path="/login/agree" element={<JoinAgree />} />
                        <Route path="*" element={<NotFound />} />
                        <Route path="/order/complete" element={<Hecto />} />
                    </Routes>
                </div>
                </ContextProvider>
            </AuthProvider>
        </RecoilRoot>
    );
}

export default App;
