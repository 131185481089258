import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import 'chartjs-adapter-date-fns';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { format, parse } from 'date-fns';

// Chart.js의 플러그인 등록
Chart.register(...registerables, ChartDataLabels);

const ChartReport = ({ dataPoints }) => {
    const [chartOptions, setChartOptions] = useState(getChartOptions());
    const [gradient, setGradient] = useState(null);

    useEffect(() => {
        // 차트가 렌더링된 후 gradient 생성
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const grad = ctx.createLinearGradient(0, 0, 0, 360);
        grad.addColorStop(0, 'rgba(147, 197, 253, 1)');
        grad.addColorStop(1, 'rgba(255, 255, 255, 0)');
        setGradient(grad);

        const updateChartOptions = () => {
            setChartOptions(getChartOptions());
        };

        window.addEventListener("resize", updateChartOptions);

        return () => {
            window.removeEventListener("resize", updateChartOptions);
        };
    }, []);

    // 차트 옵션 설정 함수
    function getChartOptions() {
        const isSmallScreen = window.innerWidth < 420;
        return {
            plugins: {
                legend: {
                    display: false
                },
                tooltip: {
                    enabled: false, // 기본 툴팁 비활성화
                    external: function(context) {
                        const { chart, tooltip } = context;
                        let tooltipEl = document.getElementById('chartjs-tooltip');

                        if (!tooltipEl) {
                            tooltipEl = document.createElement('div');
                            tooltipEl.id = 'chartjs-tooltip';
                            tooltipEl.style.position = 'absolute';
                            tooltipEl.style.backgroundColor = '#292E41';
                            tooltipEl.style.color = '#D7DCE5';
                            tooltipEl.style.borderRadius = '12px';
                            tooltipEl.style.padding = '0';
                            tooltipEl.style.pointerEvents = 'none';
                            tooltipEl.style.fontFamily = 'Pretendard';
                            document.body.appendChild(tooltipEl);
                        }

                        if (tooltip.opacity === 0) {
                            tooltipEl.style.opacity = 0;
                            return;
                        }

                        let formattedDate = '';
                        try {
                            const date = new Date(tooltip.title[0]);
                            if (!isNaN(date.getTime())) {
                                formattedDate = format(date, 'yyyy.MM.dd');
                            } else {
                                const parsedDate = parse(tooltip.title[0], 'MMM d, yyyy, h:mm:ss a', new Date());
                                if (!isNaN(parsedDate.getTime())) {
                                    formattedDate = format(parsedDate, 'yyyy.MM.dd');
                                } else {
                                    formattedDate = 'Invalid Date';
                                }
                            }
                        } catch {
                            // eslint-disable-next-line
                            formattedDate = 'Error Formatting Date';
                        }

                        let innerHtml = `<div class="chartTooltip1">
                                            <ul>${tooltip.body[0].lines[0]}</ul>
                                            <ul>인기지수</ul>
                                            <div class="botArw"></div>
                                         </div>`;
                        tooltipEl.innerHTML = innerHtml;

                        const position = chart.canvas.getBoundingClientRect();
                        const tooltipWidth = tooltipEl.offsetWidth;
                        const tooltipHeight = tooltipEl.offsetHeight;

                        tooltipEl.style.opacity = 1;
                        tooltipEl.style.left = position.left + window.pageXOffset + tooltip.caretX - tooltipWidth / 2 + 'px';
                        tooltipEl.style.top = position.top + window.pageYOffset + tooltip.caretY - tooltipHeight - 10 + 'px';
                    }
                },
                datalabels: {
                    display: false,
                }
            },
            scales: {
                x: {
                    type: 'time',
                    time: {
                        unit: 'day',
                        displayFormats: {
                            day: 'MM.dd'
                        }
                    },
                    ticks: {
                        autoSkip: true,
                        maxTicksLimit: isSmallScreen ? 4 : 11,
                        color: '#8B8B9A',
                        font: {
                            size: 11,
                            family: 'Pretendard',
                            weight: '400',
                        }
                    },
                    grid: {
                        display: false,
                    }
                },
                y: {
                    beginAtZero: true,
                    ticks: {
                        display: !isSmallScreen,
                        autoSkip: true,
                        maxTicksLimit: 10,
                        color: '#B4C0D3',
                        font: {
                            size: 12,
                            family: 'Pretendard',
                            weight: '500',
                        },
                    },
                    grid: {
                        color: '#EAEDF4'
                    }
                }
            },
            responsive: true,
            maintainAspectRatio: false
        };
    }

    // date와 popularIndex를 사용하여 차트 데이터를 생성
    const chartData = {
        labels: dataPoints.map(point => point.date),
        datasets: [{
            data: dataPoints.map(point => ({
                x: point.date,
                y: point.popularIndex
            })),
            borderColor: '#94B5FC',
            borderWidth: 2,
            fill: true,
            tension: 0.1,
            pointBackgroundColor: '#315AE9',
            pointBorderWidth: 0,
            backgroundColor: gradient // Use gradient here
        }]
    };

    return (
        <div style={{ width: '100%', height: '100%' }}>
            {dataPoints.length > 0 ? (
                <Line
                    data={chartData}
                    options={chartOptions}
                />
            ) : (
                <div>
                    <div className="listLoader" style={{paddingTop:'100px'}}>
                        <div className="loader"></div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ChartReport;
