import StickyDefault from "../layout/stickyDefault";
import React, { useEffect, useState} from "react";
import { useNavigate, useParams} from "react-router-dom";

import 'swiper/swiper-bundle.css';
import {useApi} from "../../js/module/api";
import {formatNumberWithCommas} from "../../js/module/numberFormatter";
import {encodeToBase64} from "../../js/module/base64Utils";
import ChartRank from "../../component/chartRank";
import CommentWrap from "../news/comment/comment";



function AwardsDetail() {
    const apiUrl = process.env.REACT_APP_API_URL;
    const { apiRequest } = useApi();
    const navigate = useNavigate();
    const { id } = useParams();
    const [itemList, setItemList] = useState([]);
    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [{
            data: [],
            borderWidth: 0,
            backgroundColor: '#2D65F2',
            barThickness: window.innerWidth < 480 ? 10 : 16,
            borderRadius: 50
        }]
    });

    useEffect(() => {
        const fetchList = async () => {
            try {
                const url = apiUrl + `v1/vote/completed/${id}?size=100`;
                const data = await apiRequest(url, {}, 'GET', undefined, undefined, false, false);
                console.log(data)
                setItemList(data.data)
                const labels = data.data.keywords.slice(0, 7).map(item => item.keyword);
                const values = data.data.keywords.slice(0, 7).map(item => item.totalVotes);

                setChartData(prev => ({
                    ...prev,
                    labels: labels,
                    datasets: [{
                        ...prev.datasets[0],
                        data: values
                    }]
                }));
            } catch (error) {
                console.error('데이터를 불러오는데 실패했습니다.', error);
            }
        };
        fetchList().then();
        // eslint-disable-next-line
    }, [apiRequest, apiUrl, id]);


    useEffect(() => {
        document.body.style.backgroundColor = "#FAFAFC";
        return () => {
            document.body.style.backgroundColor = "#ffffff";
        };
    }, []);

    const relationMove = (keyword) => {
        const infoData = encodeToBase64(keyword)
        navigate(`/vote/relation/${infoData}`);
    };

    const moveReport = (voteId,keywordId,isIng) => {
        const infoData = encodeToBase64(`${voteId}@${keywordId}@${isIng}`)
        navigate(`/vote/report/${infoData}`);
    };

    return (
        <>
            <StickyDefault/>
            <section className="contentsWrap">
                <div className="awardsDetail v2">
                    <div className="topV2">
                        <div className="info">
                            <div className="inBox">
                                <div className="date">{itemList.startDate?.substring(2).replace(/-/g, '.')} ~ {itemList.endDate?.substring(2).replace(/-/g, '.')}</div>
                                <div className="title">{itemList?.title}</div>
                                <div className="btn">마감된 투표</div>
                            </div>
                        </div>
                        <div className="cover"></div>
                        <img
                            src={itemList.thumbnail || "https://cdn.trend.rankify.best/dctrend/front/images/defaultImage_dt.svg"}
                            alt={itemList.keyword || 'No Image'}
                            onError={(e) => { e.target.src = "https://cdn.trend.rankify.best/dctrend/front/images/defaultImage_dt.svg"; }}
                        />
                    </div>
                    <div className="chartWrap">
                        {chartData && (
                            <ChartRank dataPoints={chartData} />
                        )}
                    </div>
                    <div className="awardsDetailList">
                        {itemList?.keywords?.map((item,index) => (
                            <ul key={index}>
                                <li className="rank">{item.rank}</li>
                                <li className="thumb">
                                    <div className="img">
                                        <img
                                            src={item.imageUrl || "https://cdn.trend.rankify.best/dctrend/front/images/defaultImage_dt.svg"}
                                            alt={item.keyword || 'No Image'}
                                            onError={(e) => { e.target.src = "https://cdn.trend.rankify.best/dctrend/front/images/defaultImage_dt.svg"; }}
                                        />
                                    </div>
                                </li>
                                <div className="rt">
                                    <li className="name"><span onClick={()=>{moveReport(item.voteId,item.keywordId,"end")}}><div>{item.keyword}</div><div className="arw s16"></div></span></li>
                                    <li className="index">{formatNumberWithCommas(item.totalVotes)} 표</li>
                                </div>
                                <li className="btn" onClick={() => relationMove(item.keyword)}>연관투표</li>
                            </ul>
                        ))}
                    </div>
                </div>
                <div id="commentPoint" className="voteCommentWrap">
                    <CommentWrap id={id} type="vote"/>
                </div>
            </section>
        </>
    );
}

export default AwardsDetail;