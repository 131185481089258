import StickyDefault from "../layout/stickyDefault";
import React  from 'react';
import {Link, useLocation, useNavigate} from "react-router-dom";
import goods from './goods.js';
import TicketBox from "./ticketBox";

function MyPageMain() {

    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const backUrl = queryParams.get('backUrl');

    const handleClick = async (id) => {
        if(backUrl === null || backUrl === undefined){
            navigate('/mypage/order/'+id);
        }else{
            navigate('/mypage/order/'+id+'?backUrl='+backUrl);
        }
    };

    return (
        <>
            <StickyDefault/>
            <section className="contentsWrap">

                <div className="myPage">
                    <div className="topTitle">마이페이지</div>
                    <TicketBox />
                    <div className="tab">
                        <ul className="on">투표권 구매</ul>
                        <ul><Link to="/mypage/voted/1">투표 내역</Link></ul>
                        <ul><Link to="/mypage/purchase/1">구매 내역</Link></ul>
                    </div>
                    <div className="orderTicket">
                        <div className="tit">투표권 구매</div>
                        <div className="list">
                            {goods.map((item, index) => (
                                <ul key={index} onClick={() => handleClick(item.id)}>
                                    <li>{item.qty.toLocaleString()}개</li>
                                    <li>{item.price.toLocaleString()}원</li>
                                </ul>
                            ))}
                        </div>
                    </div>
                    <div className="useInfo">
                        <ul>
                            <li>투표권 이용안내</li>
                            <li>
                                <dl>투표권 구매에는 부가가치세가 부과되지 않습니다.</dl>
                                <dl>투표권 구매 또는 사용 전 이용약관 동의가 필요합니다.</dl>
                                <dl>결제 상세내역은 디시트렌드 > 내 투표권 페이지에서 확인 가능합니다.</dl>
                                <dl>투표권은 무료 투표권부터 사용되며, 이후 먼저 구매한 투표권 순서로 사용됩니다.</dl>
                                <dl>충전한 투표권을 사용하여 디시트렌드의 다양한 투표에 참여할 수 있습니다.</dl>
                                <dl>무료 투표권은 매월 1일 00시 00분에 5개씩 지급되며, 이월되지 않습니다.</dl>
                            </li>
                        </ul>
                        <ul>
                            <li>구매취소</li>
                            <li>
                                <dl>디시트렌드에서 구매한 투표권 패키지는 사용하지 않은 경우에 한해, 구매 후 7일 이내에 마이페이지에서 직접 구매 취소할 수 있습니다.</dl>
                                <dl>사용한 투표권은 구매 취소 및 환불이 불가합니다.</dl>
                                <dl>무료 투표권은 구매 취소 및 환불 대상이 아닙니다. 따라서 충전 내역과 취소 내역의 투표권 수량이 다를 수 있습니다.</dl>
                            </li>
                        </ul>
                        <ul className="agree">이 양식을 제출하면 <Link to="/policy/termsVote">디시트렌드 투표권 이용약관</Link> 에 동의하는 것입니다.</ul>
                    </div>
                    <Link to="/mypage/withdraw"><div className="withdraw">디시트렌드 탈퇴<div className="arw s16"></div></div></Link>
                </div>

            </section>
        </>
    );
}

export default MyPageMain;