import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useRecoilValue, useSetRecoilState} from "recoil";
import {authState} from "../../atoms/authAtom";
import {Link, useLocation, useNavigate} from "react-router-dom";

function Footer() {
    const auth = useRecoilValue(authState);
    const setAuthState = useSetRecoilState(authState);
    const navigate = useNavigate();
    const location = useLocation();

    const handleLogout = () => {
        sessionStorage.removeItem('authToken'); // 로컬 스토리지에서 토큰 삭제
        setAuthState({ isAuthenticated: false, token: null, user: null });
        navigate('/'); // 홈 페이지로 이동
    };

    const [isVisible, setIsVisible] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);
    const [showMoveTop, setShowMoveTop] = useState(false);
    const [moveTopBottom, setMoveTopBottom] = useState(80);
    const menuRef = useRef(null);

    const handleScroll = useCallback(() => {
        const currentScrollY = window.scrollY;

        if (currentScrollY > lastScrollY) {
            setIsVisible(false);
            setMoveTopBottom(20);
        } else {
            setIsVisible(true);
            setMoveTopBottom(80);
        }
        setLastScrollY(currentScrollY);

        if (currentScrollY > 150) {
            setShowMoveTop(true);
        } else {
            setShowMoveTop(false);
        }
    }, [lastScrollY]);

    const handleMoveComment = () => {
        const element = document.getElementById("commentPoint");
        if (element) {
            // 요소의 위치를 가져옵니다.
            const elementPosition = element.getBoundingClientRect().top + window.scrollY;
            // 스크롤할 위치를 계산 (위로 50px 조정)
            const scrollToPosition = elementPosition - 100;

            // 해당 위치로 부드럽게 스크롤
            window.scrollTo({
                top: scrollToPosition,
                behavior: 'smooth',
            });
        }
    };
    const handleMoveTopClick = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };
    const handleMoveBottomClick = () => {
        window.scrollTo({
            top: document.body.scrollHeight,
            behavior: 'smooth'
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll]);

    const isActive = (path) => {
        return location.pathname.startsWith(path);
    };

    const isMyPage = location.pathname.startsWith("/mypage");

    return (
        <>
            <div
                className="botMenu"
                ref={menuRef}
                style={{
                    bottom: isVisible ? '0' : '-66px', // 메뉴가 사라질 때 아래로 이동
                }}
            >
                <div className="inBox">
                    <ul className={isActive("/news") ? "on" : ""}>
                        <Link to="/news/trend">
                            <li></li>
                            <li>뉴스</li>
                        </Link>
                    </ul>
                    <ul className={isActive("/rankNews") ? "on" : ""}>
                        <Link to="/rankNews">
                            <li></li>
                            <li>랭킹뉴스</li>
                        </Link>
                    </ul>
                    <ul className={isActive("/ranking") ? "on" : ""}>
                        <Link to="/ranking/popular">
                            <li></li>
                            <li>랭킹</li>
                        </Link>
                    </ul>
                    <ul className={isActive("/vote") ? "on" : ""}>
                        <Link to="/vote/list/all/1">
                            <li></li>
                            <li>투표</li>
                        </Link>
                    </ul>
                </div>
            </div>
            <section
                id="moveBtn"
                className="moveTop"
                style={{
                    display: showMoveTop ? 'block' : 'none',
                    opacity: showMoveTop ? 1 : 0,
                    bottom: `${moveTopBottom}px`, // bottom 위치 변경
                }}
            >
                {(isActive("/news/trend/detail") || isActive("/vote/detail") || isActive("/vote/awardsDetail")) && (
                    <img style={{marginBottom:'8px'}} src="https://cdn.trend.rankify.best/dctrend/front/images/btn_float_cmt.png" alt="Move to Comment" onClick={handleMoveComment}/>
                )}
                <img src="https://cdn.trend.rankify.best/dctrend/front/images/btn_top2.svg" alt="Move to Top" onClick={handleMoveTopClick}/>
                {isActive("/news/trend/detail") && (
                    <img className="down" src="https://cdn.trend.rankify.best/dctrend/front/images/btn_top2.svg" alt="Move to Bottom"  onClick={handleMoveBottomClick}/>
                )}
            </section>

            {/* 경로에 따라 다른 footer 표시 */}
            {isMyPage ? (
                <section className="footer tdi">
                    <ul className="btnBox">
                        {auth.isAuthenticated ? (
                            <>
                                <li onClick={handleLogout}>로그아웃</li>
                            </>
                        ) : (
                            <Link to="/login"><li>로그인</li></Link>
                        )}
                        <li className="pc">PC버전</li>
                        <Link to="https://www.dcinside.com/" target="_blank" rel="noreferrer"><li>디시인사이드</li></Link>
                    </ul>
                    <ul className="items">
                        <Link to="/policy"><li>디시트렌드 이용약관</li></Link>
                        <Link to="/policy/privacy"><li><span>개인정보처리방침</span></li></Link>
                    </ul>
                    <ul className="tdi">
                        <li>(주) 티디아이 대표이사 : 신성균</li>
                        <li>사업자등록번호 : 617-81-95610<Link to="http://www.ftc.go.kr/bizCommPop.do?wrkr_no=6178195610" target="_blank" rel="noreferrer"><div className="btn">사업자정보확인</div></Link></li>
                        <li>통신판매업 신고번호 : 제 2012-부산해운-004 호</li>
                        <li>주소 : [부산] 부산광역시 해운대구 센텀중앙로 78, 1302호 (우동, 센텀그린타워) / [서울] 서울특별시 서초구 반포대로 20길 29(서초동, TDI 타워)</li>
                        <li>고객센터 : dctrend@rankify.best</li>
                    </ul>
                    <ul className="copyright">
                        Copyright © TDI. All rights reserved
                    </ul>
                </section>
            ) : (
                <section className="footer">
                    <ul className="btnBox">
                        {auth.isAuthenticated ? (
                            <>
                                <li onClick={handleLogout}>로그아웃</li>
                            </>
                        ) : (
                            <Link to="/login"><li>로그인</li></Link>
                        )}
                        {/*
                        <li className="pc">PC버전</li>
                        */}
                        <Link to="https://www.dcinside.com/" target="_blank" rel="noreferrer"><li style={{marginLeft:'8px'}}>디시인사이드</li></Link>
                    </ul>
                    <ul className="items">
                        <Link to="/policy"><li>디시트렌드 이용약관</li></Link>
                        <Link to="/policy/privacy"><li><span>개인정보처리방침</span></li></Link>
                        <Link to="/feedback"><li>피드백센터</li></Link>
                        <li>등록번호 : 자00070</li>
                        <li>기사배열 책임자 : 권재웅</li>
                        <li>청소년보호 책임자 : 권재웅</li>
                    </ul>
                    <ul className="copyright">
                        Copyright © TDI. All rights reserved.
                    </ul>
                    <ul className="info">
                        디시트렌드에서 사용되는 모든 인물 이미지와 콘텐츠는 저작권 및 초상권을 침해하지 않도록 최선을 다하고 있습니다.<br/>
                        만약 저작권 또는 초상권 침해와 관련된 문제가 발생할 경우, 신속하고 적절한 조치를 취할 것을 약속드립니다.<br/>
                        문제가 발생하면 <Link to="/feedback"><span>피드백 센터</span></Link>를 통해 신고해 주시기 바랍니다.
                        <div className="info2">본 콘텐츠의 저작권은 저자 또는 제공처에 있으며 (주)디시인사이드의 입장과 다를 수 있습니다.</div>
                    </ul>
                </section>
            )}
        </>
    );
}

export default Footer;
