import StickyDefault from "../layout/stickyDefault";
import React from "react";
import {Link} from "react-router-dom";

function TermVote() {
    return (
        <>
            <StickyDefault/>
            <section className="contentsWrap">

                <div className="policy">
                    <div className="tab">
                        <ul><Link to="/policy">이용약관</Link></ul>
                        <ul><Link to="/policy/privacy">개인정보처리방침</Link></ul>
                        <ul className="on"><Link to="/policy/termsVote">디시트렌드 투표권 이용약관</Link></ul>
                    </div>
                    <div className="title">디시트렌드 투표권 이용약관</div>
                    <div className="box">
                        <ul>
                            <li>제1조 (목적, 적용범위 등)</li>
                            <li>
                                <dl><span>①</span> 이 약관은 TDI(이하 "TDI")의 디시트렌드 서비스에서 투표권을 이용하기 위해 사용되는 전자적 증표와 관련하여 TDI와 이용자(이하 "이용자") 사이의 권리, 의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.</dl>
                                <dl><span>②</span> 이 약관에서 정하지 않은 부분은 디시인사이드에서 정한 바에 따릅니다.</dl>
                            </li>
                        </ul>
                        <ul>
                            <li>제2조 (정의)</li>
                            <li>
                                <dl>이 약관에서 사용하는 용어의 정의는 다음과 같습니다.</dl>
                                <dl>
                                    <dt>
                                        <div>1. “투표권”은 디시트렌드 서비스에서 투표를 하기 위해 사용할 수 있는 TDI가 발행한 전자적 증표 또는 그 증표에 관한 정보를 말하며, 명칭은 TDI의 결정에 따라 변경될 수 있습니다.</div>
                                        <div>2. “패키지”라 함은 TDI가 발행한 투표권을 일정 수량으로 묶어 판매하는 것을 말합니다.</div>
                                    </dt>
                                </dl>
                            </li>
                        </ul>
                        <ul>
                            <li>제3조 (투표권의 구매 등)</li>
                            <li>
                                <dl><span>①</span> TDI는 이용자의 투표권 구매 전에 투표권에 대한 거래 조건을 정확하게 이해하고 실수나 착오 없이 거래할 수 있도록 다음 각 호의 사항들을 적절한 방법으로 이 약관 및 서비스 내에서 고지합니다.</dl>
                                <dl>
                                    <dt>
                                        <div>1. 판매자의 상호, 대표자 성명, 주소, 전화번호 등</div>
                                        <div>2. 투표권의 확인 및 선택, 구매 방법의 선택 및 결제정보의 입력, 구매 신청에 관한 확인, 종류 및 가격, 이용방법, 이용시기, 이용조건 등 구체적 내용</div>
                                        <div>3. 청약철회 및 계약해제의 기한・행사방법 및 효과, 환불 등에 관한 사항</div>
                                        <div>4. 소비자의 피해보상, 불만 처리 및 분쟁 처리에 관한 사항</div>
                                    </dt>
                                </dl>
                                <dl><span>②</span> TDI는 이용자의 투표권 구매 신청이 다음 각 호에 해당하는 경우에는 승낙하지 않거나 승낙을 유보하거나, 추후 승낙을 취소할 수 있습니다.</dl>
                                <dl>
                                    <dt>
                                        <div>1. 실명이 아니거나 타인의 명의를 이용한 경우</div>
                                        <div>2. 허위의 정보를 기재하거나, 필수 사항을 기재하지 않은 경우</div>
                                        <div>3. 서비스 관련 설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는 경우</div>
                                        <div>4. 동일 IP의 대량 결제, 유사 아이디에서의 연속적인 결제, 위험 IP에서의 결제 등 도용에 의한 투표권의 구매로 판단되는 경우</div>
                                        <div>5. 만 19세 미만의 미성년자가 법정대리인의 동의 없이 신청한 경우</div>
                                        <div>6. 결제에 대한 지급이 실제로 이루어지지 않았거나 않을 가능성이 있는 경우</div>
                                        <div>7. 기타 이용자의 투표권의 구매 신청이 TDI와 제3자의 이익을 훼손할 위험이 있다고 판단되는 경우</div>
                                    </dt>
                                </dl>
                                <dl><span>③</span> 투표권의 구매 계약은 TDI가 구매 완료를 신청절차 상에서 표시 또는 이용자에게 고지한 시점에 성립되며, 이때 TDI는 제1항 각 호의 내용을 포함하여 계약체결의 내용을 즉시 이용자에게 교부합니다.</dl>
                                <dl><span>④</span> 이용자는 이 약관 동의 및 투표권 구매에 따른 결제 후 서비스에서 투표권을 이용할 수 있습니다.</dl>
                                <dl><span>⑤</span> TDI는 이용자에게 사전에 공지하고 투표권의 종류와 가격, 지급수량 등 그 구체적인 내용을 변경하거나 특정한 투표권을 폐지할 수 있습니다. 단, 그와 같은 변경 및 폐지가 이용자에게 불리한 경우에는 공지 외 TDI 서비스 내 전자우편, 전자쪽지, 로그인 시 동의창 등의 전자적 수단을 통해 개별적으로 통지하여야 합니다.</dl>
                                <dl><span>⑥</span> TDI가 투표권의 종류 및 내용 등을 변경 혹은 폐지함에 따라 이용자가 자신의 귀책 없이 기존에 구매한 투표권을 구매할 당시에 정한 조건 등에 따라 이용하지 못하게 될 경우, TDI는 그와 같이 이용하지 못한 부분에 대해서 회원에게 적절한 보상을 합니다.</dl>
                                <dl><span>⑦</span> 디시트렌드는 디시인사이드와의 제휴로 운영되며, 제휴 기간 동안 제공되는 서비스에 대해서는 디시인사이드와 협력하여 운영됩니다.</dl>
                            </li>
                        </ul>
                        <ul>
                            <li>제4조 (미성년자 계약체결에 대한 특칙)</li>
                            <li>
                                <dl><span>①</span> 만 19세 미만의 미성년자인 이용자는 투표권 구매를 위해서는 부모 등 법정대리인의 동의를 얻어야 하며, TDI가 정한 절차에 따른 법정대리인의 동의를 받지 않은 경우에는 투표권을 구매할 수 없습니다.</dl>
                                <dl><span>②</span> TDI는 만 19세 미만의 미성년자인 이용자가 투표권을 구매하고자 하는 경우 부모 등 법정대리인의 동의를 얻지 않으면 미성년자 본인 또는 법정대리인이 그 계약을 취소할 수 있다는 내용을 계약 체결 전에 고지합니다.</dl>
                                <dl><span>③</span> 이용자가 미성년자임에도 불구하고 성년자인 제3자의 고유정보를 이용하여 구매하였거나 법정대리인의 동의절차를 진행한 경우 또는 성년자인 제3자의 결제정보를 동의 없이 이용하여 결제를 하는 등 속임수로써 TDI로 하여금 이용자가 성년자이거나 이용자의 법정대리인 동의가 있었던 것으로 믿게 한 경우 및 법정대리인이 범위를 정하여 처분을 허락한 재산으로 투표권을 구매한 경우에는 법정대리인의 동의가 없더라도 투표권 구매 및 구매한 투표권의 사용을 이용자 또는 그 법정대리인이 일방적으로 취소할 수 없습니다.</dl>
                            </li>
                        </ul>
                        <ul>
                            <li>제5조 (지급방법)</li>
                            <li>
                                <dl>이용자가 투표권을 구매하는 경우에는 TDI가 제공하는 결제 수단을 통해 구매할 수 있습니다.</dl>
                            </li>
                        </ul>
                        <ul>
                            <li>제6조 (청약철회 등)</li>
                            <li>
                                <dl><span>①</span> 이용자는 전자상거래 등에서의 소비자 보호에 관한 법률 등 관련 법령에서 정한 바에 따라 유상으로 구매한 투표권에 대하여 계약체결에 대한 내용을 교부 받은 시점 혹은 구매한 투표권을 제공받은 시점으로부터 7일 이내에 청약의 철회 및 계약의 해제(이하 “청약철회 등”)를 할 수 있습니다. 단, 이용자가 구매한 투표권을 이미 사용한 경우에는 사용한 부분에 대해서는 청약철회 등이 불가능하며, 이러한 불가능 사유에 대해서는 투표권 구매 시 TDI가 이용자에게 명확하게 고지합니다.</dl>
                                <dl><span>②</span> 전항에도 불구하고 투표권의 내용이 표시・광고의 내용과 다르거나 계약내용과 다르게 이행된 경우에는 이용자는 그 투표권을 공급받은 날부터 3개월 이내, 그 사실을 안 날 또는 알 수 있었던 날부터 30일 이내에 청약철회 등을 할 수 있습니다.</dl>
                                <dl><span>③</span> 제1항 또는 제2항에 따라서 이용자가 청약철회 등을 한 경우 전자상거래 등에서의 소비자 보호에 관한 법률에 따라 이용자가 구매한 투표권은 반환되며, TDI는 동법에 따라 환급 절차를 진행합니다.</dl>
                                <dl><span>④</span> 그 밖에 본 조에 따른 청약철회 등에 대한 사항은 전자상거래 등에서의 소비자 보호에 관한 법률에 따라 규율 및 처리됩니다.</dl>
                            </li>
                        </ul>
                        <ul>
                            <li>제7조 (환불정책)</li>
                            <li>
                                <dl><span>①</span> TDI는 이용자가 구매한 투표권의 미사용분에 대해 환불을 요구할 경우 환불수수료에 해당하는 금액을 공제하고 환불할 수 있습니다. 단, TDI의 귀책으로 인한 경우에는 환불수수료를 공제하지 않습니다.</dl>
                                <dl><span>②</span> 무상 투표권은 전항의 환불 대상에서 제외됩니다.</dl>
                                <dl><span>③</span> TDI는 이용자에 대하여 환불을 신청한 날부터 3영업일 이내에 그 결제와 동일한 방법으로 이를 환불합니다.</dl>
                            </li>
                        </ul>
                        <ul>
                            <li>제8조 (유효기간 등)</li>
                            <li>
                                <dl><span>①</span> 유상 투표권의 유효기간은 구매일로부터 5년입니다.</dl>
                                <dl><span>②</span> 무상 투표권의 경우 매월 1일 00시 00분에 일정 개수의 무료 투표권을 제공하며 이월되지 않습니다.</dl>
                            </li>
                        </ul>
                        <ul>
                            <li>제9조 (기타)</li>
                            <li>
                                <dl><span>①</span> TDI는 소비자의 피해보상, 불만 처리 및 분쟁 처리에 관한 사항, 환불에 대한 사항 등 소비자의 문제 제기에 대해 해결하기 위해서 피드백 센터를 운영합니다.</dl>
                                <dl><span>②</span> 무상 투표권의 경우 매월 1일 00시 00분에 5장의 무료 투표권을 제공하며 이월되지 않습니다.</dl>
                            </li>
                        </ul>
                        <ul>
                            <li>[부칙]</li>
                            <li>
                                <dl>이 약관은 2024년 10월 1일부터 적용됩니다.</dl>
                            </li>
                        </ul>

                    </div>
                </div>

            </section>
        </>
    );
}

export default TermVote;