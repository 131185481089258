import StickyDefault from "../layout/stickyDefault";
import React, { useEffect , useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import ChartReport from "../../component/chartReport";
import { DatePicker, ConfigProvider } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import koKR from 'antd/es/locale/ko_KR';
import {useApi} from "../../js/module/api";
import {decodeFromBase64} from "../../js/module/base64Utils";
import {useParams} from "react-router-dom";
import {formatNumberWithCommas} from "../../js/module/numberFormatter";
import RelationKeyword from "../ranking/RelationKeyword";
import KeywordNewsBox from "../ranking/keywordNewsBox";
import KeywordNaverBox from "../ranking/keywordNaverBox";
import KeywordYoutubeBox from "../ranking/keywordYoutubeBox";
import VoteRankBox from "./voteRankBox";
dayjs.locale('ko');

function Report() {
    const apiUrl = process.env.REACT_APP_API_URL;
    const { apiRequest } = useApi();
    const { infoData } = useParams();
    const [keywordId, setKeywordId] = useState("");
    const [voteId, setVoteId] = useState("");
    const [isIng, setIsIng] = useState("");
    const [detailData, setDetailData] = useState([]);
    const [statistic, setStatistic] = useState([]);
    const [chartData, setChartData] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const detailInfo = decodeFromBase64(infoData);
        const [voteIdValue, keywordIdValue,isIngValue] = detailInfo.split("@");
        setKeywordId(keywordIdValue);
        setVoteId(voteIdValue);
        setIsIng(isIngValue);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const fetchDetail = async () => {
            if (!keywordId) return;
            setLoading(true);
            try {
                const url = apiUrl + `v1/vote/keyword/detail/${voteId}/${keywordId}`;
                const data = await apiRequest(url, {}, 'GET', undefined, undefined, false, false);
                setDetailData(data.data.detail);
                setStatistic(data.data.statistic);
                setChartData(data.data.indexList);
            } catch (error) {
                console.error('데이터를 불러오는데 실패했습니다.', error);
            } finally {
                setLoading(false);
            }
        };
        fetchDetail().then();
        // eslint-disable-next-line
    }, [apiRequest, apiUrl, keywordId]);

    const fetchChartSet = async (startDate,endDate) => {
        setStartDate(startDate);
        setEndDate(endDate);
        try {
            const url = apiUrl + `v1/ranking/keyword/${keywordId}/indices?startDate=${startDate}&endDate=${endDate}`;
            const data = await apiRequest(url, {}, 'GET', undefined, undefined, false, false);
            setChartData(data.data);
        } catch (error) {
            console.error('데이터를 불러오는데 실패했습니다.', error);
        }
    };

    useEffect(() => {
        if (chartData.length > 0) {
            const startDate = chartData[0].date;
            const endDate = chartData[chartData.length - 1].date;
            setStartDate(startDate);
            setEndDate(endDate);
        }
    }, [chartData]);


    useEffect(() => {
        document.body.style.backgroundColor = "#FAFAFC";
        return () => {
            document.body.style.backgroundColor = "#ffffff";
        };
    }, []);

    const changeStartDate = (date) => {
        if (date) {
            const formattedDate = dayjs(date).format('YYYY-MM-DD');
            fetchChartSet(formattedDate,endDate).then();
        } else {
            console.log('날짜가 선택되지 않았습니다.');
        }
    };

    const changeEndDate = (date) => {
        if (date) {
            const formattedDate = dayjs(date).format('YYYY-MM-DD');
            fetchChartSet(startDate,formattedDate).then();
        } else {
            console.log('날짜가 선택되지 않았습니다.');
        }
    };

    return (
        <>
            <StickyDefault/>
            <section className="contentsWrap">

                <div className="reportWrap">
                    <div className="top">
                        <ul className="navi">{detailData.voteTitle} <div className="date">{detailData.startDate}~{detailData.endDate}</div></ul>
                        <ul className="title">
                            <li className="tit">'{detailData.keyword}' 트렌드 리포트</li>
                            <li className="rank">투표랭킹 <span>{detailData.rank}위</span></li>
                        </ul>
                    </div>
                    <div className="topIndex">
                        <Swiper
                            slidesPerView={'auto'}
                            spaceBetween={8}
                            noSwiping={false}
                            noSwipingClass='swiper-slide'
                            breakpoints={{
                                480: {
                                    slidesPerView: 'auto',
                                    spaceBetween: 16,
                                    noSwiping: false,
                                    noSwipingClass: 'swiper-slide',
                                },
                                1200: {
                                    slidesPerView: 'auto',
                                    spaceBetween: 20,
                                    noSwiping: true,
                                    noSwipingClass: 'swiper-slide',
                                }
                            }}
                        >
                            <SwiperSlide>
                                <ul>
                                    <li>인기 지수</li>
                                    <li>
                                        {formatNumberWithCommas(detailData.popularityIndexValue)}
                                    </li>
                                </ul>
                            </SwiperSlide>
                            <SwiperSlide>
                                <ul>
                                    <li>검색 지수</li>
                                    <li>{formatNumberWithCommas(detailData.searchIndexValue)}</li>
                                </ul>
                            </SwiperSlide>
                            <SwiperSlide>
                                <ul>
                                    <li>바이럴 지수</li>
                                    <li>{formatNumberWithCommas(detailData.viralIndexValue)}</li>
                                </ul>
                            </SwiperSlide>
                            <SwiperSlide>
                                <ul>
                                    <li>뉴스 지수</li>
                                    <li>{formatNumberWithCommas(detailData.newsIndexValue)}</li>
                                </ul>
                            </SwiperSlide>
                            <SwiperSlide>
                                <ul>
                                    <li>참여 지수</li>
                                    <li>{formatNumberWithCommas(detailData.participationIndexValue)}</li>
                                </ul>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                    <div className="trend origin">
                        <div className="left">
                            <div className="dbox chart">
                                <div className="stit">차트</div>
                                <div className="dateSelect">
                                    {/*
                                    <div className="tab">
                                        <ul data-type="daily" className={activeTab === 'daily' ? 'on' : ''} onClick={handleTabClick}>일별</ul>
                                        <ul data-type="weekly" className={activeTab === 'weekly' ? 'on' : ''} onClick={handleTabClick}>주별</ul>
                                        <ul data-type="monthly" className={activeTab === 'monthly' ? 'on' : ''} onClick={handleTabClick}>월별</ul>
                                        <ul data-type="custom" className={activeTab === 'custom' ? 'on' : ''} onClick={handleTabClick}>직접입력</ul>
                                    </div>
                                    */}
                                    {!loading && startDate && (
                                        <div className="picker on">
                                            <ConfigProvider locale={koKR} componentDisabled={false}>
                                                <DatePicker
                                                    className="reportPicker"
                                                    allowClear={false}
                                                    format="YYYY.MM.DD"
                                                    placeholder={'시작일'}
                                                    defaultValue={startDate ? dayjs(startDate, "YYYY-MM-DD") : null}
                                                    onChange={changeStartDate}
                                                    disabledDate={(current) =>
                                                        current && (current < dayjs('2024-09-01') || current > dayjs().subtract(3, 'day'))
                                                    }
                                                />
                                                -
                                                <DatePicker
                                                    className="reportPicker"
                                                    allowClear={false}
                                                    format="YYYY.MM.DD"
                                                    placeholder={'종료일'}
                                                    defaultValue={endDate ? dayjs(endDate, "YYYY-MM-DD") : null}
                                                    onChange={changeEndDate}
                                                    disabledDate={(current) => current && current > dayjs().subtract(2, 'day')}
                                                />
                                            </ConfigProvider>
                                        </div>
                                    )}
                                </div>
                                <div className="chartBox">
                                    <ChartReport dataPoints={chartData} />
                                </div>
                            </div>
                            <RelationKeyword keyword={detailData.keyword} />
                            <div className="dbox gender">
                                <div className="stit">관심 성별</div>
                                <div className="list">
                                    <ul>
                                        <li>남성</li>
                                        <li>{statistic.searchMalePercentage}%</li>
                                    </ul>
                                    <ul>
                                        <li>여성</li>
                                        <li>{statistic.searchFemalePercentage}%</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="dbox age">
                                <div className="stit">관심 연령</div>
                                <div className="list">
                                    <ul>
                                        <li>10대</li>
                                        <li>{statistic.searchAge10Percentage}%</li>
                                    </ul>
                                    <ul>
                                        <li>20대</li>
                                        <li>{statistic.searchAge20Percentage}%</li>
                                    </ul>
                                    <ul>
                                        <li>30대</li>
                                        <li>{statistic.searchAge30Percentage}%</li>
                                    </ul>
                                    <ul>
                                        <li>40대</li>
                                        <li>{statistic.searchAge40Percentage}%</li>
                                    </ul>
                                    <ul>
                                        <li>50대</li>
                                        <li>{statistic.searchAge50Percentage}%</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="right">
                            <VoteRankBox voteId={voteId} rank={detailData?.rank} isIng={isIng}/>
                        </div>
                    </div>
                    <div className="contents">
                        <KeywordNewsBox keywordId={keywordId} keyword={detailData.keyword} />
                        <KeywordNaverBox keyword={detailData.keyword} />
                        <KeywordYoutubeBox keyword={detailData.keyword} />
                    </div>
                </div>
            </section>
        </>
    );
}

export default Report;