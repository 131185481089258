import React from 'react';

const ChartDetail = ({ dataIndex }) => {
    if (!dataIndex || !dataIndex.length) {
        return <div>데이터가 없습니다.</div>;
    }

    // Destructure the values from dataIndex
    const {
        searchMalePercentage = 0,
        searchFemalePercentage = 0,
        searchAge10Percentage = 0,
        searchAge20Percentage = 0,
        searchAge30Percentage = 0,
        searchAge40Percentage = 0,
        searchAge50Percentage = 0,
    } = dataIndex[0] || {};

    return (
        <div className="right">
            <div className="box">
                <div className="tit">성별</div>
                <div className="gender">
                    <dl className="male">
                        <dt>남성</dt>
                        <dt>{searchMalePercentage.toFixed(2)}%</dt>
                    </dl>
                    <dl className="female">
                        <dt>여성</dt>
                        <dt>{searchFemalePercentage.toFixed(2)}%</dt>
                    </dl>
                </div>
            </div>
            <div className="box">
                <div className="tit">연령대</div>
                <div className="age">
                    <dl className="ageGroup1">
                        <dt>10대</dt>
                        <dt>{searchAge10Percentage.toFixed(2)}%</dt>
                    </dl>
                    <dl className="ageGroup2">
                        <dt>20대</dt>
                        <dt>{searchAge20Percentage.toFixed(2)}%</dt>
                    </dl>
                    <dl className="ageGroup3">
                        <dt>30대</dt>
                        <dt>{searchAge30Percentage.toFixed(2)}%</dt>
                    </dl>
                    <dl className="ageGroup4">
                        <dt>40대</dt>
                        <dt>{searchAge40Percentage.toFixed(2)}%</dt>
                    </dl>
                    <dl className="ageGroup5">
                        <dt>50대</dt>
                        <dt>{searchAge50Percentage.toFixed(2)}%</dt>
                    </dl>
                    <dl className="ageGroup6">
                        <dt>60대</dt>
                        <dt>-</dt>
                    </dl>
                </div>
            </div>
        </div>
    );
};

export default ChartDetail;
