import {useNavigate, useParams, Link} from "react-router-dom";
import React, { useCallback, useEffect, useState } from "react";
import NewsSubMenu from "./submenu";
import StickyDefault from "../layout/stickyDefault";
import NewsListRanking from "./newsListRanking";
import { Pagination } from 'antd';
import { useApi } from '../../js/module/api';
import decodeHtmlEntities from '../../js/module/HtmlDecode';
import htmlToText from '../../js/module/HtmlToText';
import {formatDay} from "../../js/module/formatDate";

function News() {
    const [height, setHeight] = useState('2100px');
    const [itemHeight, setItemHeight] = useState(175);
    const apiUrl = process.env.REACT_APP_API_URL;
    const { categoryId, pageNumber } = useParams();
    const navigate = useNavigate();
    const [newsList, setNewsList] = useState([]);
    const [resentList, setResentList] = useState([]);
    const [popularsList, setPopularsList] = useState([]);
    const [currentPage, setCurrentPage] = useState(parseInt(pageNumber) || 1);
    const [totalItems, setTotalItems] = useState(0);
    const [categoryList, setCategoryList] = useState([]);
    const pageSize = 12;
    const recentNews = 4;
    const popularNews = 5;
    const { apiRequest } = useApi();

    // 로컬스토리지에서 categoryList를 가져오거나 없으면 API를 호출해서 가져옴
    useEffect(() => {
        if (categoryList.length === 0) {
            const storedData = JSON.parse(localStorage.getItem('categoryList'));
            if (storedData) {
                setCategoryList(storedData);
            } else {
                const fetchCategories = async () => {
                    try {
                        const data = await apiRequest(`${apiUrl}v1/news/categories/group`, {}, 'GET');
                        localStorage.setItem('categoryList', JSON.stringify(data.data));
                        setCategoryList(data.data);
                    } catch (error) {
                        console.error("Failed to fetch categories:", error);
                    }
                };
                fetchCategories().then();
            }
        }
    }, [apiRequest, apiUrl, categoryList.length]);

    const getIdByCode = useCallback((groupCode) => {
        const group = categoryList.find(group => group.groupCode === groupCode);

        if (group) {
            const ids = group.categories.map(cat => cat.id);
            return ids.join(',');
        }

        return '';
    }, [categoryList]);

    const fetchNews = useCallback(async (page, categoryId) => {
        try {
            const data = await apiRequest(
                `${apiUrl}v1/news?categoryIds=${getIdByCode(categoryId)}&page=${page}&size=${pageSize}&recentNews=${recentNews}&popularNews=${popularNews}`,
                {},
                'GET'
            );
            const width = window.innerWidth;
            if (width < 480) {
                setItemHeight(164)
            } else if (width < 800) {
                setItemHeight(138);
            } else {
                setItemHeight(172);
            }
            setHeight(data.data.newsList.content.length * itemHeight + 'px') ;
            setNewsList(data.data.newsList.content);
            setTotalItems(data.data.newsList.metadata.totalCounts);
            setResentList(data.data.resents);
            setPopularsList(data.data.populars);
        } catch (error) {
            console.error("뉴스를 가져오는 데 실패했습니다.", error);
        }
    }, [apiRequest, pageSize, apiUrl, itemHeight, getIdByCode]);

    useEffect(() => {
        if (categoryList.length > 0) {
            const pageFromUrl = parseInt(pageNumber) || 1;
            setCurrentPage(pageFromUrl);
            fetchNews(pageFromUrl, categoryId).then();
        }
    }, [categoryId, pageNumber, fetchNews, categoryList]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
        navigate(`/news/trend/${categoryId}/${page}`);
    };

    return (
        <>
            <StickyDefault />
            <NewsSubMenu />
            <section className="contentsWrap">
                <div className="newsLayout">
                    <div className="leftWrap">
                        <div className="newsList" style={{ height }}>
                            <div className="list">
                                {newsList.map(item => (
                                    <ul key={item.newsId} className="hoverImgPt">
                                        <Link to={`/news/trend/detail/${item.categoryId}/${item.newsId}`}>
                                            <div className="thumb skeletonStyle">
                                                <img
                                                    src={item.thumbnail || "https://cdn.trend.rankify.best/dctrend/front/images/defaultImage_dt.svg"}
                                                    alt={item.title || 'No Image'}
                                                    onError={(e) => { e.target.src = "https://cdn.trend.rankify.best/dctrend/front/images/defaultImage_dt.svg"; }}
                                                />
                                            </div>
                                            <li className="tit">{decodeHtmlEntities(item.title)}</li>
                                            <li className="txt">{htmlToText(item.content, 150)}</li>
                                            <li className="info">
                                                <dl>{item.pressName}</dl>
                                                <dl>{formatDay(item.publishedAt)}</dl>
                                                {/*
                                                <dl><img src="https://cdn.trend.rankify.best/dctrend/front/images/ico_comment.png" alt=""/>0</dl>
                                                */}
                                            </li>
                                        </Link>
                                    </ul>
                                ))}
                            </div>
                        </div>
                        <div className="paging">
                            <Pagination
                                onChange={handlePageChange}
                                align="center"
                                defaultCurrent={1}
                                total={totalItems}
                                pageSize={pageSize}
                                current={currentPage}
                                showSizeChanger={false}
                                responsive={true}
                            />
                        </div>
                    </div>
                    <div className="rightWrap">
                        <div className="rightSticky">
                            <div className="stickyTitle">랭킹 뉴스</div>
                            <div className="rankNews mgb40">
                                <div className="rankChartRight">
                                    <NewsListRanking />
                                </div>
                            </div>
                            <div className="stickyTitle">실시간 인기기사</div>
                            <div className="popularNewsRight">
                                {popularsList.map((item, index) => (
                                    <Link key={index} to={`/news/trend/detail/${item.categoryId}/${item.newsId}`}>
                                        <ul key={index}>
                                            <li>{index + 1}</li>
                                            <li>{decodeHtmlEntities(item.title)}</li>
                                            <li>
                                                <img
                                                    src={item.thumbnail || "https://cdn.trend.rankify.best/dctrend/front/images/defaultImage_dt.svg"}
                                                    alt={item.title || 'No Image'}
                                                    onError={(e) => { e.target.src = "https://cdn.trend.rankify.best/dctrend/front/images/defaultImage_dt.svg"; }}
                                                />
                                            </li>
                                        </ul>
                                    </Link>
                                ))}
                            </div>
                            <div className="stickyTitle">최신 기사</div>
                            <div className="rtNewsRight">
                                {resentList.map(item => (
                                    <ul key={item.newsId}>
                                        <Link to={`/news/trend/detail/${item.categoryId}/${item.newsId}`}>
                                            <li>
                                                <img
                                                    src={item.thumbnail || "https://cdn.trend.rankify.best/dctrend/front/images/defaultImage_dt.svg"}
                                                    alt={item.title || 'No Image'}
                                                    onError={(e) => { e.target.src = "https://cdn.trend.rankify.best/dctrend/front/images/defaultImage_dt.svg"; }}
                                                />
                                            </li>
                                            <li>{decodeHtmlEntities(item.title)}</li>
                                        </Link>
                                    </ul>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default News;
