import React from 'react';

function ContTermUse() {

    return (
        <div className="box">
            <li className="tit">디시트렌드 이용약관</li>
            <ul>
                <li>제1조 (목적)</li>
                <li>
                    <dl>이 약관은 TDI(이하 "회사")가 제공하는 디시트렌드 서비스를 이용함에 있어 회사와 이용자의 권리, 의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.</dl>
                </li>
            </ul>
            <ul>
                <li>제2조 (용어의 정의)</li>
                <li>
                    <dl>이 약관에서 사용하는 용어의 정의는 다음과 같습니다.</dl>
                    <dl>
                        <dt>
                            <div>1. "서비스"란 회사가 제공하는 디시트렌드의 서비스를 의미합니다.</div>
                            <div>2. "이용자"란 회사의 서비스에 접속하여 이 약관에 따라 서비스를 이용하는 모든 사용자(소셜로그인 사용자 포함)를 의미합니다.</div>
                            <div>3. "투표권"이란 디시트렌드 서비스 내에서 이용자가 투표에 참여할 수 있는 권리로, 매월 일정 개수의 투표권이 무료로 제공되며, 이후 추가 투표권은 유료로 구매할 수 있습니다.</div>
                        </dt>
                    </dl>
                </li>
            </ul>
            <ul>
                <li>제3조 (약관의 개정)</li>
                <li>
                    <dl>
                        <dt>
                            <div>1.	회사는 필요 시 약관을 개정할 수 있으며, 개정된 약관은 게시와 동시에 효력이 발생합니다.</div>
                            <div>2.	약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 당 사이트의 초기화면에 그 적용일자 7일 이전부터 적용일자 전일까지 공지합니다. 다만, 이용자에게 불리하게 약관내용을 변경하는 경우에는 최소한 30일 이상의 사전 유예기간을 두고 공지합니다. 이용자가 변경된 약관에 동의하지 않는 경우, 이용자는 디시트렌드에 사용한 소셜로그인 계정을 탈퇴할 수 있으며 계속 사용의 경우는 약관 변경에 대한 동의로 간주됩니다.</div>
                        </dt>
                    </dl>
                </li>
            </ul>
            <ul>
                <li>제4조 (서비스의 제공 및 관리)</li>
                <li>
                    <dl>
                        <dt>
                            <div>1.	회사는 이용자에게 디시트렌드 서비스를 제공합니다.</div>
                            <div>2.	서비스의 내용 및 제공 시간은 회사의 정책에 따라 변경될 수 있습니다.</div>
                            <div>3.	디시트렌드 서비스는 디시인사이드 웹사이트를 통해 제공될 수 있으며, 디시트렌드 서비스 및 그에 따른 디시트렌드 이용자의 개인정보 수집·이용에 대한 책임은 TDI에게 있습니다.</div>
                        </dt>
                    </dl>
                </li>
            </ul>
            <ul>
                <li>제5조 (투표권의 제공 및 유료 구매)</li>
                <li>
                    <dl>
                        <dt>
                            <div>1.	이용자는 매월 일정 개수의 무료 투표권을 제공받습니다.</div>
                            <div>2.	추가 투표권이 필요한 경우, 이용자는 회사가 정한 결제수단을 이용하여 유료로 투표권을 구매할 수 있습니다.</div>
                            <div>3.	투표권의 가격 및 충전 방식은 회사의 정책에 따라 변경될 수 있습니다.</div>
                            <div>4.	회사는 관련 법령에 따라 전자상거래 판매업 신고를 완료하였으며, 이용자는 구매 페이지에서 해당 정보를 확인할 수 있습니다.</div>
                        </dt>
                    </dl>
                </li>
            </ul>
            <ul>
                <li>제6조 (댓글 관리)</li>
                <li>
                    <dl>1. 댓글 작성</dl>
                    <dl>
                        <dt>
                            <div><span>①</span> 디시트렌드에서 제공되는 뉴스에 대한 댓글 작성은 닉네임과 비밀번호를 입력하여 작성이 가능합니다.</div>
                        </dt>
                    </dl>
                    <dl>2. 댓글의 관리 및 삭제</dl>
                    <dl>
                        <dt>
                            <div><span>①</span> 이용자가 작성한 댓글이 "정보통신망법" 등 관련 법령에 위반되거나, 미풍양속을 저해하는 경우, 회사는 해당 댓글에 대해 임시 조치(삭제, 노출 제한, 게시 중단 등)를 취할 수 있습니다.</div>
                        </dt>
                    </dl>
                    <dl>3. 댓글 열람 및 보관</dl>
                    <dl>
                        <dt>
                            <div><span>①</span> 회사는 댓글을 저장 및 보관할 수 있으며, 서비스 내 민원 처리, 분쟁 조정, 질서 유지 및 기타 법령 위반 여부 확인을 위해 필요할 경우에 한하여 댓글을 열람할 수 있습니다.</div>
                            <div><span>②</span> 회사는 댓글 운영을 위해 제3자에게 댓글 관리 업무를 위탁할 수 있습니다. 이 경우, 제3자는 관련 법령을 준수하여 댓글을 관리하며, 이용자의 개인정보는 계약된 목적 범위 내에서만 처리됩니다.</div>
                        </dt>
                    </dl>
                </li>
            </ul>
            <ul>
                <li>제7조 (저작권 및 이미지 사용)</li>
                <li>
                    <dl>
                        <dt>
                            <div>1.	디시트렌드 서비스에서 제공되는 랭킹 및 투표에 사용되는 이미지는 회사가 포털에서 수집하여 제공하며, 이에 대한 저작권은 해당 저작권자에게 있습니다.</div>
                            <div>2.	이용자는 피드백 센터를 통해 저작권 이슈 또는 기타 권리 침해 문제를 신고할 수 있습니다.</div>
                            <div>3.	본 콘텐츠의 저작권은 저자 또는 제공처에 있으며, (주)디시인사이드의 입장과 다를 수 있습니다.</div>
                        </dt>
                    </dl>
                </li>
            </ul>
            <ul>
                <li>제8조 (개인정보 보호)</li>
                <li>
                    <dl>회사는 개인정보 처리방침에 따라 이용자의 개인정보를 보호하기 위해 노력합니다.</dl>
                </li>
            </ul>
            <ul>
                <li>제9조 (책임의 한계)</li>
                <li>
                    <dl>
                        <dt>
                            <div>1.	회사는 천재지변, 전쟁, 정부의 규제 등 불가항력적인 사유로 인하여 발생한 서비스 제공의 지연, 중단, 손해에 대하여 책임을 지지 않습니다.</div>
                            <div>2.	디시트렌드 서비스는 디시인사이드 웹사이트를 통해 제공될 수 있으며, 디시인사이드는 본 서비스와 관련된 어떠한 책임도 지지 않습니다.</div>
                        </dt>
                    </dl>
                </li>
            </ul>
            <ul>
                <li>제10조 (서비스 중단 및 변경)</li>
                <li>
                    <dl>1. 서비스 중단</dl>
                    <dl>
                        <dt>
                            <div><span>①</span> 회사는 연중 무휴, 1일 24시간 안정적으로 디시트렌드 서비스를 제공하기 위해 최선을 다합니다. 그러나 컴퓨터, 서버 등 정보통신설비의 보수점검, 교체 또는 고장, 통신 두절 등 운영상 상당한 이유가 있는 경우, 부득이하게 서비스의 전부 또는 일부를 중단할 수 있습니다.</div>
                        </dt>
                    </dl>
                    <dl>2. 서비스 변경</dl>
                    <dl>
                        <dt>
                            <div><span>①</span> 회사는 서비스 운영 또는 개선을 위해 상당한 필요성이 있는 경우, 디시트렌드 서비스의 전부 또는 일부를 수정, 변경, 또는 종료할 수 있습니다. 무료로 제공되는 서비스의 전부 또는 일부를 수정, 변경, 또는 종료하게 된 경우, 관련 법령에 특별한 규정이 없는 한 별도의 보상을 하지 않습니다.</div>
                        </dt>
                    </dl>
                    <dl>3. 안내 및 공지</dl>
                    <dl>
                        <dt>
                            <div><span>①</span> 서비스 중단 또는 변경이 예측 가능한 경우, 회사는 이를 상당 기간 전에 안내합니다. 예측이 불가능한 경우라면, 사후 지체 없이 상세히 설명하고 안내하겠습니다.</div>
                        </dt>
                    </dl>
                </li>
            </ul>
            <ul>
                <li>제11조 (소셜로그인 탈퇴 및 처리 절차)</li>
                <li>
                    <dl>1. 탈퇴 시 처리 내용</dl>
                    <dl>
                        <dt>
                            <div><span>①</span> 이용자가 소셜로그인 계정을 탈퇴할 경우, 디시트렌드 서비스를 탈퇴하는 것으로 간주합니다.</div>
                            <div><span>②</span> 이용자가 디시트렌드 서비스를 탈퇴할 경우, 보유 중인 무료 투표권은 소멸됩니다.</div>
                            <div><span>③</span> 결제일로부터 1년 이내에 구매한 유료 투표권에 대해서는 원 결제 수단(카드)으로 환불 처리되며, 1년이 넘은 거래에 대해서는 계좌 이체 또는 기타 적절한 방법으로 환불 처리됩니다.</div>
                            <div><span>④</span> 환불 절차는 탈퇴 신청 접수 후 7영업일 이내에 완료됩니다.</div>
                            <div><span>⑤</span> 이용자의 모든 데이터는 관련 법령 및 개인정보처리방침에 따라 처리되며, 이용자의 모든 계정 데이터는 소멸되고 복구할 수 없습니다.</div>
                        </dt>
                    </dl>
                    <dl>2. 탈퇴 시 투표 내역 관리</dl>
                    <dl>
                        <dt>
                            <div><span>①</span> 디시트렌드 서비스 탈퇴 시, 모든 개인 정보 및 투표 내역이 삭제되며, 이는 복구할 수 없습니다.</div>
                        </dt>
                    </dl>
                    <dl>3. 탈퇴 후 재가입 규정</dl>
                    <dl>
                        <dt>
                            <div><span>①</span> 디시트렌드 서비스 탈퇴 후 재가입하더라도 기존의 투표권은 이미 환불 절차가 진행되었기 때문에 양도되지 않습니다.</div>
                        </dt>
                    </dl>
                </li>
            </ul>
            <ul>
                <li>[부칙]</li>
                <li>
                    <dl>이 약관은 2024년 10월 1일부터 적용됩니다.</dl>
                </li>
            </ul>

        </div>
    );
}

export default ContTermUse;