import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { extractKeywordInfo } from "../../js/module/profileConvert";

const getDisplayCategory = (categoryPath) => {
    const cateArray = categoryPath.split(' > '); // 문자열을 ' > '로 분리하여 배열로 변환
    const lastValue = cateArray[cateArray.length - 1];

    if (lastValue === "남자" || lastValue === "여자") {
        return `${cateArray[cateArray.length - 3] || ''}・${cateArray[cateArray.length - 2] || ''}`.trim();
    }

    return `${cateArray[cateArray.length - 2] || ''}・${lastValue}`; // 두 번째 마지막 값과 마지막 값을 조합하여 반환
};

function PeopleBox({ peopleData }) {
    const [list, setList] = useState([]);
    const [profile, setProfile] = useState(null);
    const [activeIndex, setActiveIndex] = useState(0); // 기본적으로 첫 번째 항목을 활성화

    useEffect(() => {
        if (peopleData.length > 0) {
            setList(peopleData);
            const firstItem = peopleData[0];
            const extractedInfo = extractKeywordInfo(firstItem.keywordInfo);
            setProfile({
                imageUrl: firstItem.imageUrl,
                name: firstItem.keyword,
                info: extractedInfo.slice(0, 6) // 최대 6개까지만 가져오기
            });
        }
    }, [peopleData]);

    // 클릭 핸들러 추가
    const handleProfileChange = (item, index) => {
        const extractedInfo = extractKeywordInfo(item.keywordInfo); // 클릭한 아이템의 키워드 정보 추출
        setProfile({
            imageUrl: item.imageUrl,
            name: item.keyword,
            info: extractedInfo.slice(0, 6) // 최대 6개까지만 가져오기
        });
        setActiveIndex(index); // 클릭된 인덱스 저장
    };

    return (
        <>
            <div className="profileTitle">인물정보</div>
            {profile && (
                <div className="profileBox" data-id={profile.keywordId}>
                    <ul className="thumb">
                        <img src={profile.imageUrl} alt={profile.name} />
                    </ul>
                    <ul className="info">
                        <div className="name">{profile.name}</div>
                        {profile.info.map((item, index) => (
                            <li key={index}>
                                <dl>{item.title}</dl>
                                <dl>{item.value}</dl>
                            </li>
                        ))}
                        <div className="source">출처 : 멜론(Melon) www.melon.com/artist</div>
                    </ul>
                </div>
            )}
            <div className="another">
                <Swiper slidesPerView={'auto'} spaceBetween={16}>
                    {list.slice(0, 5).map((item, index) => (
                        <SwiperSlide key={index}>
                            <ul
                                className={`${item.keywordId} ${activeIndex === index ? 'on' : ''}`} // activeIndex에 따라 'on' 클래스 추가
                                onClick={() => handleProfileChange(item, index)} // 인덱스도 함께 전달
                            >
                                <li className="thumb">
                                    <img
                                        src={item.imageUrl || "https://cdn.trend.rankify.best/dctrend/front/images/defaultImage_dt.svg"}
                                        alt={item.title || 'No Image'}
                                        onError={(e) => { e.target.src = "https://cdn.trend.rankify.best/dctrend/front/images/defaultImage_dt.svg"; }}
                                    />
                                </li>
                                <li>
                                    <dl>{getDisplayCategory(item.categoryPath)}</dl>
                                    <dl>{item.keyword}</dl>
                                </li>
                            </ul>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </>
    );
}

export default PeopleBox;
