import CryptoJS from 'crypto-js';

export const encryptAES = (text, secretKey) => {
    const key = CryptoJS.enc.Utf8.parse(secretKey);  // 키를 32바이트로 맞추기

    const ciphertext = CryptoJS.AES.encrypt(text, key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    }).toString();

    return ciphertext;
};


// 복호화 함수
export const decryptAES = (ciphertext, secretKey) => {
    const key = CryptoJS.enc.Utf8.parse(secretKey);  // 키를 32바이트로 맞추기

    const bytes = CryptoJS.AES.decrypt(ciphertext, key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    });
    const plaintext = bytes.toString(CryptoJS.enc.Utf8);

    return plaintext;
};
