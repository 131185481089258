import React, {useEffect, useState} from "react";
import StickyDefault from "../layout/stickyDefault";
import {Link, useParams} from "react-router-dom";
import {useAlertNavigation } from  "../../js/module/checkNavigation"
import {useApi} from "../../js/module/api";
import {encodeToBase64} from "../../js/module/base64Utils";
import CommentWrap from "../news/comment/comment";

function VoteList() {
    const handleNavigationWithAlert = useAlertNavigation();
    const apiUrl = process.env.REACT_APP_API_URL;
    const { apiRequest } = useApi();
    const { voteId } = useParams();
    const [height, setHeight] = useState('4050px');
    const [itemHeight, setItemHeight] = useState(81);
    const [voteInfo, setVoteInfo] = useState([]);
    const [voteList, setVoteList] = useState([]);
    const [displayCate, setDisplayCate] = useState("");

    useEffect(() => {
        const fetchRankList = async () => {

            try {
                const url = apiUrl + `v1/vote/${voteId}`;
                const data = await apiRequest(url, {}, 'GET', undefined, undefined, false, false);
                const width = window.innerWidth;
                if (width < 480) {
                    setItemHeight(86)
                } else if (width < 800) {
                    setItemHeight(81);
                } else {
                    setItemHeight(81);
                }
                setHeight(data.data.keyword.length * itemHeight + 'px') ;
                setVoteInfo(data.data.vote);
                setVoteList(data.data.keyword);
            } catch (error) {
                console.error('데이터를 불러오는데 실패했습니다.', error);
            } finally {

            }
        };
        fetchRankList().then();
        // eslint-disable-next-line
    }, [apiRequest,apiUrl,voteId,itemHeight]);

    useEffect(() => {
        if (voteInfo.categoryPath) {
            const cateArray = voteInfo.categoryPath.split(' > '); // 문자열을 ' > '로 분리하여 배열로 변환
            const lastValue = cateArray[cateArray.length - 1];
            setDisplayCate(`${cateArray[cateArray.length - 2] || ''}・${lastValue}`);
        }
    }, [voteInfo.categoryPath]);

    useEffect(() => {
        document.body.style.backgroundColor = "#FAFAFC";
        return () => {
            document.body.style.backgroundColor = "#ffffff";
        };
    }, []);

    const MoveVoteDo = (voteId,keywordId) => {
        const infoData = encodeToBase64(`${voteId}@${keywordId}`)
        handleNavigationWithAlert(`/vote/do/${infoData}`);
    };

    return (
        <>
            <StickyDefault/>
            <section className="contentsWrap">

                <div className="voteList">
                    <div className="box">
                        <div className="top">
                            <ul className="info">
                                <li className="date">
                                    {voteInfo.startDate && (
                                        <>
                                            {voteInfo.startDate.substring(2).replace(/-/g, '.')} ~ {voteInfo.endDate.substring(2).replace(/-/g, '.')}
                                        </>
                                    )}
                                </li>
                                <li className="title">{voteInfo.title}</li>
                            </ul>
                            <ul className="mask"></ul>
                            <ul className="bg"><img src={voteInfo.thumbnail} alt=""/></ul>
                        </div>
                        <div className="list" style={{ height }}>
                            {voteList.map((item,index) => (
                                <ul key={index}>
                                    <li className="rank">
                                        <div className="num">{item.rank}</div>
                                    </li>
                                    <li className="info">
                                        <dl className="thumb">
                                            <img
                                                src={item.imageUrl || "https://cdn.trend.rankify.best/dctrend/front/images/defaultImage_dt.svg"}
                                                alt={item.title || 'No Image'}
                                                onError={(e) => { e.target.src = "https://cdn.trend.rankify.best/dctrend/front/images/defaultImage_dt.svg"; }}
                                            />
                                        </dl>
                                        <dl className="txt">
                                            <dt className="cate">{displayCate}</dt>
                                            <dt className="name">{item.keyword}</dt>
                                            <dt className="scoreIn">{item.totalVotes} 표</dt>
                                        </dl>
                                    </li>
                                    <li className="score">{item.totalVotes} 표</li>
                                    <li className="btn" onClick={() => MoveVoteDo(item.voteId,item.keywordId)}>투표</li>
                                </ul>
                            ))}
                        </div>
                    </div>
                    <Link to={`/vote/another/${voteId}`}><div className="another">선호하는 대상이 없나요?</div></Link>
                </div>
                <div id="commentPoint" className="voteCommentWrap">
                    <CommentWrap id={voteId} type="vote"/>
                </div>
            </section>
        </>
    );
}

export default VoteList;