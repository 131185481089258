import React, { useRef, useEffect, useState } from 'react';
import Chart from 'chart.js/auto';

const ChartKeyword = ({ dataPoints }) => {
    const chartRef = useRef(null);
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 600);

    useEffect(() => {
        // Resize event listener to detect screen size changes
        const handleResize = () => setIsSmallScreen(window.innerWidth < 600);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const canvas = chartRef.current;
        const ctx = canvas.getContext('2d');

        const parsedData = JSON.parse(dataPoints);

        const chartData = parsedData.map(point => ({
            x: point.date,
            y: point.indexValue
        }));

        new Chart(ctx, {
            type: 'line',
            data: {
                datasets: [{
                    data: chartData,
                    borderColor: '#FF5656',
                    borderWidth: 2,
                    fill: false,
                    tension: 0.1,
                    pointBackgroundColor: '#ffffff',
                    pointRadius: isSmallScreen ? 2 : 3,
                }]
            },
            options: {
                plugins: {
                    legend: {
                        display: false
                    },
                    tooltip: {
                        enabled: false, // 툴팁 비활성화
                    },
                    datalabels: {
                        display: false
                    }
                },
                scales: {
                    x: {
                        type: 'time',
                        time: {
                            unit: 'day',
                            displayFormats: {
                                day: 'MM.dd'
                            }
                        },
                        ticks: {
                            autoSkip: true,
                            maxTicksLimit: isSmallScreen ? 4 : 10,
                            color: '#8491A7',
                            font: {
                                size: 12,
                                family: 'Pretendard',
                                weight: '600',
                            }
                        },
                        title: {
                            display: false,
                        },
                        grid: {
                            display: false
                        }
                    },
                    y: {
                        beginAtZero: true,
                        title: {
                            display: false
                        },
                        ticks: {
                            autoSkip: true,
                            maxTicksLimit: 10,
                            color: '#B4C0D3',
                            font: {
                                size: 12,
                                family: 'Pretendard',
                                weight: '400',
                            }
                        },
                        grid: {
                            color: '#EAEDF4',
                        }
                    }
                },
                responsive: true,
                maintainAspectRatio: false
            }
        });

        return () => {
            if (canvas) {
                const chartInstance = Chart.getChart(canvas);
                if (chartInstance) chartInstance.destroy();
            }
        };
    }, [dataPoints, isSmallScreen]);

    return <canvas ref={chartRef} />;
};

export default ChartKeyword;
