import {Link, useLocation, useParams, useNavigate } from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import StickyDefault from "../layout/stickyDefault";
import {useApi} from "../../js/module/api";
import PopularRankBox from "../../component/popularRankBox";
import VoteBox from "../../component/voteBox";

const titleMap = {
    celebrity: { title: '연예인 랭킹', code: '705' },
    athlete: { title: '스포츠인 랭킹', code: '706' }
};

const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}년 ${month}월 ${day}일`;
};

function Popular() {
    const location = useLocation();
    const navigate = useNavigate();
    const apiUrl = process.env.REACT_APP_API_URL;
    const { apiRequest } = useApi();
    const { category } = useParams();
    const [rankDate, setRankDate] = useState("");
    const [items, setItems] = useState(() => {
        const savedItems = sessionStorage.getItem('prankitems');
        return savedItems ? JSON.parse(savedItems) : [];
    });
    const [page, setPage] = useState(() => {
        const savedPage = sessionStorage.getItem('prankpage');
        return savedPage ? Number(savedPage) : 1;
    });
    const [totalPages, setTotalPages] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const observerRef = useRef(null);

    useEffect(() => {
        // 새로고침 플래그 체크
        const hasReloaded = sessionStorage.getItem('voteReloaded');

        // voteList가 있는지 확인
        const pageCheck = sessionStorage.getItem('voteList');
        if (pageCheck) {
            sessionStorage.removeItem('voteList');
        } else if (!hasReloaded) {
            sessionStorage.setItem('voteReloaded', 'true');
            navigate(0);
            setItems([]);
            setPage(1);
        } else {
            setItems([]);
            setPage(1);
            sessionStorage.removeItem('voteitems');
            sessionStorage.removeItem('votepage');
            sessionStorage.removeItem('voteReloaded');
        }
        // eslint-disable-next-line
    }, [location]);

    const fetchItems = async (page) => {
        setIsLoading(true);
        try {
            const url = apiUrl + `v1/ranking/${titleMap[category].code}?size=6&page=${page}`;
            const data = await apiRequest(url, {}, 'GET', undefined, undefined, false, false);
            setRankDate(data.data.updatedDate);
            setTotalPages(data.data.totalPages || 1);
            return Array.isArray(data.data.rankingData) ? data.data.rankingData : [];
        } catch (error) {
            console.error('데이터를 불러오는데 실패했습니다.', error);
            return [];
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        sessionStorage.setItem('prankitems', JSON.stringify(items));
        sessionStorage.setItem('prankpage', page.toString());
    }, [items, page]);

    useEffect(() => {
        setHasMore(true);
        setPage(1);
    }, [category]);

    const handleObserver = (entries) => {
        const [target] = entries;
        if (target.isIntersecting && hasMore) {
            setPage((prev) => prev + 1);
        }
    };

    useEffect(() => {
        const loadItems = async () => {
            const newItems = await fetchItems(page);
            if (newItems.length === 0) {
                setHasMore(false);
            } else {
                const uniqueItems = [...new Map([...items, ...newItems].map(item => [item.order, item])).values()];
                setItems(uniqueItems);
            }
            if (newItems.length > 0) {
                if (observerRef.current && document.getElementById('load-more')) {
                    observerRef.current.observe(document.getElementById('load-more'));
                }
            }
        };
        if (hasMore && page <= totalPages) {
            loadItems().then();
        }
        return () => {
            if (observerRef.current) observerRef.current.disconnect();
        };
        // eslint-disable-next-line
    }, [page]);

    useEffect(() => {
        observerRef.current = new IntersectionObserver(handleObserver, {
            root: null,
            rootMargin: '0px',
            threshold: 0.5,
        });
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <StickyDefault/>
            <section className="contentsWrap">
                <div className="rankingMain">
                    <div className="mainTitle">
                        <ul className="tit">{titleMap[category].title || '랭킹'}<img className="hiddenInfo" src="https://cdn.trend.rankify.best/dctrend/front/images/ico_info.svg" alt=""/></ul>
                        {rankDate !== "" && (
                            <ul className="update">{formatDate(rankDate)} 기준 랭킹 데이터입니다.</ul>
                        )}
                        <ul className="info"><img src="https://cdn.trend.rankify.best/dctrend/front/images/ico_info.svg" alt=""/>랭킹은 인기지수와 이슈지수를 반영하여 집계되며, 키워드의 온라인 관심도와 활동을 반영합니다.</ul>
                    </div>
                    <div className="tab">
                        <ul className="on">인기 랭킹</ul>
                        <Link to={`/ranking/${category}`}><ul>이슈 랭킹</ul></Link>
                    </div>
                    <div className="rankingBox">
                        {items.length === 0 && (
                            <>
                                <div className="box skeletonStyle"></div>
                                <div className="box skeletonStyle"></div>
                                <div className="box skeletonStyle"></div>
                                <div className="box skeletonStyle"></div>
                                <div className="box skeletonStyle"></div>
                                <div className="box skeletonStyle"></div>
                            </>
                        )}
                        {items?.map((item, index) => (
                            <PopularRankBox key={index} rankingData={item} />
                        ))}
                    </div>
                    {isLoading && (
                        <></>
                    )}
                    <div id="load-more" style={{ height: '20px'}}></div>
                    <div className="voteTitle">실시간 인기투표</div>
                    <VoteBox category={""} sort={"readCount,desc"}/>
                    {/*
                    <div className="blank"></div>
                    <div className="voteTitle">투표율 급상승</div>
                    <div className="voteBox">
                        <Swiper
                            direction='vertical'
                            slidesPerView='auto'
                            spaceBetween={0}
                            touchReleaseOnEdges={true}
                            breakpoints={{
                                480: {
                                    direction: 'horizontal',
                                    slidesPerView: 'auto',
                                    spaceBetween: 16,
                                },
                                1200: {
                                    direction: 'horizontal',
                                    slidesPerView: 3,
                                    spaceBetween: 16,
                                }
                            }}
                        >
                            <SwiperSlide>
                                <ul className="hoverImgPt">
                                    <li className="thumb"><img src="https://img.tvreportcdn.de/cms-content/uploads/2024/05/10/55571fb3-142e-4439-be30-ed3a33f4ed3e.jpg" alt=""/></li>
                                    <li className="info">
                                        <dl className="date">23.06.14 ~ 24.06.30</dl>
                                        <dl className="title">예능인 남성 랭킹</dl>
                                        <Link to="/vote/detail"><dl className="btn">투표</dl></Link>
                                    </li>
                                </ul>
                            </SwiperSlide>
                            <SwiperSlide>
                                <ul className="hoverImgPt">
                                    <li className="thumb"><img src="https://cdn.thetitlenews.net/news/photo/202309/2337_4003_554.png" alt=""/></li>
                                    <li className="info">
                                        <dl className="date">23.06.14 ~ 24.06.30</dl>
                                        <dl className="title">예능인 여성 랭킹</dl>
                                        <Link to="/vote/detail"><dl className="btn">투표</dl></Link>
                                    </li>
                                </ul>
                            </SwiperSlide>
                            <SwiperSlide>
                                <ul className="hoverImgPt">
                                    <li className="thumb"><img src="https://cdn.hankyung.com/photo/202407/ZA.36891140.1.jpg" alt=""/></li>
                                    <li className="info">
                                        <dl className="date">23.06.14 ~ 24.06.30</dl>
                                        <dl className="title">배우 남성 랭킹</dl>
                                        <Link to="/vote/detail"><dl className="btn">투표</dl></Link>
                                    </li>
                                </ul>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                    */}
                </div>

            </section>
        </>
    );
}

export default Popular;