import {useLocation, useNavigate, useParams} from "react-router-dom";
import React, {useEffect} from 'react';
import LoadingSpinner from "../../component/LoadingSpinner";
import { useApi } from '../../js/module/api';
import useLoginHandler from "./LoginHandler"
import { jwtDecode } from 'jwt-decode';
function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function LoginCallback() {
    const apiUrl = process.env.REACT_APP_API_URL;
    const redirect_uri_kakao = process.env.REACT_APP_REDIRECT_KAKAO;
    const { apiRequest } = useApi();
    const { type } = useParams();
    const query = useQuery();
    const code = query.get('code');
    const state = query.get('state');
    const navigate = useNavigate();
    const { handleLogin } = useLoginHandler();

    const sendLogin = async () => {
        let addData;
        if(type === "kakao"){
            addData = redirect_uri_kakao;
        }
        if(type === "naver"){
            addData = state;
        }
        try {
            const url = `${apiUrl}v1/auth/login/${type}`;
            const params = {
                "code" : code,
                "addData" : addData
            };
            const response = await apiRequest(url, params, 'POST');
            const token = response.data.accessToken;
            const decoded = jwtDecode(token);
            if(decoded.consent){
                sessionStorage.setItem('authToken', response.data.accessToken);
                handleLogin();
            }else{
                sessionStorage.setItem('authTokenTm', response.data.accessToken);
                navigate("/login/agree", { replace: true });
            }
        } catch (error) {
            console.log(error)
        }
    };

    useEffect(() => {
        if (code) {
            sendLogin().then();
        }else{
            alert('비정상적인 경로로 접속하셨습니다.');
            navigate('/');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [code]);

    return (
        <>
            <LoadingSpinner/>
        </>
    );
}

export default LoginCallback;
