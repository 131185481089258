import React, {useEffect, useState} from 'react';
import {useApi} from "../../js/module/api";
import {useAppContext} from "../../component/context";

function TicketBox() {
    const apiUrl = process.env.REACT_APP_API_URL;
    const { apiRequest } = useApi();
    const [myTicket, setMyTicket] = useState([]);
    const { sendTicket } = useAppContext();

    useEffect(() => {
        const fetchTicket = async () => {
            try {
                const url = apiUrl + `v1/user/vote-ticket`;
                const data = await apiRequest(url, {}, 'GET', undefined, undefined, false, false);
                setMyTicket(data.data)
                sessionStorage.setItem('totalVoteTicket', data.data.totalVoteTicket);
                sendTicket(data.data.totalVoteTicket);
            } catch (error) {
                console.error(error);
            }
        };
        fetchTicket();
        // eslint-disable-next-line
    }, [apiRequest, apiUrl]);

    return (
        <>
            <div className="ticketInfo">
                <ul className="total">
                    <li>보유 투표권</li>
                    <li><span>{myTicket.totalVoteTicket}</span>개</li>
                </ul>
                <div className="detail">
                    <ul>
                        <li>무료 투표권</li>
                        <li><span>{myTicket.freeVoteTicket}</span>개</li>
                    </ul>
                    <ul>
                        <li>구매 투표권</li>
                        <li><span>{myTicket.voteTicket}</span>개</li>
                    </ul>
                </div>
            </div>
        </>
    );
}

export default TicketBox;