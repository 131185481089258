import React, { useEffect } from 'react';
import { useParams , useNavigate, useLocation } from 'react-router-dom';
import StickyDefault from "../layout/stickyDefault";
import goods from './goods.js';
import { useRecoilValue } from 'recoil';
import { authState } from '../../atoms/authAtom';
import { digestSHA256 } from '../../js/module/sha256';
import { encryptAES } from '../../js/module/aes';
import { format } from 'date-fns';

const currentDomain = window.location.hostname;

function OrderTicket() {
    const auth = useRecoilValue(authState); // 현재 인증 상태를 가져옵니다.
    const navigate = useNavigate();
    const { goodsId } = useParams();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const backUrl = queryParams.get('backUrl');
    useEffect(() => {
        window.completeAction = (code,backUrl) => {
            console.log("code:"+code);
            console.log("backUrl:"+backUrl);
            if(code === "0021"){
                if(backUrl === ""){
                    navigate('/mypage/purchase/1');
                }else{
                    navigate(`/vote/do/${backUrl}`);
                }
            }else{
                navigate('/mypage');
            }
        };
    }, [navigate]);

    const selectedGoods = goods.find(item => item.id === parseInt(goodsId, 10));
    if (!selectedGoods) {
        alert('존재하지 않는 상품입니다.');
        navigate('/');
        return null;
    }

    const key = "pgSettle30y739r82jtd709yOfZ2yK5K";
    const license_key = "ST1009281328226982205";
    const env = "https://tbnpg.settlebank.co.kr";
    const shopId = "nxca_jt_il";

    const handleClick = (price,goodsName,qty) => {
        const now = new Date();
        const mchtId = shopId;
        const method = "card";
        const trdDt = format(new Date(), 'yyyyMMdd');
        const trdTm = format(now, 'HHmmss');
        const mchtTrdNo = "ORDER"+trdDt+"-"+trdTm+Math.floor(1000 + Math.random() * 9000);
        const pmtPrdtNm = goodsName;
        const trdAmt = `${price}`;
        const mchtCustNm = auth.user.name;
        const email = "";
        const pktHash = mchtId + method + mchtTrdNo + trdDt + trdTm + trdAmt + license_key;
        let mchtParam;
        if(backUrl === null || backUrl === undefined){
            mchtParam = "token="+sessionStorage.getItem('authToken')+"&ticket="+qty+"&currentDomain="+currentDomain;
        }else{
            mchtParam = "token="+sessionStorage.getItem('authToken')+"&ticket="+qty+"&currentDomain="+currentDomain+"&backUrl="+backUrl;
        }
        sendPgPay(mchtId,method,trdDt,trdTm,mchtTrdNo,pmtPrdtNm,trdAmt,mchtCustNm,email,pktHash,mchtParam);
    }
    const sendPgPay = (mchtId,method,trdDt,trdTm,mchtTrdNo,pmtPrdtNm,trdAmt,mchtCustNm,email,pktHash,mchtParam) => {
        window.SETTLE_PG.pay({
            env: env,
            mchtId: mchtId,
            method: method,
            trdDt: trdDt,
            trdTm: trdTm,
            mchtTrdNo: mchtTrdNo,
            mchtParam: mchtParam,
            mchtName: "주식회사 TDI",
            mchtEName: "TDI",
            pmtPrdtNm: pmtPrdtNm,
            trdAmt: encryptAES(trdAmt,key),
            mchtCustNm: encryptAES(mchtCustNm,key),
            email: encryptAES(email,key),
            custAcntSumry: "주식회사 TDI",
            notiUrl: "https://console.trend.rankify.best/api/pg/receiveNoti",
            nextUrl: "https://console.trend.rankify.best/api/pg/receiveResult",
            cancUrl: "https://console.trend.rankify.best/api/pg/receiveResult",
            pktHash: digestSHA256(pktHash),
            ui: {
                type: "popup",
                width: "430",
                height: "660"
            }
        }, function(rsp){
            console.log(rsp);
        });
    };

    return (
        <>
            <StickyDefault/>
            <section className="contentsWrap">
                <div className="myPage">
                    <div className="topTitle">투표권 구매</div>
                    <div className="ticketOrder">
                        <div className="detail">
                            <ul>
                                <li>보유 투표권</li>
                                <li>{sessionStorage.getItem("totalVoteTicket")}개</li>
                            </ul>
                            <ul>
                                <li>구매 투표권</li>
                                <li>{selectedGoods.qty}개<div>{selectedGoods.price.toLocaleString()}원</div></li>
                            </ul>
                        </div>
                        <ul className="price">
                            <li>최종 결제금액</li>
                            <li><span>{selectedGoods.price.toLocaleString()}</span>원</li>
                        </ul>
                        <div className="btn" onClick={() => handleClick(selectedGoods.price,selectedGoods.title,selectedGoods.qty)}>구매하기</div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default OrderTicket;
