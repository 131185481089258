import React, { useEffect, useState } from "react";
import {Link, useNavigate} from "react-router-dom";
import decodeHtmlEntities from "../js/module/HtmlDecode";
import { formatNumberWithCommas } from '../js/module/numberFormatter';
import {encodeToBase64} from "../js/module/base64Utils";

function RankingNewsBox({ rankingData }) {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isPaused, setIsPaused] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        const validKeywords = rankingData.keywords.filter(item => item.news.length > 0);
        const displayCount = Math.min(validKeywords.length, 5); // 5개 이하로 제한

        const interval = setInterval(() => {
            if (!isPaused && displayCount > 0) {
                setCurrentIndex(prevIndex => (prevIndex + 1) % displayCount);
            }
        }, 2000);

        return () => clearInterval(interval);
    }, [isPaused, rankingData]);

    const handleMouseEnter = () => {
        setIsPaused(true);
    };

    const handleMouseLeave = () => {
        setIsPaused(false);
    };

    const chartMove = (title,categoryId) => {

        const infoData = encodeToBase64(`${title}@${categoryId}`)
        setTimeout(() => {
            navigate(`/ranking/chart/${infoData}`);
        }, 0);
    };

    // 유효한 키워드만 필터링
    const validKeywords = rankingData.keywords.filter(item => item.news.length > 0).slice(0, 5);

    return (
        <div className="rankBox" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
           <div className="rankTitle" onClick={() => chartMove(rankingData.title,rankingData.categoryId)}>{rankingData.title}<div className="arw s24"></div></div>
            <div className="box">
                <div className="rank">
                    {validKeywords.map((item, index) => {

                        const prevIndex = (currentIndex - 1 + validKeywords.length) % validKeywords.length;

                        return (
                            <ul key={index} className={currentIndex === index ? 'on' : (prevIndex === index ? 'off' : '')}>
                                <li className="num">{index + 1}</li>
                                <li className="thumb">
                                    <img
                                        src={item.imageUrl || "https://cdn.trend.rankify.best/dctrend/front/images/defaultImage_dt.svg"}
                                        alt={item.keyword || 'No Image'}
                                        onError={(e) => { e.target.src = "https://cdn.trend.rankify.best/dctrend/front/images/defaultImage_dt.svg"; }}
                                    />
                                </li>
                                <li className="title">{item.keyword}</li>
                                <li className="vote">{formatNumberWithCommas(item.indexValue)}점</li>
                            </ul>
                        );
                    })}
                </div>
                <div className="news">
                    {validKeywords.map((item, index) => (
                        item.news.slice(0,1).map((newsItem, newsIndex) => {
                            const prevIndex = (currentIndex - 1 + validKeywords.length) % validKeywords.length;

                            return (
                                <Link to={`/news/trend/detail/${newsItem.categoryId}/${newsItem.newsId}`} key={newsIndex}>
                                    <ul className={currentIndex === index ? 'on hoverImgPt' : (prevIndex === index ? 'off hoverImgPt' : '')}>
                                        <li className="num">{index + 1}</li>
                                        <div className="infoBox">
                                            <li className="title">{decodeHtmlEntities(newsItem.title)}</li>
                                            <li className="pressName">{newsItem.pressName}</li>
                                        </div>
                                        <li className="thumb">
                                            <img
                                                src={newsItem.thumbnail || "https://cdn.trend.rankify.best/dctrend/front/images/defaultImage_dt.svg"}
                                                alt={newsItem.pressName || 'No Image'}
                                                onError={(e) => { e.target.src = "https://cdn.trend.rankify.best/dctrend/front/images/defaultImage_dt.svg"; }}
                                            />
                                        </li>
                                    </ul>
                                </Link>
                            );
                        })
                    ))}
                </div>
            </div>
        </div>
    );
}

export default RankingNewsBox;
