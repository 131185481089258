import {Link, useParams} from "react-router-dom";
import StickyDefault from "../layout/stickyDefault";
import React, {useEffect, useState} from "react";
import {useApi} from "../../js/module/api";
import { decodeFromBase64 } from '../../js/module/base64Utils';

function RelationVote() {
    const apiUrl = process.env.REACT_APP_API_URL;
    const { apiRequest } = useApi();
    const { infoData } = useParams();
    const [keyword, setKeyword] = useState("");
    const [listData, setListData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const keyword = decodeFromBase64(infoData);
        setKeyword(keyword);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const fetchList = async () => {
            if (!keyword) return;
            setLoading(true);
            try {
                const url = apiUrl + `v1/search/votes?page=1&size=48&query=${keyword}`;
                const data = await apiRequest(url, {}, 'GET', undefined, undefined, false, false);
                console.log(data.data);
                setListData(data.data.content);
            } catch (error) {
                console.error('데이터를 불러오는데 실패했습니다.', error);
            } finally {
                setLoading(false);
            }
        };
        fetchList().then();
        // eslint-disable-next-line
    }, [apiRequest, apiUrl,keyword]);

    return (
        <>
            <StickyDefault/>
            <section className="contentsWrap">
                <div className="relationVote">
                    <div className="rvTitle"><span>'{keyword}'</span>연관 투표</div>
                    <div className="list">
                        {loading ? (
                            <div className="listLoader" style={{paddingTop:'286px'}}>
                                <div className="loader"></div>
                            </div>
                        ) : listData?.length === 0 ? (
                            <div className="noResulWrap" style={{paddingTop:'130px'}}>
                                <div className="ul"><img src="https://cdn.trend.rankify.best/dctrend/front/images/ico_nocomment.svg" alt="" /></div>
                                <div className="ul">검색된 연관투표가 없습니다.</div>
                            </div>
                        ) : (
                            listData?.map((item, index) => (
                                <ul key={index} className="hoverImgPt">
                                    <li className="thumb">
                                        <img
                                            src={item.thumbnail || "https://cdn.trend.rankify.best/dctrend/front/images/defaultImage_dt.svg"}
                                            alt={item.title || 'No Image'}
                                            onError={(e) => { e.target.src = "https://cdn.trend.rankify.best/dctrend/front/images/defaultImage_dt.svg"; }}
                                        />
                                    </li>
                                    <li className="info">
                                        <dl className="date">{item.startDate.substring(2).replace(/-/g, '.')} ~ {item.endDate.substring(2).replace(/-/g, '.')}</dl>
                                        <dl className="title">{item.title}</dl>
                                        <Link to={`/vote/detail/${item.id}`}><dl className="btn">투표</dl></Link>
                                    </li>
                                </ul>
                            ))
                        )}
                    </div>
                </div>
            </section>
        </>
    );
}

export default RelationVote;