import React, {useEffect, useState, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import NewsSubMenu from "./submenu";
import StickyDefault from "../layout/stickyDefault";
import {Link} from "react-router-dom";
import RankingNewsBox from "../../component/rankingNewsBox";
import { useApi } from '../../js/module/api';
import decodeHtmlEntities from "../../js/module/HtmlDecode";
import htmlToText from "../../js/module/HtmlToText";
import PopularVoteBox from "../../component/populaVoteBox";

function TrendNews() {

    const apiUrl = process.env.REACT_APP_API_URL;
    const { apiRequest } = useApi();
    const [topNews, setTopNews] = useState([]);
    const [entNews, setEntNews] = useState([]);
    const [sportsNews, setSportsNews] = useState([]);
    const [rankData, setRankData] = useState([]);
    const widgetRef = useRef(null);

    useEffect(() => {
        const fetchTopNews = async () => {
            try {
                const url = apiUrl + `vi/main/headline?categoryIds=14,15&headlineCount=4&recentCount=4`;
                const data = await apiRequest(url, {}, 'GET', undefined, undefined, false, false);
                setTopNews(data.data);
            } catch (error) {
                console.error('데이터를 불러오는데 실패했습니다.', error);
            }
        };
        fetchTopNews().then();

        const fetchEntNews = async () => {
            try {
                const url = apiUrl + `v1/news/popular?limit=4&categoryIds=14`;
                const data = await apiRequest(url, {}, 'GET', undefined, undefined, false, false);
                setEntNews(data.data);
            } catch (error) {
                console.error('데이터를 불러오는데 실패했습니다.', error);
            }
        };
        fetchEntNews().then();

        const fetchSportsNews = async () => {
            try {
                const url = apiUrl + `v1/news/popular?limit=4&categoryIds=15`;
                const data = await apiRequest(url, {}, 'GET', undefined, undefined, false, false);
                setSportsNews(data.data);
            } catch (error) {
                console.error('데이터를 불러오는데 실패했습니다.', error);
            }
        };
        fetchSportsNews().then();

        const fetchRankNews = async () => {
            try {
                const url = apiUrl + `v1/main/ranking-news?keywordCount=10`;
                const data = await apiRequest(url, {}, 'GET', undefined, undefined, false, true);
                setRankData(data.data);
            } catch (error) {
                console.error('데이터를 불러오는데 실패했습니다.', error);
            }
        };
        fetchRankNews().then();

    }, [apiRequest,apiUrl]);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://dctrend.rankify.best/widget/showWidget.js?code=dbdedl6p9g0v8zis&target=_self';
        script.async = true;
        widgetRef.current.appendChild(script);

        return () => {
            // 컴포넌트 언마운트 시 스크립트 제거
            if (widgetRef.current) {
                widgetRef.current.innerHTML = '';
            }
        };
    }, []);

    return (
        <>
            <StickyDefault/>
            <NewsSubMenu/>
            <section className="contentsWrap">

                <div className="trendNewsWrap">
                    <div className="mainNews">
                        {topNews?.headline && (
                            <Link to={`/news/trend/detail/${topNews.headline[0].categoryId}/${topNews.headline[0].newsId}`}>
                                <div className="main">
                                    <ul className="thumb hoverImgPt">
                                        <img
                                            src={topNews.headline[0].thumbnail || "https://cdn.trend.rankify.best/dctrend/front/images/defaultImage_dt.svg"}
                                            alt={topNews.headline[0].title || 'No Image'}
                                            onError={(e) => { e.target.src = "https://cdn.trend.rankify.best/dctrend/front/images/defaultImage_dt.svg"; }}
                                        />
                                    </ul>
                                    <ul className="head">헤드라인</ul>
                                    <ul className="title">{decodeHtmlEntities(topNews.headline[0].title)}</ul>
                                    <ul className="txt">{htmlToText(topNews.headline[0].content, 150)}</ul>
                                    <ul className="mask"></ul>
                                </div>
                            </Link>
                        )}
                        <div className="sideNewsTitle">주요기사</div>
                        <div className="sideNews">
                            {topNews?.headline?.slice(1).map((item, index) => (
                                <ul key={index} className="hoverImgPt">
                                    <Link to={`/news/trend/detail/${item.categoryId}/${item.newsId}`}>
                                        <li className="thumb">
                                            <img
                                                src={item.thumbnail || "https://cdn.trend.rankify.best/dctrend/front/images/defaultImage_dt.svg"}
                                                alt={item.title || 'No Image'}
                                                onError={(e) => { e.target.src = "https://cdn.trend.rankify.best/dctrend/front/images/defaultImage_dt.svg"; }}
                                            />
                                        </li>
                                        <li className="title">{decodeHtmlEntities(item.title)}</li>
                                        <li className="txt">{htmlToText(item.content, 150)}</li>
                                    </Link>
                                </ul>
                            ))}
                        </div>
                        <div className="rtNewsTitle">
                            {topNews.headline && (
                                <span>최신 기사</span>
                            )}
                        </div>
                        <div className="rtNews">
                            {topNews?.resents?.map((item, index) => (
                                <ul key={index} className="hoverImgPt">
                                    <Link to={`/news/trend/detail/${item.categoryId}/${item.newsId}`}>
                                        <li className="thumb">
                                            <img
                                                src={item.thumbnail || "https://cdn.trend.rankify.best/dctrend/front/images/defaultImage_dt.svg"}
                                                alt={item.title || 'No Image'}
                                                onError={(e) => { e.target.src = "https://cdn.trend.rankify.best/dctrend/front/images/defaultImage_dt.svg"; }}
                                            />
                                        </li>
                                        <li className="title">{decodeHtmlEntities(item.title)}</li>
                                        <li className="txt">{htmlToText(item.content, 150)}</li>
                                    </Link>
                                </ul>
                            ))}
                        </div>
                    </div>
                    <PopularVoteBox/>
                    <div className="sectionTitle">
                        <div className="title">랭킹 뉴스</div>
                        <Link to="/rankNews"><div className="more">랭킹뉴스 더보기<div className="arw s16"></div></div></Link>
                    </div>
                    <div className="inScrollRank">
                        <div className="rankChart">
                            {rankData?.slice(0, 3).map((item, index) => (
                                <RankingNewsBox key={index} rankingData={item} />
                            ))}
                        </div>
                    </div>
                    <div className="sectionTitle">
                        <div className="title">연예</div>
                        <Link to="/news/trend/entertainment/1"><div className="more">연예뉴스 더보기<div className="arw s16"></div></div></Link>
                    </div>
                    <div className="newsCategory">
                        <Swiper
                            slidesPerView={'auto'}
                            spaceBetween={16}
                            breakpoints={{
                                600: {
                                    slidesPerView: 'auto',
                                    spaceBetween: 16,
                                },
                                1200: {
                                    slidesPerView: 'auto',
                                    spaceBetween: 16,
                                },
                            }}
                        >
                            {entNews?.map((item, index) => (
                                <SwiperSlide key={index}>
                                    <Link to={`/news/trend/detail/${item.categoryId}/${item.newsId}`}>
                                        <ul className="hoverImgPt">
                                            <li className="thumb">
                                                <img
                                                    src={item.thumbnail || "https://cdn.trend.rankify.best/dctrend/front/images/defaultImage_dt.svg"}
                                                    alt={item.title || 'No Image'}
                                                    onError={(e) => { e.target.src = "https://cdn.trend.rankify.best/dctrend/front/images/defaultImage_dt.svg"; }}
                                                />
                                            </li>
                                            <li className="title">{decodeHtmlEntities(item.title)}</li>
                                            <li className="txt">{htmlToText(item.content, 150)}</li>
                                        </ul>
                                    </Link>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                    <div className="sectionTitle">
                        <div className="title">스포츠</div>
                        <Link to="/news/trend/sports/1"><div className="more">스포츠뉴스 더보기<div className="arw s16"></div></div></Link>
                    </div>
                    <div className="newsCategory">
                        <Swiper
                            slidesPerView={'auto'}
                            spaceBetween={16}
                            breakpoints={{
                                600: {
                                    slidesPerView: 'auto',
                                    spaceBetween: 16,
                                },
                                1200: {
                                    slidesPerView: 'auto',
                                    spaceBetween: 16,
                                },
                            }}
                        >
                            {sportsNews?.map((item, index) => (
                                <SwiperSlide key={index}>
                                    <Link to={`/news/trend/detail/${item.categoryId}/${item.newsId}`}>
                                        <ul className="hoverImgPt">
                                            <li className="thumb">
                                                <img
                                                    src={item.thumbnail || "https://cdn.trend.rankify.best/dctrend/front/images/defaultImage_dt.svg"}
                                                    alt={item.title || 'No Image'}
                                                    onError={(e) => { e.target.src = "https://cdn.trend.rankify.best/dctrend/front/images/defaultImage_dt.svg"; }}
                                                />
                                            </li>
                                            <li className="title">{decodeHtmlEntities(item.title)}</li>
                                            <li className="txt">{htmlToText(item.content, 150)}</li>
                                        </ul>
                                    </Link>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                    <div className="trendWidgetWrap">
                        <div ref={widgetRef}></div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default TrendNews;