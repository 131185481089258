import React, { useState, useEffect } from 'react';
import StickyDefault from "../layout/stickyDefault";
import { Checkbox } from 'antd';
import {Link, useNavigate, useParams} from "react-router-dom";
import {decodeFromBase64, encodeToBase64} from "../../js/module/base64Utils";
import {useApi} from "../../js/module/api";
import { formatNumberWithCommas } from '../../js/module/numberFormatter';
import {useAppContext} from "../../component/context";

function DoVote() {
    const apiUrl = process.env.REACT_APP_API_URL;
    const { apiRequest } = useApi();
    const navigate = useNavigate();
    const { infoData } = useParams();
    const [keywordId, setKeywordId] = useState("");
    const [voteId, setVoteId] = useState("");
    const [voteInfo, setVoteInfo] = useState([]);
    const [displayCate, setDisplayCate] = useState("");
    const [ticketCount, setTicketCount] = useState(1); // 사용 투표권 상태 관리
    const [fixedTicketCount, setFixedTicketCount] = useState("");
    const [alwaysUseAll, setAlwaysUseAll] = useState(false); // 항상 전체사용 체크박스 상태 관리
    const [voteCompleted, setVoteCompleted] = useState(false); // 투표 완료 상태 관리
    const [availableTickets, setAvailableTickets] = useState("");
    const [percent, setPercent] = useState("");
    const [keywordList, setKeywordList] = useState([]);
    const { sendTicket } = useAppContext();
    const [isLoading, setIisLoading] = useState(false);
    const [isResultLoading, setIisResultLoading] = useState(false);

    useEffect(() => {
        const detailInfo = decodeFromBase64(infoData);
        const [voteId, keywordId] = detailInfo.split("@");
        setKeywordId(keywordId);
        setVoteId(voteId);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const fetchRankList = async () => {
            if(!voteId) return;
            setIisLoading(false);
            try {
                const url = apiUrl + `v1/vote/keyword/detail/${voteId}/${keywordId}?indexData=false`;
                const data = await apiRequest(url, {}, 'GET', undefined, undefined, false, false);
                setVoteInfo(data.data.detail);
            } catch (error) {
                console.error('데이터를 불러오는데 실패했습니다.', error);
            } finally {
                setIisLoading(true);
            }
        };
        fetchRankList().then();
        // eslint-disable-next-line
    }, [apiRequest,apiUrl,voteId]);

    useEffect(() => {
        if (voteInfo.categoryPath) {
            const cateArray = voteInfo.categoryPath.split(' > '); // 문자열을 ' > '로 분리하여 배열로 변환
            const lastValue = cateArray[cateArray.length - 1];
            setDisplayCate(`${cateArray[cateArray.length - 2] || ''}・${lastValue}`);
        }
    }, [voteInfo.categoryPath,keywordId]);

    useEffect(() => {
        const fetchTicket = async () => {
            try {
                const url = apiUrl + `v1/user/vote-ticket`;
                const data = await apiRequest(url, {}, 'GET', undefined, undefined, false, false);
                setAvailableTickets(data.data.totalVoteTicket)
                sessionStorage.setItem('totalVoteTicket', data.data.totalVoteTicket);
            } catch (error) {
                console.error(error);
            }
        };
        fetchTicket();
    }, [apiRequest, apiUrl]);

    // 로컬 스토리지에서 항상 전체사용 상태값 불러오기
    useEffect(() => {
        const savedAlwaysUseAll = localStorage.getItem('alwaysUseAll');
        if (savedAlwaysUseAll === 'true') {
            setAlwaysUseAll(true);
        }
    }, []);

    useEffect(() => {
        document.body.style.backgroundColor = "#FAFAFC";
        return () => {
            document.body.style.backgroundColor = "#ffffff";
        };
    }, []);

    useEffect(() => {
        if (alwaysUseAll) {
            setTicketCount(availableTickets);
        }else{
            setTicketCount(1);
        }
    }, [alwaysUseAll,availableTickets]);

    const handleTicketCountChange = (e) => {
        setTicketCount(e.target.value);
    };

    const handleAlwaysUseAllChange = (e) => {
        const checked = e.target.checked;
        setAlwaysUseAll(checked);
        localStorage.setItem('alwaysUseAll', checked.toString());
    };

    const handleVoteConfirm = async () => {
        if(ticketCount === 0){
            alert("투표권은 1장이상 사용해야합니다.")
            return;
        }
        setFixedTicketCount(ticketCount);
        try {
            const url = `${apiUrl}v1/vote/${voteId}/keyword/${keywordId}/submit?count=${ticketCount}`;
            const data = await apiRequest(url, {}, 'GET', undefined, undefined, false, false);
            if(data.message === "success"){
                try {
                    const url = apiUrl + `v1/user/vote-ticket`;
                    const data = await apiRequest(url, {}, 'GET', undefined, undefined, false, false);
                    setAvailableTickets(data.data.totalVoteTicket)
                    sessionStorage.setItem('totalVoteTicket', data.data.totalVoteTicket);
                } catch (error) {
                    console.error(error);
                }
                makeResult();
                setPercent(data.data);
                setVoteCompleted(true);
            }
        } catch (error) {
            console.log(error)
        }
    };

    const makeResult = async () => {
        try {
            setIisResultLoading(false);
            const url = apiUrl + `v1/vote/${voteId}/keywords/search?keyword=${voteInfo.keyword}&topCount=1&bottomCount=1`;
            const data = await apiRequest(url, {}, 'GET', undefined, undefined, false, false);
            setKeywordList(data.data);
            sendTicket(parseInt(availableTickets) - parseInt(ticketCount));
        } catch (error) {
            console.log(error);
        } finally {
            setIisResultLoading(true);
        }
    };

    const moveReport = (voteId,keywordId,isIng) => {
        const infoData = encodeToBase64(`${voteId}@${keywordId}@${isIng}`);
        navigate(`/vote/report/${infoData}`);
    };

    return (
        <>
            <StickyDefault/>
            <section className="contentsWrap">
                <>
                {isLoading ? (
                    <div className="voteWrap">
                        <div className="box">
                            <div className="voteInfo">
                                <div className="category">{displayCate}</div>
                                <div className="title">{voteInfo.voteTitle}</div>
                                <div className="date">{voteInfo?.startDate?.substring(2).replace(/-/g, '.')} ~ {voteInfo?.endDate?.substring(2).replace(/-/g, '.')}</div>
                                <div className="item">
                                    <ul className="thumb">
                                        <img
                                            src={voteInfo.imageUrl || "https://cdn.trend.rankify.best/dctrend/front/images/defaultImage_dt.svg"}
                                            alt={voteInfo.title || 'No Image'}
                                            onError={(e) => { e.target.src = "https://cdn.trend.rankify.best/dctrend/front/images/defaultImage_dt.svg"; }}
                                        />
                                    </ul>
                                    <ul>
                                        <li className="cate">{displayCate}</li>
                                        <li className="name">{voteInfo.keyword}</li>
                                    </ul>
                                </div>
                            </div>
                            {!voteCompleted ? (
                                <div className="ticket">
                                    <div className="myTicket">
                                        <ul className="my">보유 투표권 <span>{availableTickets}개</span></ul>
                                        <Link to={`/mypage?backUrl=${infoData}`}><ul className="btn">투표권 구매<div className="arw s16"></div></ul></Link>
                                    </div>
                                    <div className="ticketUse">
                                        <ul className="inpBox">
                                            <li className="tit">사용 투표권</li>
                                            <li className="inp"><input name="ticketCount" type="number" value={ticketCount} onChange={handleTicketCountChange} /></li>
                                            <li className="unit">개</li>
                                        </ul>
                                        <ul className="btn" onClick={() => setTicketCount(availableTickets)}>전체사용</ul>
                                    </div>
                                    <div className="all">
                                        <Checkbox onChange={handleAlwaysUseAllChange} checked={alwaysUseAll}>항상 전체사용</Checkbox>
                                    </div>
                                    <div className="bntVote" onClick={handleVoteConfirm}>투표하기</div>
                                </div>
                            ) : (
                                <div className="voteResult">
                                    <div className="title">투표가 완료되었습니다.</div>
                                    <div className="txt">내 투표권<span>{fixedTicketCount}개</span>로 <span>{voteInfo.keyword}</span>에게<br/><span>{percent.toFixed(2)}%</span>기여했어요.</div>
                                    <div className="list">
                                        {isResultLoading ? (
                                          <>
                                              {keywordList.map((item,index) => (
                                                  <ul key={index} className={parseInt(keywordId) === item.keywordId ? "on" : ""}>
                                                      <li className="rank">{item.rank}</li>
                                                      <li className="thumb">
                                                          <img
                                                              src={item.imageUrl || "https://cdn.trend.rankify.best/dctrend/front/images/defaultImage_dt.svg"}
                                                              alt={item.keyword || 'No Image'}
                                                              onError={(e) => { e.target.src = "https://cdn.trend.rankify.best/dctrend/front/images/defaultImage_dt.svg"; }}
                                                          />
                                                      </li>
                                                      <li className="info">
                                                          <dl className="cate">{displayCate}</dl>
                                                          <dl className="name">{item.keyword}</dl>
                                                      </li>
                                                      <li className="score">{formatNumberWithCommas(item.totalVotes)} 표</li>
                                                  </ul>
                                              ))}
                                          </>
                                        ) : (
                                            <>
                                                <ul className="skeletonStyle"></ul>
                                                <ul className="skeletonStyle"></ul>
                                                <ul className="skeletonStyle"></ul>
                                            </>
                                        )}
                                    </div>
                                    <div className="btnBox">
                                    <span  onClick={() => {
                                        navigate(-1); // -1을 전달하여 이전 페이지로 이동
                                    }}>
                                        <ul className="back">돌아가기</ul>
                                    </span>
                                        <ul className="report" onClick={()=>{moveReport(voteId,keywordId,"ing")}}>트렌드 리포트 보기</ul>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                ) : (
                    <div className="listLoader" style={{paddingTop:'200px'}}>
                        <div className="loader"></div>
                    </div>
                )}
                </>
            </section>
        </>
    );
}

export default DoVote;
