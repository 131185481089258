import React, { createContext, useState, useContext, useEffect } from 'react';
import { useApi } from '../js/module/api';
import {useRecoilValue} from "recoil";
import {authState} from "../atoms/authAtom";

// Context 생성
const Context = createContext();

// Custom Hook
export const useAppContext = () => useContext(Context);

export const ContextProvider = ({ children }) => {
    const auth = useRecoilValue(authState);
    const naverClientID = "GpUAdIW8TuvaJvTfRJXv";
    const naverClientSecret = "CkTLR10jvm";
    const kakaoRestApiKey = "2ef828ac4f96dbd3b4d458d334ea79f3";

    const [menuOpen, setMenuOpen] = useState(false);
    const [categoryList, setCategoryList] = useState([]);
    const [ticket, setTicket] = useState("");
    const { apiRequest } = useApi();
    const apiUrl = process.env.REACT_APP_API_URL;

    const openMenu = () => setMenuOpen(true);
    const closeMenu = () => setMenuOpen(false);
    const toggleMenu = () => setMenuOpen(prev => !prev);

    const sendTicket = (ticket) => setTicket(ticket);

    // 메뉴 상태에 따라 body 스크롤 비활성화
    useEffect(() => {
        if (menuOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }
        return () => {
            document.body.style.overflow = '';
        };
    }, [menuOpen]);

    useEffect(() => {
        const fetchTicket = async () => {
            try {
                // 로그인 상태 확인
                if (!auth.isAuthenticated) return;

                // 세션 스토리지에 값이 없으면 API 호출
                const storedTicket = sessionStorage.getItem('totalVoteTicket');
                if (!storedTicket) {
                    const url = apiUrl + `v1/user/vote-ticket`;
                    const data = await apiRequest(url, {}, 'GET', undefined, undefined, false, false);

                    // 세션 스토리지와 상태를 동시에 업데이트
                    sessionStorage.setItem('totalVoteTicket', data.data.totalVoteTicket);
                    setTicket(data.data.totalVoteTicket);
                } else {
                    setTicket(storedTicket);
                }
            } catch (error) {
                console.error(error); // 오류 로그 출력
            }
        };

        fetchTicket();
    }, [apiRequest, apiUrl, auth.isAuthenticated]);


    useEffect(() => {
        const categoryLoad = async () => {
            try {
                const data = await apiRequest(apiUrl + 'v1/news/categories/group', {}, 'GET',undefined,undefined,false,false);
                localStorage.setItem('categoryList', JSON.stringify(data.data)); // 데이터를 로컬스토리지에 저장
                setCategoryList(data.data); // 상태 업데이트
            } catch (error) {
                console.error("Failed to fetch news categories:", error);
            }
        };

        categoryLoad().catch(error => console.error("Failed to load categories:", error));

    }, [apiRequest, apiUrl]);


    return (
        <Context.Provider value={{
            menuOpen,
            openMenu,
            closeMenu,
            toggleMenu,
            categoryList,
            naverClientID,
            naverClientSecret,
            kakaoRestApiKey,
            ticket,
            sendTicket
        }}>
            {children}
        </Context.Provider>
    );
};