import React, {useState,useEffect} from 'react';
import { Checkbox } from "antd";
import ContTermUse from "../policy/cont_termsUse";
import ContPrivacyJoin from "../policy/cont_privacyJoin";
import ContThirdParty from "../policy/cont_thirdParty";
import { useApi } from '../../js/module/api';
import useLoginHandler from "./LoginHandler"
import {useNavigate} from "react-router-dom";


function JoinAgree() {
    const navigate = useNavigate();
    const [tempToken, setTempToken] = useState("");
    useEffect(() => {
        const storedValue = sessionStorage.getItem('authTokenTm');
        if (storedValue) {
            setTempToken(storedValue);
            sessionStorage.removeItem('authTokenTm');
        } else {
            navigate("/", { replace: true });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        console.log(tempToken);
    }, [tempToken]);

    const apiUrl = process.env.REACT_APP_API_URL;
    const { apiRequest } = useApi();
    const [checkedList, setCheckedList] = useState({
        ck1: false,
        ck2: false,
        ck3: false,
        ck4: false,
        ck5: false,
    });
    const [allChecked, setAllChecked] = useState(false);
    const [isLayerVisible, setIsLayerVisible] = useState(false);
    const [layerTitle, setLayerTitle] = useState("");
    const [activeComponent, setActiveComponent] = useState(null);
    const { handleLogin } = useLoginHandler();

    // 필수값 체크박스 확인
    const isRequiredChecked = checkedList.ck1 && checkedList.ck2 && checkedList.ck3 && checkedList.ck4;

    // 개별 체크박스 변경 시 호출
    const onCheckboxChange = (e) => {
        const { id, checked } = e.target;
        setCheckedList({
            ...checkedList,
            [id]: checked,
        });

        // 모든 체크박스가 체크되었는지 확인 후 all 체크박스 상태 업데이트
        const allCheckedNow = Object.values({
            ...checkedList,
            [id]: checked,
        }).every(val => val);
        setAllChecked(allCheckedNow);
    };

    // '모두동의' 체크박스 클릭 시 호출
    const onAllCheckboxChange = (e) => {
        const { checked } = e.target;
        setAllChecked(checked);
        setCheckedList({
            ck1: checked,
            ck2: checked,
            ck3: checked,
            ck4: checked,
            ck5: checked,
        });
    };

    // view 클릭 시 레이어 열기 및 컴포넌트 설정
    const onViewClick = (title, component) => {
        setLayerTitle(title);
        setActiveComponent(component);
        setIsLayerVisible(true);
    };

    // 레이어 닫기
    const closeLayer = () => {
        setIsLayerVisible(false);
    };

    const sendAgree = async () => {
        try {
            const url = `${apiUrl}v1/auth/agree`;
            const response = await apiRequest(url, {}, 'GET', {'Authorization': 'bearer '+tempToken}, "merge", false);
            if(response.message === "success"){
                sessionStorage.setItem('authToken', tempToken);
                handleLogin();
            }
        } catch (error) {
            console.log(error)
        }
    };

    useEffect(() => {
        if (isLayerVisible) {
            document.body.classList.add("notScroll");
        } else {
            document.body.classList.remove("notScroll");
        }
    }, [isLayerVisible]);

    return (
        <>
            <section className="loginPage">
                <div className="box">
                    <div className="logo">
                        <img src="https://cdn.trend.rankify.best/dctrend/front/images/logo.svg" alt="" />
                    </div>
                    <div className="title">디시트렌드에 오신것을 환영합니다!</div>
                    <div className="agreeBox">
                        <div className="all">
                            <Checkbox id="all" onChange={onAllCheckboxChange} checked={allChecked}>
                                모두동의합니다.
                            </Checkbox>
                            <div className="txt">
                                개인정보 수집 및 이용(선택) 광고성 정보 수신 동의(선택) 동의를 포함합니다.
                            </div>
                        </div>
                        <div className="list">
                            <ul>
                                <Checkbox id="ck1" checked={checkedList.ck1} onChange={onCheckboxChange}>
                                    <span className="type req">필수</span>만 14세 이상입니다.
                                </Checkbox>
                            </ul>
                            <ul>
                                <Checkbox id="ck2" checked={checkedList.ck2} onChange={onCheckboxChange}>
                                    <span className="type req">필수</span>이용 약관에 동의
                                </Checkbox>
                                <div className="view" data-title="이용약관" onClick={() => onViewClick("이용약관", <ContTermUse />)}>
                                    <div className="arw s16"></div>
                                </div>
                            </ul>
                            <ul>
                                <Checkbox id="ck3" checked={checkedList.ck3} onChange={onCheckboxChange}>
                                    <span className="type req">필수</span>개인정보 수집 및 이용
                                </Checkbox>
                                <div className="view" data-title="개인정보 수집 및 이용" onClick={() => onViewClick("개인정보 수집 및 이용", <ContPrivacyJoin />)}>
                                    <div className="arw s16"></div>
                                </div>
                            </ul>
                            <ul>
                                <Checkbox id="ck4" checked={checkedList.ck4} onChange={onCheckboxChange}>
                                    <span className="type req">필수</span>개인정보 제3자 제공 동의
                                </Checkbox>
                                <div className="view" data-title="개인정보 제3자 제공 동의" onClick={() => onViewClick("개인정보 제3자 제공 동의", <ContThirdParty />)}>
                                    <div className="arw s16"></div>
                                </div>
                            </ul>
                            <ul>
                                <Checkbox id="ck5" checked={checkedList.ck5} onChange={onCheckboxChange}>
                                    <span className="type sel">선택</span>개인정보 수집 및 이용
                                </Checkbox>
                                <div className="view" data-title="개인정보 수집 및 이용" onClick={() => onViewClick("개인정보 수집 및 이용", <ContPrivacyJoin />)}>
                                    <div className="arw s16"></div>
                                </div>
                            </ul>
                        </div>
                        <button disabled={!isRequiredChecked} onClick={sendAgree}>선택완료</button>
                    </div>
                    <div className="agreeInfo">
                        디시트렌드의 소셜로그인은 투표권 구매 및 투표권 관리만을 위해 사용됩니다.
                    </div>
                </div>
            </section>

            {isLayerVisible && (
                <section className="layerDefault">
                    <div className="inbox">
                        <div className="top">{layerTitle}</div>
                        <div className="close" onClick={closeLayer}>
                            <img src="https://cdn.trend.rankify.best/dctrend/front/images/btn_close_gray.svg" alt="닫기" />
                        </div>
                        <div className="cont">
                            <div className="agreeTxt">
                                {activeComponent}
                            </div>
                        </div>
                        <div className="bot">
                            <ul className="confirm wide" onClick={closeLayer}>확인</ul>
                        </div>
                    </div>
                </section>
            )}
        </>
    );
}

export default JoinAgree;
