import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { authState } from '../atoms/authAtom';
import { useApi } from '../js/module/api';

const PrivateRoute = ({ children }) => {
    const setAuthState = useSetRecoilState(authState);
    const { apiRequest } = useApi();
    const apiUrl = process.env.REACT_APP_API_URL;
    const { isAuthenticated } = useRecoilValue(authState);
    const location = useLocation();
    const [tokenValid, setTokenValid] = useState(null);

    useEffect(() => {
        const validateToken = async () => {
            try {
                const url = `${apiUrl}v1/auth/token-validation`;
                const response = await apiRequest(url, {}, 'GET', undefined, undefined, false,false);

                if (response.message === "success") {
                    setTokenValid(true);
                } else {
                    sessionStorage.removeItem('authToken');
                    localStorage.removeItem('kakao_448db9e204b79a4e02cbf3f062a0543a');
                    setAuthState({ isAuthenticated: false, token: null, user: null });
                    setTokenValid(false);
                }
            } catch (error) {
                setTokenValid(false);
                console.error(error);
            }
        };

        if (isAuthenticated) {
            validateToken().then();
        } else {
            setTokenValid(false);
        }
    }, [isAuthenticated, apiRequest, apiUrl, setAuthState]);

    if (tokenValid === null) {
        return null;
    }

    if (!isAuthenticated || tokenValid === false) {
        sessionStorage.setItem('originalPath', location.pathname);
        return <Navigate to="/login" replace />;
    }

    return children;
};

export default PrivateRoute;
