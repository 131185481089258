import React, {useCallback, useEffect, useState} from "react";
import StickyDefault from "../layout/stickyDefault";
import SortSelect from "../../component/sortSelect";
import {formatNumberWithCommas} from "../../js/module/numberFormatter";
import {useApi} from "../../js/module/api";
import {encodeToBase64} from "../../js/module/base64Utils";
import {useNavigate, useLocation} from "react-router-dom";

const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}년 ${month}월 ${day}일`;
};

function RankPopular() {
    const apiUrl = process.env.REACT_APP_API_URL;
    const { apiRequest } = useApi();
    const [height, setHeight] = useState('7300px');
    const [itemHeight, setItemHeight] = useState(73);
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const category = queryParams.get('category');
    const subCategory = queryParams.get('subCategory');
    const [depth1, setDepth1] = useState("");
    const [selectedSort, setSelectedSort] = useState('popularUp');
    const [openIndices, setOpenIndices] = useState([]);
    const [rankData, setRankData] = useState([]);
    const [rankDate, setRankDate] = useState("");
    const [loading, setLoading] = useState(true);
    const [menuData, setMenuData] = useState([]);
    const [subMenuData, setSubMenuData] = useState([]);
    const [viewID, setViewID] = useState([]);
    const [isLayer, setIsLayer] = useState(false);
    const [isHover, setIsHover] = useState(false);

    useEffect(() => {
        const fetchRankList = async () => {
            if (!Array.isArray(viewID) || viewID.length === 0) return;
            setLoading(true);
            try {
                const url = apiUrl + `v1/ranking/keyword`;
                const params = {
                    "categoryIds": viewID,
                    "keywordCount": 100
                };
                const data = await apiRequest(url, params, 'POST', undefined, undefined, false, false);
                const width = window.innerWidth;
                if (width < 480) {
                    setItemHeight(73)
                } else if (width < 800) {
                    setItemHeight(73);
                } else {
                    setItemHeight(73);
                }
                setHeight(data.data.keyword.length * itemHeight + 'px') ;
                console.log(data.data);
                setRankData(data.data.keyword);
                setRankDate(data.data.update);
                setSelectedSort('popularUp');
            } catch (error) {
                console.error('데이터를 불러오는데 실패했습니다.', error);
            } finally {
                setLoading(false);
            }
        };
        fetchRankList().then();
        // eslint-disable-next-line
    }, [apiRequest,apiUrl,viewID]);


    useEffect(() => {
        const fetchRankMenu = async () => {
            try {
                const url = apiUrl + `v1/ranking/category`;
                const data = await apiRequest(url, {}, 'GET', undefined, undefined, false, false);
                setMenuData(data.data); //뎁스메뉴
                if (category) {
                    const parsedCategory = Number(category);
                    const rankingCategory = data.data.find(item => item.rankingCategoryId === parsedCategory);
                    setDepth1(parsedCategory)
                    if (rankingCategory) {
                        const trendRankingList = rankingCategory.trendRankingList || [];
                        setSubMenuData(trendRankingList); //1뎁스 첫번째의 하위메뉴
                        if(subCategory){
                            const parsedSubCategory = Number(subCategory);
                            setViewID([parsedSubCategory]); //리스트에 뿌려질 카테고리

                        }else{
                            setViewID([rankingCategory.rankingCategoryId]); //리스트에 뿌려질 카테고리
                        }
                    } else {
                        alert("해당 카테고리를 찾을 수 없습니다.");
                    }
                } else {
                    setDepth1(data.data[0]?.rankingCategoryId)
                    setViewID([data.data[0]?.rankingCategoryId]); //리스트에 뿌려질 카테고리
                    setSubMenuData(data.data[0]?.trendRankingList || []); //1뎁스 첫번째의 하위메뉴
                }
            } catch (error) {
                console.error('데이터를 불러오는데 실패했습니다.', error);
            } finally {
            }
        };
        fetchRankMenu().then();
        // eslint-disable-next-line
    }, [apiRequest,apiUrl,category,subCategory,depth1]);


    const handleMenuClick = (cid) => {
        setDepth1(cid)
        navigate(`/ranking/popular?category=${cid}&subCategory=`);
    };

    const handleSubMenuClick = (cid) => {
        navigate(`/ranking/popular?category=${depth1}&subCategory=${cid}`);
    };

    // 정렬 함수
    const sortRankData = useCallback((data, sortKey) => {
        return data.slice().sort((a, b) => { // slice()로 복사한 배열을 정렬
            switch (sortKey) {
                case 'popularUp':
                    return b.popularityIndexValue - a.popularityIndexValue;
                case 'popularDown':
                    return a.popularityIndexValue - b.popularityIndexValue;
                case 'activeUp':
                    return b.activeIndex - a.activeIndex;
                case 'activeDown':
                    return a.activeIndex - b.activeIndex;
                case 'participationUp':
                    return b.participationIndex - a.participationIndex;
                case 'participationDown':
                    return a.participationIndex - b.participationIndex;
                default:
                    return 0;
            }
        });
    }, []);

    useEffect(() => {
        if (rankData.length > 0) {
            const sortedData = sortRankData(rankData, selectedSort);
            // 정렬된 데이터가 기존 데이터와 다를 때만 상태 업데이트
            if (JSON.stringify(sortedData) !== JSON.stringify(rankData)) {
                setRankData(sortedData);
            }
        }
        // eslint-disable-next-line
    }, [selectedSort, sortRankData]);

    const handleDropClick = (index) => {
        setOpenIndices((prevIndices) =>
            prevIndices.includes(index)
                ? prevIndices.filter((i) => i !== index)  // 이미 열려 있으면 닫기
                : [...prevIndices, index] // 닫혀 있으면 열기
        );
    };

    const reportMove = (keywordId) => {
        const infoData = encodeToBase64(`${viewID}@${keywordId}`)
        setTimeout(() => {
            navigate(`/ranking/report/${infoData}`);
        }, 0);
    };

    const relationMove = (keyword) => {
        const infoData = encodeToBase64(keyword);
        navigate(`/vote/relation/${infoData}`);
    };

    return (
        <>
            <StickyDefault/>
            <div className="rankSubMenu">
                <ul className="menu">
                    <li className="list on">
                        {menuData
                            .filter(item => item.display)
                            .map((item, index) => (
                                <dl key={index} className={depth1 === item.rankingCategoryId ? "on" : ""} onClick={() => handleMenuClick(item.rankingCategoryId)}>
                                    {item.title}
                                </dl>
                            ))
                        }
                    </li>
                </ul>
            </div>
            {subMenuData.length > 0 && (
                <div className="topDep3">
                    <div className="inBox">
                        <ul
                            className={(subCategory === "" || subCategory === undefined || subCategory === null) ? "on" : ""}
                            onClick={() => handleSubMenuClick("")}
                        >
                            전체
                        </ul>
                        {subMenuData.map((subItem, subIndex) => (
                            <ul
                                key={subIndex}
                                className={subItem.rankingCategoryList.includes(Number(subCategory)) ? "on" : ""}
                                onClick={() => handleSubMenuClick(subItem.rankingCategoryList[0])}
                            >
                                {subItem.title}
                            </ul>
                        ))}
                    </div>
                </div>
            )}

            <section className="contentsWrap">
                <div className="rankingCustomWrap">
                    <div className="top">
                        <ul className="info">
                            {rankDate && (
                                <>
                                    <div className="update">{formatDate(rankDate)}  기준</div>
                                    <div className="toptip" onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
                                        인기랭킹이란?
                                        {isHover && (
                                            <div className="layer">
                                                인기랭킹은 <span>인기지수</span>를 기준으로 산정되며<br/>인기지수는 참여지수와 활동지수를<br/>반영하여 집계됩니다.
                                            </div>
                                        )}
                                    </div>
                                    <div className="toptip_mo" onClick={()=>{setIsLayer(true)}}>인기랭킹이란?</div>
                                </>
                            )}
                        </ul>
                        <SortSelect
                            options={[
                                { id: 'popularUp', label: '인기지수 높은 순' },
                                { id: 'popularDown', label: '인기지수 낮은 순' },
                                { id: 'activeUp', label: '활동지수 높은 순' },
                                { id: 'activeDown', label: '활동지수 낮은 순' },
                                { id: 'participationUp', label: '참여지수 높은 순' },
                                { id: 'participationDown', label: '참여지수 낮은 순' },
                            ]}
                            selectedSort={selectedSort}
                            onSortChange={setSelectedSort}
                        />
                    </div>
                    <div className="list" style={{ minHeight:height }}>
                        <ul className="head">
                            <li className="rank">랭킹</li>
                            <li className="thumb">이미지</li>
                            <li className="name">이름</li>
                            <li className="popular">인기지수</li>
                            <li className="active">활동지수</li>
                            <li className="participate">참여지수</li>
                            <li className="btnBox">연관투표</li>
                        </ul>
                        {loading ? (
                            <div style={{ height: '1000px' }}>
                                <div className="listLoader" style={{marginTop:'100px'}}>
                                    <div className="loader"></div>
                                </div>
                            </div>
                        ) : rankData?.length === 0 ? (
                            <div className="listNoneDefault">
                                데이터가 없습니다.
                            </div>
                        ) : (
                            rankData.map((item, index) => (
                                <ul className="tr" key={index}>
                                    <div className="default">
                                        <li className="rank">
                                            <dl className="num">{item.todayRank}</dl>
                                            <dl
                                                className={`rankMark ${
                                                    item.rankDiff === "NEW"
                                                        ? "new"
                                                        : !isNaN(item.rankDiff) && Number(item.rankDiff) > 0
                                                            ? "up"
                                                            : !isNaN(item.rankDiff) && Number(item.rankDiff) < 0
                                                                ? "down"
                                                                : "same"
                                                }`}
                                            >
                                                {!isNaN(item.rankDiff) && Number(item.rankDiff) >= 1000
                                                    ? "999+"
                                                    : item.rankDiff}
                                            </dl>
                                        </li>
                                        <li className="thumb">
                                            <div className="thumbBox">
                                                <img
                                                    src={item.thumbnailUrl || "https://cdn.trend.rankify.best/dctrend/front/images/defaultImage_dt.svg"}
                                                    alt={item.keyword || 'No Image'}
                                                    onError={(e) => { e.target.src = "https://cdn.trend.rankify.best/dctrend/front/images/defaultImage_dt.svg"; }}
                                                />
                                            </div>
                                        </li>
                                        <div className="mo">
                                            <li className="name"><span onClick={() => reportMove(item.id)}>{item.keyword}<div className="arw s16"></div></span></li>
                                            <li className="popular"><span>{formatNumberWithCommas(item.popularityIndexValue)}</span>
                                                <div
                                                    className={`drop ${openIndices.includes(index) ? 'open' : ''}`}
                                                    onClick={() => handleDropClick(index)}
                                                >
                                                </div>
                                            </li>
                                        </div>
                                        <li className="active">{formatNumberWithCommas(item.activeIndex)}</li>
                                        <li className="participate">{formatNumberWithCommas(item.participationIndex)}</li>
                                        <li className="btnBox"><div className="btn" onClick={()=>{relationMove(item.keyword)}}>투표하기</div></li>
                                    </div>
                                    {/* moList 영역: open 상태일 때만 보이기 */}
                                    {openIndices.includes(index) && (
                                        <div className="moList">
                                            <div>
                                                <dt>인기지수</dt>
                                                <dt>{formatNumberWithCommas(item.popularityIndexValue)}</dt>
                                            </div>
                                            <div>
                                                <dt>활동지수</dt>
                                                <dt>{formatNumberWithCommas(item.activeIndex)}</dt>
                                            </div>
                                            <div>
                                                <dt>참여지수</dt>
                                                <dt>{formatNumberWithCommas(item.participationIndex)}</dt>
                                            </div>
                                        </div>
                                    )}
                                </ul>
                            ))
                        )}
                    </div>
                </div>
            </section>
            {isLayer && (
                <section className="layerDefault">
                    <div className="inbox">
                        <div className="top">인기랭킹이란?</div>
                        <div className="close" onClick={()=>{setIsLayer(false)}}><img src="https://cdn.trend.rankify.best/dctrend/front/images/btn_close_gray.svg" alt=""/></div>
                        <div style={{lineHeight:'21px',padding:'8px 0 8px 0', textAlign:'center',color:'#475067', width:'280px'}}>
                            인기랭킹은 인기지수를 기준으로 산정되며 인기지수는 참여지수와 활동지수를 반영하여 집계됩니다.
                        </div>
                        <div className="bot">
                            <ul className="confirm wide" onClick={()=>{setIsLayer(false)}}>확인</ul>
                        </div>
                    </div>
                </section>
            )}
        </>
    );
}

export default RankPopular;
